<script setup>
import { computed, onMounted, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required, requiredIf } from '@vuelidate/validators';
import { useMapGetter, useStore } from 'dashboard/composables/store';
import { useI18n } from 'vue-i18n';
import { useAlert } from 'dashboard/composables';
import WootSubmitButton from 'dashboard/components/buttons/FormSubmitButton.vue';
import Auth from '../../../../api/auth';
import wootConstants from 'dashboard/constants/globals';

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  email: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  availability: {
    type: String,
    default: '',
  },
  customRoleId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(['close']);

const { AVAILABILITY_STATUS_KEYS } = wootConstants;

const store = useStore();
const { t } = useI18n();

const agentName = ref(props.name);
const agentAvailability = ref(props.availability);
const selectedRoleId = ref(props.customRoleId || props.type);
const agentCredentials = ref({ email: props.email });
const isAgentWebhook = ref(false);
const webhookUrl = ref('');

const urlValidators = {
  shouldBeAValidURLPattern: value => {
    if (isAgentWebhook.value && selectedRoleId.value === 'agent') {
      try {
        // eslint-disable-next-line
        new URLPattern(value);
        return true;
      } catch {
        return false;
      }
    }
    return true;
  },
  shouldStartWithHTTP: value => {
    if (isAgentWebhook.value && selectedRoleId.value === 'agent') {
      return value
        ? value.startsWith('https://') || value.startsWith('http://')
        : false;
    }
    return true;
  },
};

const rules = {
  agentName: { required, minLength: minLength(1) },
  selectedRoleId: { required },
  agentAvailability: { required },
  webhookUrl: {
    required: requiredIf(
      () => isAgentWebhook.value && selectedRoleId.value === 'agent'
    ),
    ...urlValidators,
  },
};

const v$ = useVuelidate(rules, {
  agentName,
  selectedRoleId,
  agentAvailability,
  webhookUrl,
});

const pageTitle = computed(
  () => `${t('AGENT_MGMT.EDIT.TITLE')} - ${props.name}`
);

const uiFlags = useMapGetter('agents/getUIFlags');
const getCustomRoles = useMapGetter('customRole/getCustomRoles');
const webhookAgent = useMapGetter('agentsUblux/getAgent');

const roles = computed(() => {
  const defaultRoles = [
    {
      id: 'administrator',
      name: 'administrator',
      label: t('AGENT_MGMT.AGENT_TYPES.ADMINISTRATOR'),
    },
    {
      id: 'agent',
      name: 'agent',
      label: t('AGENT_MGMT.AGENT_TYPES.AGENT'),
    },
  ];

  const customRoles = getCustomRoles.value.map(role => ({
    id: role.id,
    name: `custom_${role.id}`,
    label: role.name,
  }));

  return [...defaultRoles, ...customRoles];
});

const selectedRole = computed(() =>
  roles.value.find(
    role =>
      role.id === selectedRoleId.value || role.name === selectedRoleId.value
  )
);

const statusList = computed(() => {
  return [
    t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUS.ONLINE'),
    t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUS.BUSY'),
    t('PROFILE_SETTINGS.FORM.AVAILABILITY.STATUS.OFFLINE'),
  ];
});

const availabilityStatuses = computed(() =>
  statusList.value.map((statusLabel, index) => ({
    label: statusLabel,
    value: AVAILABILITY_STATUS_KEYS[index],
    disabled: props.availability === AVAILABILITY_STATUS_KEYS[index],
  }))
);

const editAgent = async () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;

  try {
    const payload = {
      id: props.id,
      name: agentName.value,
      availability: agentAvailability.value,
    };

    if (selectedRole.value.name.startsWith('custom_')) {
      payload.custom_role_id = selectedRole.value.id;
    } else {
      payload.role = selectedRole.value.name;
      payload.custom_role_id = null;
    }

    await store.dispatch('agents/update', payload);

    if (webhookAgent.value && Object.values(webhookAgent.value).length > 0) {
      await store.dispatch('agentsUblux/update', {
        agent_id: props.id,
        url: webhookUrl.value,
        is_enabled: isAgentWebhook.value,
      });
    } else if (isAgentWebhook.value) {
      await store.dispatch('agentsUblux/create', {
        agent_id: props.id,
        url: webhookUrl.value,
        is_enabled: isAgentWebhook.value,
      });
    }

    useAlert(t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
    emit('close');
  } catch (error) {
    useAlert(t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
  }
};

const changeSelectedRoleId = async () => {
  v$.value.selectedRoleId.$touch();
  isAgentWebhook.value = false;
  webhookUrl.value = '';
};

const resetPassword = async () => {
  try {
    await Auth.resetPassword(agentCredentials.value);
    useAlert(t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_SUCCESS_MESSAGE'));
  } catch (error) {
    useAlert(t('AGENT_MGMT.EDIT.PASSWORD_RESET.ERROR_MESSAGE'));
  }
};

const getWebhookData = async () => {
  await store.dispatch('agentsUblux/get', props.id);
};

onMounted(() => {
  getWebhookData();
});
</script>

<template>
  <div class="flex flex-col h-auto overflow-auto">
    <woot-modal-header :header-title="pageTitle" />
    <form class="w-full" @submit.prevent="editAgent">
      <div class="w-full">
        <label :class="{ error: v$.agentName.$error }">
          {{ $t('AGENT_MGMT.EDIT.FORM.NAME.LABEL') }}
          <input
            v-model="agentName"
            type="text"
            :placeholder="$t('AGENT_MGMT.EDIT.FORM.NAME.PLACEHOLDER')"
            @input="v$.agentName.$touch"
          />
        </label>
      </div>

      <div class="w-full">
        <label :class="{ error: v$.selectedRoleId.$error }">
          {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.LABEL') }}
          <select v-model="selectedRoleId" @change="changeSelectedRoleId">
            <option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.label }}
            </option>
          </select>
          <span v-if="v$.selectedRoleId.$error" class="message">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_TYPE.ERROR') }}
          </span>
        </label>
      </div>

      <div class="w-full">
        <label :class="{ error: v$.agentAvailability.$error }">
          {{ $t('PROFILE_SETTINGS.FORM.AVAILABILITY.LABEL') }}
          <select
            v-model="agentAvailability"
            @change="v$.agentAvailability.$touch"
          >
            <option
              v-for="status in availabilityStatuses"
              :key="status.value"
              :value="status.value"
            >
              {{ status.label }}
            </option>
          </select>
          <span v-if="v$.agentAvailability.$error" class="message">
            {{ $t('AGENT_MGMT.EDIT.FORM.AGENT_AVAILABILITY.ERROR') }}
          </span>
        </label>
      </div>

      <div v-if="selectedRoleId === 'agent'" class="w-full">
        <label for="toggle-webhook">
          <input
            v-model="isAgentWebhook"
            type="checkbox"
            name="toggle-webhook"
          />
          {{ $t('AGENT_MGMT.EDIT.FORM.TOGGLE_WEBHOOK') }}
        </label>
      </div>

      <div v-if="isAgentWebhook" class="w-full mt-3">
        <label :class="{ error: v$.webhookUrl.$error }">
          {{ $t('AGENT_MGMT.EDIT.FORM.WEBHOOK.LABEL') }}
          <input
            v-model="webhookUrl"
            type="url"
            :placeholder="$t('AGENT_MGMT.EDIT.FORM.WEBHOOK.PLACEHOLDER')"
            @input="v$.webhookUrl.$touch"
          />
        </label>
      </div>

      <div class="flex flex-row justify-end w-full gap-2 px-0 py-2">
        <div>
          <WootSubmitButton
            :disabled="v$.$invalid || uiFlags.isUpdating"
            :button-text="$t('AGENT_MGMT.EDIT.FORM.SUBMIT')"
            :loading="uiFlags.isUpdating"
          />
          <button class="button clear" @click.prevent="emit('close')">
            {{ $t('AGENT_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
        <!-- <div class="w-[50%] text-right">
          <woot-button
            icon="lock-closed"
            variant="clear"
            @click.prevent="resetPassword"
          >
            {{ $t('AGENT_MGMT.EDIT.PASSWORD_RESET.ADMIN_RESET_BUTTON') }}
          </woot-button>
        </div> -->
      </div>
    </form>
  </div>
</template>
