<script>
import { mapGetters } from 'vuex';
import SettingsFiltersCategoriesUblux from 'dashboard/routes/dashboard/settings/templates/filters/CategoriesUblux.vue';

export default {
  components: { SettingsFiltersCategoriesUblux },
  props: {
    showCategoriesFilter: {
      type: Boolean,
      default: false,
    },
    inboxId: {
      type: Number,
      default: 0,
    },
    isAccounts: {
      type: Boolean,
      default: false,
    },
    selectedWaTemplate: {
      type: Object,
      default: () => {},
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onSelect', 'selectedTemplate', 'close'],
  data() {
    return {
      selectedTemplate$: this.selectedWaTemplate,
      templateHeaderFormat: '',
      previewTemplate: '',
      currentTemplate: {},
      query: '',
      whatsAppTemplateMessages: [],
      phoneNumberFrom: '',
      categoryResetValue: false,
      filteredTemplate: [],
      categorySelection: '',
    };
  },
  computed: {
    ...mapGetters({
      phoneNumbers: 'phoneNumbersFrom/getPhoneNumbersFrom',
    }),
    classObject() {
      return {
        'mt-3': this.templateHeaderFormat,
        'mb-3': this.currentTemplate.buttons,
      };
    },
    getWhatsAppTemplateMessages() {
      return this.$store.getters['inboxes/getWhatsAppTemplates']({
        inboxId: this.inboxId,
        phoneNumberFrom: this.phoneNumberFrom,
      });
    },
  },
  watch: {
    inboxId(value) {
      if (value) {
        this.getPhoneNumberForm();
        this.whatsAppTemplateMessages = this.getWhatsAppTemplateMessages;
        this.filteredTemplate = [...this.whatsAppTemplateMessages];
      }
    },
    selectedWaTemplate(value) {
      if (value && Object.entries(value).length > 0) {
        this.selectedTemplate(value);
      }
    },
  },
  mounted() {
    this.getPhoneNumberForm();
    this.whatsAppTemplateMessages = this.getWhatsAppTemplateMessages;
    this.filteredTemplate = [...this.whatsAppTemplateMessages];

    if (
      this.selectedWaTemplate &&
      Object.entries(this.selectedWaTemplate).length > 0
    ) {
      this.selectedTemplate(this.selectedWaTemplate);
    }
  },
  methods: {
    getPhoneNumberForm() {
      const from = this.phoneNumbers.find(
        phone => phone.cw_inbox_id === this.inboxId
      );

      if (from) {
        this.phoneNumberFrom = from.phoneNumber;
      }
    },
    classObjectSelect(template) {
      return {
        'bg-woot-50 dark:bg-slate-800':
          this.selectedTemplate$ &&
          Object.entries(this.selectedTemplate$).length > 0 &&
          this.selectedTemplate$.id === template.id,
      };
    },
    getTemplatebody(template) {
      return template.data.components.find(
        component => component.type === 'BODY'
      ).text;
    },
    selectedTemplate(template) {
      this.templateHeaderFormat = '';
      this.currentTemplate = {};
      this.selectedTemplate$ = template;
      if (template && template.data.components.length > 0) {
        template.data.components.forEach(component => {
          if (component.type) {
            this.currentTemplate[('' + component.type).toLowerCase()] =
              component;
          }
        });

        if (typeof this.currentTemplate.body !== 'undefined') {
          this.previewTemplate = this.currentTemplate.body.text
            .replace(/\*([^*]+)\*/g, '<b>$1</b>')
            .replace(/_([^_]+)_/g, '<i>$1</i>')
            .replace(/~([^~]+)~/g, '<s>$1</s>')
            .replace(/\n/g, '<br>');
        }

        if (typeof this.currentTemplate.header !== 'undefined') {
          this.templateHeaderFormat = (
            '' + this.currentTemplate.header.format
          ).toLowerCase();
        }
      }
      this.$emit('selectedTemplate', this.selectedTemplate$);
    },
    handleFiltersSelection(selection) {
      this.categorySelection = selection.data?.value ?? '';

      if (!this.query && !this.categorySelection) {
        this.filteredTemplate = this.whatsAppTemplateMessages;
      } else {
        this.obtainingTemplates();
      }
    },
    handleInputSearch() {
      if (!this.query && !this.categorySelection) {
        this.filteredTemplate = this.whatsAppTemplateMessages;
      } else if (this.query && !this.categorySelection) {
        this.filteredTemplate = this.whatsAppTemplateMessages
          .map(item => ({
            ...item,
            attributes: item.attributes.filter(attribute =>
              attribute.name.toLowerCase().includes(this.query.toLowerCase())
            ),
          }))
          .filter(item => item.attributes.length > 0);
      } else {
        this.obtainingTemplates();
      }
    },
    replaceLabel(label) {
      return label.replaceAll('_', ' ');
    },
    obtainingTemplates() {
      const templatesByCategory = this.whatsAppTemplateMessages.find(
        value => value.key === this.categorySelection
      );

      if (this.query) {
        this.filteredTemplate = [
          {
            ...templatesByCategory,
            attributes:
              templatesByCategory.attributes?.filter(template =>
                template.name.toLowerCase().includes(this.query.toLowerCase())
              ) ?? [],
          },
        ];
      } else {
        this.filteredTemplate = [templatesByCategory];
      }
    },
  },
};
</script>

<template>
  <div class="w-full">
    <div
      class="grid grid-cols-1 md:grid-cols-2 overflow-auto md:max-h-[520px] px-3 md:px-0"
      :class="{
        'h-[calc(100vh-275px)]': !isAccounts,
        'h-[calc(100vh-355px)] md:h-[calc(100vh-280px)]': isAccounts,
        'md:max-h-[565px]': showCategoriesFilter,
      }"
    >
      <div class="w-full">
        <div>
          <!-- <templates-filters-language
            @language-filter-selection="selectedTemplate()"
          /> -->
        </div>
        <SettingsFiltersCategoriesUblux
          v-show="showCategoriesFilter"
          class="w-full mb-1.5"
          :reset-value="categoryResetValue"
          @category-filter-selection="handleFiltersSelection"
        />
        <div
          class="templates__list-search gap-1 !border-slate-200 dark:!border-slate-600"
        >
          <fluent-icon icon="search" class="search-icon mr-2" size="14" />
          <input
            v-model="query"
            type="search"
            :placeholder="$t('WHATSAPP_TEMPLATES.PICKER.SEARCH_PLACEHOLDER')"
            class="templates__search-input"
            @input="handleInputSearch"
          />
        </div>
        <div class="template__list-container">
          <div v-for="(group, i) in filteredTemplate" :key="i">
            <template v-if="group.attributes?.length > 0">
              <p class="template-group-title mb-2 font-semibold">
                {{ group.name }}
              </p>
              <ul class="template-list">
                <li
                  v-for="(attribute, j) in group.attributes"
                  :key="attribute.id"
                >
                  <button
                    class="template__list-item"
                    :class="classObjectSelect(attribute)"
                    type="button"
                    @click="selectedTemplate(attribute)"
                  >
                    <div class="flex items-center justify-between">
                      <p class="label-title mb-0 truncate mr-2">
                        {{ replaceLabel(attribute.name) }}
                      </p>
                      <span
                        class="inline-block py-1 px-2 rounded-sm text-xs leading-none cursor-default bg-white dark:bg-slate-700 text-slate-800 dark:text-slate-100"
                      >
                        {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.LANGUAGE') }} :
                        {{ attribute.languageCode }}
                      </span>
                    </div>
                  </button>
                  <hr
                    v-if="j !== group.attributes.length - 1"
                    :key="`hr-${i}`"
                  />
                </li>
              </ul>
            </template>
            <template v-else>
              <div
                v-if="categorySelection && !query"
                class="flex justify-center"
              >
                <p class="m-0">
                  {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES') }}
                </p>
              </div>
              <div v-if="query" class="flex justify-center">
                <p class="m-0">
                  {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES_FOUND') }}
                  <strong>{{ query }}</strong>
                </p>
              </div>
            </template>
          </div>
          <div
            v-if="!filteredTemplate.length && !query"
            class="flex justify-center"
          >
            <p class="m-0">
              {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES') }}
            </p>
          </div>
          <div
            v-if="!filteredTemplate.length && query"
            class="flex justify-center"
          >
            <p class="m-0">
              {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES_FOUND') }}
              <strong>{{ query }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="wa-container md:pl-5 md:pr-2 mt-6 md:mt-9 mb-4 md:mb-3">
        <div
          class="wa-background-phone max-h-[470px] h-screen mx-auto relative"
        >
          <div
            class="overflow-auto absolute p-3 top-[37px] bottom-[30px] inset-x-[20px]"
          >
            <div
              v-if="currentTemplate && currentTemplate.body"
              class="bg-white p-2 rounded-t-2xl rounded-br-2xl"
            >
              <div v-if="templateHeaderFormat">
                <div
                  v-if="templateHeaderFormat === 'image'"
                  class="background-empty-image w-full h-screen max-h-[170px]"
                />
                <div
                  v-else-if="templateHeaderFormat === 'video'"
                  class="background-empty-video w-full h-screen max-h-[170px]"
                />
                <div
                  v-else-if="templateHeaderFormat === 'document'"
                  class="background-empty-document w-full h-screen max-h-[170px]"
                />
                <div
                  v-else-if="templateHeaderFormat === 'audio'"
                  class="background-empty-audio w-full h-screen max-h-[170px]"
                />
                <div
                  v-else-if="templateHeaderFormat === 'location'"
                  class="background-empty-location w-full h-screen max-h-[170px]"
                />
                <div v-else-if="templateHeaderFormat === 'text'" class="w-full">
                  <p class="text-base font-semibold">
                    {{ currentTemplate.header.text }}
                  </p>
                </div>
              </div>
              <p
                v-if="previewTemplate"
                class="whitespace-pre-line text-black-700 break-words"
                :class="classObject"
                v-html="previewTemplate"
              />
              <p v-if="currentTemplate.footer" class="text-xs">
                {{ currentTemplate.footer.text }}
              </p>
              <div v-if="currentTemplate.buttons" class="pt-2">
                <button
                  v-for="(button, index) in currentTemplate.buttons.buttons"
                  :key="index"
                  type="button"
                  class="cursor-pointer bg-white text-black-700 !border-slate-300 hover:bg-woot-50 dark:hover:bg-slate-50 w-full !rounded-lg mb-2"
                >
                  {{ button.text || 'Code copy' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer v-show="!hideButtons" class="flex justify-end mt-3">
      <woot-button
        variant="smooth"
        type="button"
        @click.prevent="$emit('close')"
      >
        {{ $t('WHATSAPP_TEMPLATES.PARSER.EXIT_LABEL') }}
      </woot-button>
      <woot-button type="submit" @click="$emit('onSelect', selectedTemplate$)">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.ADD_VARIABLES_LABEL') }}
      </woot-button>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.templates__list-search {
  @apply items-center flex bg-slate-25 dark:bg-slate-900 mb-3 py-0 px-2.5 rounded-md border border-solid border-slate-100 dark:border-slate-700;

  .search-icon {
    @apply text-slate-400 dark:text-slate-300;
  }

  .templates__search-input {
    @apply bg-transparent border-0 text-xs h-9 m-0;
  }
}

.template__list-container {
  @apply bg-slate-25 dark:bg-slate-900 rounded-md max-h-[18.75rem] md:max-h-[29rem] overflow-y-auto overflow-x-hidden p-2.5;

  .template__list-item {
    @apply rounded-lg cursor-pointer block p-2.5 text-left w-full hover:bg-woot-50 dark:hover:bg-slate-800;

    .label-title {
      @apply text-sm;
    }

    .label-category {
      @apply mt-5;

      span {
        @apply text-sm font-semibold;
      }
    }

    .label-body {
      font-family: monospace;
    }
  }
}

.strong {
  @apply text-xs font-semibold;
}

hr {
  @apply border-b border-solid border-slate-100 dark:border-slate-700 my-2.5 mx-auto max-w-[95%];
}

footer {
  .button.primary {
    @apply ml-2.5;
  }
}

.wa-container {
  .wa-background-phone {
    background-image: url('../../../../assets/images/templates/background-wa-phone.png');
    background-size: 100% 100%;
    max-width: 280px;
    background-repeat: no-repeat;
  }

  .background-empty-image {
    background-image: url('../../../../assets/images/templates/empty-image.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .background-empty-video {
    background-image: url('../../../../assets/images/templates/empty-video.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .background-empty-document {
    background-image: url('../../../../assets/images/templates/empty-document.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .background-empty-audio {
    background-image: url('../../../../assets/images/templates/empty-audio.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .background-empty-location {
    background-image: url('../../../../assets/images/templates/empty-location.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
