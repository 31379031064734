<script>
import { email, url } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  // The value types are dynamic, hence prop validation removed to work with our action schema
  // eslint-disable-next-line vue/require-prop-types
  props: {
    flows: { type: Array, required: true },
    modelValue: { type: Object, required: true },
  },
  emits: ['update:modelValue'],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      emails: this.emails.map(() => ({
        email,
      })),
      sendFlowWebhook: {
        url,
      },
    };
  },
  data() {
    return {
      selectedFlow: {},
      sendFlowWebhook: '',
      emails: [''],
    };
  },
  computed: {
    isPatch() {
      return (
        this.modelValue &&
        Object.values(this.modelValue).length > 0 &&
        this.modelValue.details
      );
    },
  },
  watch: {
    modelValue(newValue) {
      if (!newValue || Object.values(newValue).length <= 0) {
        this.selectedFlow = {};
      }
    },
  },
  mounted() {
    if (this.isPatch) {
      this.selectedFlow = this.modelValue.details.flow;
      this.emails = this.modelValue.details.emails.filter(
        item => item !== null && item !== undefined && item !== ''
      );
      this.sendFlowWebhook = this.modelValue.details.webhook;
    }
  },
  methods: {
    updateValue() {
      if (this.isPatch) {
        this.$emit('update:modelValue', [
          this.modelValue.url,
          {
            flow: this.selectedFlow,
            emails: this.emails,
            webhook: this.sendFlowWebhook,
          },
          this.modelValue.metadata,
        ]);
      } else {
        this.$emit('update:modelValue', {
          flow: this.selectedFlow,
          emails: this.emails,
          webhook: this.sendFlowWebhook,
        });
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    addEmail() {
      this.emails.push('');
    },
  },
};
</script>

<template>
  <div>
    <div class="multiselect-wrap--small mt-1">
      <multiselect
        v-model="selectedFlow"
        track-by="id"
        label="name"
        :placeholder="$t('FORMS.MULTISELECT.SELECT')"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :max-height="160"
        :options="flows"
        :allow-empty="false"
        :option-height="104"
        class="!mb-0"
        @update:model-value="updateValue"
      />
    </div>
    <div class="mt-2">
      <p class="mb-0 font-semibold">
        {{ $t('INTEGRATION_SETTINGS.WEBHOOK.TITLE') }}
      </p>
      <input
        v-model="sendFlowWebhook"
        type="url"
        class="!mb-0"
        :placeholder="$t('AUTOMATION.ACTION.URL_INPUT_PLACEHOLDER')"
        @change="updateValue()"
        @input="v$.sendFlowWebhook.$touch"
      />
      <p
        v-if="v$.sendFlowWebhook.$error || v$.sendFlowWebhook.$invalid"
        class="error mt-1"
      >
        {{ $t('AUTOMATION.ADD.FORM.FLOW.WEBHOOK_ERROR') }}
      </p>
    </div>
    <div class="mt-2 grid cols-2 gap-2">
      <p class="mb-0 font-semibold ml-2">
        {{ $t('AGENT_MGMT.EDIT.FORM.EMAIL.LABEL') }}
      </p>
      <woot-button
        icon="add"
        color-scheme="success"
        variant="smooth"
        size="small"
        class="mt-1"
        @click="addEmail()"
      >
        {{ $t('AUTOMATION.ADD.ADD_EMAIL_BUTTON') }}
      </woot-button>
    </div>
    <div class="mt-3">
      <div v-for="(email, i) in emails" :key="i" class="mt-1">
        <div class="flex gap-2 items-center">
          <p class="font-semibold text-red-600 mb-0 mx-2">{{ i + 1 + '°' }}</p>
          <input
            v-model="emails[i]"
            type="url"
            class="!mb-0"
            :placeholder="$t('AUTOMATION.ADD.FORM.FLOW.EMAIL_PLACEHOLDER')"
            @change="updateValue()"
            @input="v$.emails[i].$touch()"
          />
          <woot-button
            v-tooltip.top="$t('AUTOMATION.FORM.DELETE')"
            variant="smooth"
            color-scheme="alert"
            size="tiny"
            icon="dismiss-circle"
            @click="removeEmail(i)"
          />
        </div>
        <p
          v-if="v$.emails[i].$error || v$.emails[i].$invalid"
          class="error mt-1 mx-[35px]"
        >
          {{ $t('AUTOMATION.ADD.FORM.FLOW.EMAIL_ERROR') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cols-2 {
  grid-template-columns: minmax(0, 1fr) 130px;
}

.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
</style>
