<script>
import { mapGetters } from 'vuex';
import { STATUS_MAP } from 'shared/constants/campaign';
import { useDateFormat } from '@vueuse/core';
import { DateTime } from 'luxon';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import ContactsModal from './ContactsModal.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import { useAlert } from 'dashboard/composables';

export default {
  components: { TableFooter, ContactsModal, EmptyState },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      campaignContactId: '',
      showContactsModal: false,
      campaigns: [],
      totalItems: 0,
      itemsPerPage: 100,
      selectedCampaign: {},
      showDeleteConfirmationPopup: false,
      searchQuery: '',
      textModalConfirmation: {
        title: '',
        description: '',
        confirm: '',
        cancel: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      paginatedCampaigns: 'campaigns/getCampaigns',
      meta: 'campaigns/getMeta',
    }),
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.campaigns.length === 0;
      return hasEmptyResults;
    },
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
  },
  watch: {
    paginatedCampaigns(newValue) {
      if (newValue && newValue.length > 0) {
        this.assignInitialValues();
      }
    },
  },
  mounted() {
    this.assignInitialValues();
  },
  methods: {
    async openContactsModal(id) {
      await this.$store.dispatch('campaigns/getCampaignContacts', id);
      this.campaignContactId = id;
      this.showContactsModal = true;
    },
    hideContactsModal() {
      this.showContactsModal = false;
    },
    openDeletePopup(campaign) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaign = campaign;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const { id } = this.selectedCampaign;
      this.deleteCampaign(id);
    },
    async deleteCampaign(id) {
      try {
        await this.$store.dispatch('campaigns/delete', id);
        useAlert(this.$t('CAMPAIGN.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        useAlert(this.$t('CAMPAIGN.DELETE.API.ERROR_MESSAGE'));
      }
    },
    mediumDate(date) {
      return useDateFormat(date, 'MMM DD, YYYY').value.toUpperCase();
    },
    shortTime(date) {
      return useDateFormat(date, 'hh:mm A').value;
    },
    submitSearch() {
      if (this.searchQuery) {
        if (this.meta.totalItems <= this.itemsPerPage) {
          this.campaigns = this.paginatedCampaigns.filter(campaignValue => {
            const searchString = this.searchQuery.toLowerCase();

            return (
              campaignValue.name.toLowerCase().includes(searchString) ||
              campaignValue.smsFromPhoneNumber?.includes(searchString) ||
              campaignValue.timezone?.includes(searchString) ||
              campaignValue.startedAt?.includes(searchString) ||
              campaignValue.wasTemplate?.name
                .toLowerCase()
                .includes(searchString) ||
              campaignValue.wasTemplate?.languageCode
                .toLowerCase()
                .includes(searchString) ||
              campaignValue.wasCampaignStatus?.name
                .toLowerCase()
                .includes(searchString) ||
              campaignValue.wasPhoneNumber?.friendlyName
                .toLowerCase()
                .includes(searchString) ||
              campaignValue.wasPhoneNumber?.friendlyName
                .toLowerCase()
                .includes(searchString)
            );
          });

          this.totalItems = this.campaigns.length;
        } else {
          // TODO aqui seria para buscar en otras campañas, por medio de la API
        }
      }
    },
    inputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllCampaigns = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllCampaigns) {
        if (this.meta.totalItems <= this.itemsPerPage) {
          this.assignInitialValues();
        } else {
          this.fetchCampaigns(1, this.itemsPerPage);
        }
      }
    },
    getStatusConfig(status) {
      return STATUS_MAP.get(status);
    },
    openTotalDialog() {
      // this._dialog.open(ContactsDialogComponent, {
      //   width: '700px',
      //   panelClass: 'responsive-panel',
      //   data: { id: id }
      // });
    },
    downloadExcel(campaign) {
      this.$store.dispatch('campaigns/downloadExcel', {
        from: campaign.createdAt,
        to: new Date(),
        campaignId: campaign.id,
        name: campaign.name,
      });
    },
    async setStatus(data, campaignName, campaignStartDate) {
      switch (data.wasCampaignStatusId) {
        case 'InProgress':
          if (data.wasCampaignStatusStateId !== 'Scheduled') {
            this.textModalConfirmation = {
              title: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_NO_SCHEDULED.TITLE'
              ),
              description: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_NO_SCHEDULED.DESCRIPTION',
                { campaignName }
              ),
              confirm: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_NO_SCHEDULED.CONFIRM'
              ),
              cancel: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_NO_SCHEDULED.CANCEL'
              ),
            };
          } else {
            const dateLuxon = DateTime.fromISO(
              campaignStartDate.toDateString(),
              { zone: 'utc' }
            );
            const startDate = dateLuxon.toFormat('LLL dd, yyyy hh:mm a');
            this.textModalConfirmation = {
              title: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_SCHEDULED.TITLE'
              ),
              description: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_SCHEDULED.DESCRIPTION',
                { campaignName, startDate }
              ),
              confirm: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_SCHEDULED.CONFIRM'
              ),
              cancel: this.$t(
                'CAMPAIGN.CHANGE_STATUS.IN_PROGRESS_SCHEDULED.CANCEL'
              ),
            };
          }
          break;
        case 'Paused':
          this.textModalConfirmation = {
            title: this.$t('CAMPAIGN.CHANGE_STATUS.PAUSED.TITLE'),
            description: this.$t('CAMPAIGN.CHANGE_STATUS.PAUSED.DESCRIPTION', {
              campaignName,
            }),
            confirm: this.$t('CAMPAIGN.CHANGE_STATUS.PAUSED.CONFIRM'),
            cancel: this.$t('CAMPAIGN.CHANGE_STATUS.PAUSED.CANCEL'),
          };
          break;
        case 'Canceled':
          this.textModalConfirmation = {
            title: this.$t('CAMPAIGN.CHANGE_STATUS.CANCELED.TITLE'),
            description: this.$t(
              'CAMPAIGN.CHANGE_STATUS.CANCELED.DESCRIPTION',
              { campaignName }
            ),
            confirm: this.$t('CAMPAIGN.CHANGE_STATUS.CANCELED.CONFIRM'),
            cancel: this.$t('CAMPAIGN.CHANGE_STATUS.CANCELED.CANCEL'),
          };
          break;

        default:
          break;
      }

      let okModal = await this.$refs.confirmStatusDialog.showConfirmation();
      if (okModal) {
        this.$store.dispatch('campaigns/setStatusCampaign', data);
      }
    },
    onPageChange(page) {
      this.fetchCampaigns(page, this.itemsPerPage);
    },
    async fetchCampaigns(page, limit) {
      await this.$store.dispatch('campaigns/get', {
        page,
        limit,
      });
    },
    assignInitialValues() {
      this.campaigns = [...this.paginatedCampaigns];
      this.totalItems = this.meta.totalItems;
    },
  },
};
</script>

<template>
  <div class="px-3">
    <div class="flex items-center relative w-full search-wrap">
      <div class="flex items-center absolute h-full left-2.5">
        <fluent-icon
          icon="search"
          size="16"
          class="h-4 text-sm leading-9 text-slate-700 dark:text-slate-200"
        />
      </div>
      <input
        type="text"
        :placeholder="$t('CAMPAIGN.LIST.SEARCH.PLACEHOLDER')"
        class="campaign-search border-slate-100 dark:border-slate-600"
        :value="searchQuery"
        @keyup.enter="submitSearch"
        @input="inputSearch"
      />
      <woot-button
        :is-loading="false"
        class="clear"
        :class-names="searchButtonClass"
        @click="submitSearch"
      >
        {{ $t('CAMPAIGN.LIST.SEARCH.SEARCH_BUTTON') }}
      </woot-button>
    </div>
    <div v-if="campaigns && campaigns.length > 0">
      <ul>
        <li
          v-for="(campaign, index) in campaigns"
          :key="index"
          class="!p-5 shadow-inner rounded-xl h-auto shadow-slate-100 dark:shadow-slate-700 bg-slate-50 dark:bg-slate-800 mt-5 list-none"
        >
          <div>
            <div
              class="flex flex-col sm:flex-row justify-between gap-x-6 gap-y-2"
            >
              <p class="text-xl truncate mb-0 text-gray-800 dark:!text-white">
                {{ campaign.name }}
              </p>
              <div
                class="flex items-center px-3 rounded-lg w-max"
                :class="getStatusConfig(campaign.wasCampaignStatusId).bgColor"
              >
                <p
                  class="text-md mb-0"
                  :class="
                    getStatusConfig(campaign.wasCampaignStatusId).textColor
                  "
                >
                  {{ campaign.wasCampaignStatus.name }}
                </p>
                <fluent-icon
                  :icon="getStatusConfig(campaign.wasCampaignStatusId).icon"
                  :viewBox="
                    getStatusConfig(campaign.wasCampaignStatusId).viewBox
                  "
                  size="13"
                  class="ml-1"
                  :class="
                    getStatusConfig(campaign.wasCampaignStatusId).iconColor
                  "
                />
              </div>
            </div>
            <div class="flex flex-col sm:flex-row sm:flex-wrap mt-2">
              <div v-if="campaign.idPowerDialerGroup" class="flex items-center">
                <div class="flex items-center">
                  <fluent-icon
                    icon="lightning-bolt"
                    viewBox="0 -960 960 960"
                    size="15"
                    type="solid"
                    class="mr-1 text-gray-800 dark:!text-white"
                  />
                  <p class="truncate mb-0 text-gray-800 dark:!text-white">
                    {{ $t('CAMPAIGN.LIST.POWER_DIALER_CAMPAIGN') }}
                  </p>
                  <!-- <p v-else class="truncate italic mb-0 text-gray-800 dark:!text-white">
                    Power dialer deleted
                  </p> -->
                </div>
                <span
                  class="text-[8px] mx-3 hidden sm:block text-gray-800 dark:!text-white"
                >
                  {{ $t('CAMPAIGN.LIST.DOT') }}
                </span>
              </div>
              <div class="flex items-center">
                <div class="flex items-center">
                  <fluent-icon
                    icon="auto-awesome-mosaic"
                    viewBox="0 -960 960 960"
                    size="15"
                    type="solid"
                    class="mr-1 text-gray-800 dark:!text-white"
                  />
                  <p
                    v-if="campaign.wasTemplate"
                    class="truncate mb-0 text-gray-800 dark:!text-white"
                  >
                    {{ campaign.wasTemplate.name }}
                  </p>
                  <p
                    v-else
                    class="truncate italic mb-0 text-gray-800 dark:!text-white"
                  >
                    {{ $t('CAMPAIGN.LIST.TEMPLATE_DELETED') }}
                  </p>
                </div>
                <span
                  class="text-[8px] mx-3 hidden sm:block text-gray-800 dark:!text-white"
                >
                  {{ $t('CAMPAIGN.LIST.DOT') }}
                </span>
              </div>
              <div class="flex items-center">
                <div class="flex items-center">
                  <fluent-icon
                    icon="calendar-today"
                    viewBox="0 -960 960 960"
                    size="15"
                    type="solid"
                    class="mr-1 text-gray-800 dark:!text-white"
                  />
                  <p class="mb-0 text-gray-800 dark:!text-white">
                    {{ mediumDate(campaign.createdAt) }}
                    {{ shortTime(campaign.createdAt) }}
                  </p>
                </div>
                <span
                  class="text-[8px] mx-3 hidden sm:block mb-0 text-gray-800 dark:!text-white"
                >
                  {{ $t('CAMPAIGN.LIST.DOT') }}
                </span>
              </div>
              <div class="flex items-center">
                <fluent-icon
                  icon="contacts"
                  viewBox="0 -960 960 960"
                  size="15"
                  type="solid"
                  class="mr-1 text-gray-800 dark:!text-white"
                />
                <a
                  class="cursor-pointer text-gray-800 dark:!text-white hover:!text-blue-600 mb-0"
                  @click="openContactsModal(campaign.id)"
                >
                  {{ $t('CAMPAIGN.LIST.TOTAL') }}:
                  {{
                    campaign.wasCampaignResult &&
                    campaign.wasCampaignResult.total
                      ? campaign.wasCampaignResult.total
                      : 0
                  }}
                </a>
              </div>
            </div>
            <div
              class="mt-10 flex flex-col sm:flex-row sm:justify-between items-end gap-6"
            >
              <div
                class="flex flex-wrap lg:flex-nowrap gap-y-3 gap-x-5 lg:gap-x-7"
              >
                <div class="flex flex-30 lg:flex-1">
                  <div class="bg-indigo-100 w-10 h-10 p-3 rounded-xl mr-2">
                    <fluent-icon
                      icon="done"
                      viewBox="0 -960 960 960"
                      size="18"
                      class="text-gray-800 dark:!text-white"
                    />
                  </div>
                  <p
                    class="flex flex-col leading-normal mb-0 text-gray-800 dark:!text-white"
                  >
                    <span
                      v-if="
                        campaign.wasCampaignStatusId === 'Completed' &&
                        campaign.wasCampaignResult
                      "
                    >
                      {{
                        campaign.wasCampaignResult.total
                          ? campaign.wasCampaignResult.sent ===
                            campaign.wasCampaignResult.total
                            ? campaign.wasCampaignResult.sent
                            : campaign.wasCampaignResult.total || 0
                          : campaign.wasCampaignResult.sent || 0
                      }}
                    </span>
                    <span v-else>{{ $t('CAMPAIGN.LIST.ZERO') }}</span>
                    <span class="text-sm">
                      {{ $t('CAMPAIGN.LIST.STATUSES.SENT') }}
                    </span>
                  </p>
                </div>
                <div class="flex flex-30 lg:flex-1">
                  <div class="bg-indigo-100 w-10 h-10 p-3 rounded-xl mr-2">
                    <fluent-icon
                      icon="visibility"
                      viewBox="0 -960 960 960"
                      size="18"
                      class="text-gray-800 dark:!text-white"
                    />
                  </div>
                  <p
                    class="flex flex-col leading-normal mb-0 text-gray-800 dark:!text-white"
                  >
                    <span v-if="campaign.wasCampaignResult">
                      {{ campaign.wasCampaignResult.read || 0 }}
                    </span>
                    <span v-else>{{ $t('CAMPAIGN.LIST.ZERO') }}</span>
                    <span class="text-sm">
                      {{ $t('CAMPAIGN.LIST.STATUSES.READ') }}
                    </span>
                  </p>
                </div>
                <div class="flex flex-30 lg:flex-1">
                  <div class="bg-indigo-100 w-10 h-10 p-3 rounded-xl mr-2">
                    <fluent-icon
                      icon="room-service"
                      viewBox="0 -960 960 960"
                      size="18"
                      class="text-gray-800 dark:!text-white"
                    />
                  </div>
                  <p
                    class="flex flex-col leading-normal mb-0 text-gray-800 dark:!text-white"
                  >
                    <span v-if="campaign.wasCampaignResult">
                      {{ campaign.wasCampaignResult.delivered || 0 }}
                    </span>
                    <span v-else>{{ $t('CAMPAIGN.LIST.ZERO') }}</span>
                    <span class="text-sm">
                      {{ $t('CAMPAIGN.LIST.STATUSES.DELIVERED') }}
                    </span>
                  </p>
                </div>
                <div class="flex flex-30 lg:flex-1">
                  <div class="bg-indigo-100 w-10 h-10 p-3 rounded-xl mr-2">
                    <fluent-icon
                      icon="pending"
                      viewBox="0 -960 960 960"
                      size="18"
                      class="text-gray-800 dark:!text-white"
                    />
                  </div>
                  <p
                    class="flex flex-col leading-normal mb-0 text-gray-800 dark:!text-white"
                  >
                    <span v-if="campaign.wasCampaignResult">
                      {{ campaign.wasCampaignResult.pending || 0 }}
                    </span>
                    <span v-else>{{ $t('CAMPAIGN.LIST.ZERO') }}</span>
                    <span class="text-sm">
                      {{ $t('CAMPAIGN.LIST.STATUSES.PENDING') }}
                    </span>
                  </p>
                </div>
                <div class="flex flex-30 lg:flex-1">
                  <div class="bg-indigo-100 w-10 h-10 p-3 rounded-xl mr-2">
                    <fluent-icon
                      icon="reply-all"
                      viewBox="0 -960 960 960"
                      size="18"
                      class="text-gray-800 dark:!text-white"
                    />
                  </div>
                  <p
                    class="flex flex-col leading-normal mb-0 text-gray-800 dark:!text-white"
                  >
                    <span v-if="campaign.wasCampaignResult">
                      {{ campaign.wasCampaignResult.replied || 0 }}
                    </span>
                    <span v-else>{{ $t('CAMPAIGN.LIST.ZERO') }}</span>
                    <span class="text-sm">
                      {{ $t('CAMPAIGN.LIST.STATUSES.REPLIED') }}
                    </span>
                  </p>
                </div>
                <div class="flex flex-30 lg:flex-1">
                  <div class="bg-indigo-200 w-10 h-10 p-3 rounded-xl mr-2">
                    <fluent-icon
                      icon="sms-failed"
                      viewBox="0 -960 960 960"
                      size="18"
                      class="text-gray-800 dark:!text-white"
                    />
                  </div>
                  <p
                    class="flex flex-col leading-normal mb-0 text-gray-800 dark:!text-white"
                  >
                    <span v-if="campaign.wasCampaignResult">
                      {{
                        campaign.wasCampaignResult.failed +
                        campaign.wasCampaignResult['error']
                      }}
                    </span>
                    <span v-else>{{ $t('CAMPAIGN.LIST.ZERO') }}</span>
                    <span class="text-sm">
                      {{ $t('CAMPAIGN.LIST.STATUSES.FAILED') }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex gap-x-1">
                <woot-button
                  v-if="
                    campaign.wasCampaignStatus.id === 'ToBeStarted' ||
                    campaign.wasCampaignStatus.id === 'Paused' ||
                    campaign.wasCampaignStatus.id === 'Scheduled'
                  "
                  v-tooltip.top="'Start campaign'"
                  variant="smooth"
                  color-scheme="secondary"
                  size="medium"
                  :size-icon="20"
                  icon="play-arrow"
                  view-box="0 -960 960 960"
                  @click="
                    setStatus(
                      {
                        id: campaign.id,
                        wasCampaignStatusId: 'InProgress',
                        wasCampaignStatusStateId: campaign.wasCampaignStatus.id,
                      },
                      campaign.name,
                      campaign.startAt
                    )
                  "
                />
                <woot-button
                  v-if="
                    campaign.wasCampaignStatus.id !== 'ToBeStarted' &&
                    campaign.wasCampaignStatus.id !== 'Paused' &&
                    campaign.wasCampaignStatus.id !== 'Canceled' &&
                    campaign.wasCampaignStatus.id !== 'Completed' &&
                    campaign.wasCampaignStatus.id !== 'Scheduled'
                  "
                  v-tooltip.top="'Paused campaign'"
                  variant="smooth"
                  color-scheme="secondary"
                  size="medium"
                  :size-icon="20"
                  icon="pause"
                  view-box="0 -960 960 960"
                  @click="
                    setStatus(
                      {
                        id: campaign.id,
                        wasCampaignStatusId: 'Paused',
                        wasCampaignStatusStateId: campaign.wasCampaignStatus.id,
                      },
                      campaign.name
                    )
                  "
                />
                <woot-button
                  v-if="
                    campaign.wasCampaignStatus.id !== 'ToBeStarted' &&
                    campaign.wasCampaignStatus.id !== 'Canceled' &&
                    campaign.wasCampaignStatus.id !== 'Completed' &&
                    campaign.wasCampaignStatus.id !== 'Scheduled'
                  "
                  v-tooltip.top="'Canceled campaign'"
                  variant="smooth"
                  color-scheme="secondary"
                  size="medium"
                  :size-icon="20"
                  icon="stop"
                  view-box="0 -960 960 960"
                  @click="
                    setStatus(
                      {
                        id: campaign.id,
                        wasCampaignStatusId: 'Canceled',
                        wasCampaignStatusStateId: campaign.wasCampaignStatus.id,
                      },
                      campaign.name
                    )
                  "
                />
                <woot-button
                  v-if="
                    campaign.wasCampaignStatus.id === 'ToBeStarted' ||
                    campaign.wasCampaignStatus.id === 'Canceled' ||
                    campaign.wasCampaignStatus.id === 'Completed' ||
                    campaign.wasCampaignStatus.id === 'Scheduled'
                  "
                  v-tooltip.top="'Remove campaign'"
                  variant="smooth"
                  color-scheme="secondary"
                  size="medium"
                  :size-icon="20"
                  icon="trash"
                  view-box="0 -960 960 960"
                  @click="openDeletePopup(campaign)"
                />
                <woot-button
                  v-if="
                    campaign.wasCampaignStatus.id === 'Completed' ||
                    campaign.wasCampaignStatus.id === 'InProgress' ||
                    campaign.wasCampaignStatus.id === 'Paused'
                  "
                  v-tooltip.top="'Download excel'"
                  variant="smooth"
                  color-scheme="secondary"
                  size="medium"
                  :size-icon="20"
                  icon="download"
                  @click="downloadExcel(campaign)"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <TableFooter
        class="border-t border-slate-75 dark:border-slate-700/50"
        :current-page="meta.currentPage"
        :total-count="totalItems"
        :page-size="meta.itemsPerPage"
        @page-change="onPageChange"
      />
    </div>
    <EmptyState v-else :title="$t('CAMPAIGN.LIST.404')" />
    <woot-delete-modal
      v-model:show="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN.DELETE.CONFIRM.TITLE')"
      :message="
        $t('CAMPAIGN.DELETE.CONFIRM.MESSAGE', {
          campaignName: selectedCampaign.name,
        })
      "
      :confirm-text="$t('CAMPAIGN.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN.DELETE.CONFIRM.NO')"
    />
    <woot-confirm-modal
      ref="confirmStatusDialog"
      :title="textModalConfirmation.title"
      :description="textModalConfirmation.description"
      :confirm-label="textModalConfirmation.confirm"
      :cancel-label="textModalConfirmation.cancel"
    />
    <ContactsModal
      v-if="showContactsModal"
      :show-modal="showContactsModal"
      :campaign-id="campaignContactId"
      @close="hideContactsModal"
      @cancel="hideContactsModal"
    />
  </div>
</template>

<style scoped lang="scss">
.search-wrap {
  .campaign-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.5rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}

.flex-30 {
  flex: 1 1 30%;
}

.text-gray-800 {
  color: rgb(30 41 59 / 1);
}

.bg-gray-200 {
  background-color: rgb(229 231 235);
}

.text-indigo-600 {
  color: rgb(79 70 229);
}
</style>
