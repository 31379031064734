<script>
import { mapGetters } from 'vuex';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';

const CUSTOM_DATE_RANGE_ID = 6;

export default {
  components: {
    WootDateRangePicker,
  },
  data() {
    return {
      currentDateRangeSelection: {
        id: 0,
        name: this.$t('REPORT.DATE_RANGE_OPTIONS.TODAY'),
        key: 'today',
      },
      customDateRange: [new Date(), new Date()],
      timeZoneSelection: {
        key: 'Etc/GMT',
        value: '(UTC) Coordinated Universal Time',
      },
      dateRange: [
        {
          id: 0,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.TODAY'),
          key: 'today',
        },
        {
          id: 1,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.LAST_7_DAYS'),
          key: 'week',
        },
        {
          id: 2,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.LAST_30_DAYS'),
          key: 'month',
        },
        {
          id: 3,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.LAST_3_MONTHS'),
          key: 'custom',
        },
        {
          id: 4,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.LAST_6_MONTHS'),
          key: 'custom',
        },
        {
          id: 5,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.LAST_YEAR'),
          key: 'custom',
        },
        {
          id: 6,
          name: this.$t('REPORT.DATE_RANGE_OPTIONS.CUSTOM_DATE_RANGE'),
          key: 'custom',
        },
      ],
      campaignResultsCards: [
        {
          key: 'sent',
          icon: 'done',
          name: this.$t('CAMPAIGN.LIST.STATUSES.SENT'),
          color: 'woot',
          total: 0,
        },
        {
          key: 'delivered',
          icon: 'room-service',
          name: this.$t('CAMPAIGN.LIST.STATUSES.DELIVERED'),
          color: 'woot',
          total: 0,
        },
        {
          key: 'failed',
          icon: 'sms-failed',
          name: this.$t('CAMPAIGN.LIST.STATUSES.FAILED'),
          color: 'woot',
          total: 0,
        },
        {
          key: 'pending',
          icon: 'pending',
          name: this.$t('CAMPAIGN.LIST.STATUSES.PENDING'),
          color: 'woot',
          total: 0,
        },
        {
          key: 'read',
          icon: 'visibility',
          name: this.$t('CAMPAIGN.LIST.STATUSES.READ'),
          color: 'woot',
          total: 0,
        },
        {
          key: 'replied',
          icon: 'reply-all',
          name: this.$t('CAMPAIGN.LIST.STATUSES.REPLIED'),
          color: 'woot',
          total: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      timeZonesData: 'timezones/getTimeZones',
      statisticsData: 'campaigns/getStatistics',
    }),
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    to() {
      if (this.isDateRangeSelected) {
        return this.toCustomDate(this.customDateRange[1]);
      }
      return this.toCustomDate(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return this.fromCustomDate(this.customDateRange[0]);
      }
      const dateRange = {
        0: 1,
        1: 6,
        2: 29,
        3: 89,
        4: 179,
        5: 364,
      };
      const diff = dateRange[this.currentDateRangeSelection.id];
      const fromDate = subDays(new Date(), diff);
      const a = this.fromCustomDate(fromDate);
      return a;
    },
    filters() {
      let filterData = {
        period: this.currentDateRangeSelection.key,
        timezone: this.timeZoneSelection.key,
      };

      if (this.currentDateRangeSelection.key === 'custom') {
        filterData = { ...filterData, from: this.from, to: this.to };
      }

      return filterData;
    },
  },
  watch: {
    timeZonesData() {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (Object.values(this.timeZonesData).length > 0) {
        this.timeZoneSelection = this.timeZonesData.find(
          item => item.key === localTimeZone
        );
        if (
          !this.timeZoneSelection ||
          Object.values(this.timeZoneSelection).length <= 0
        ) {
          const parts = localTimeZone.split('/');
          if (typeof parts[1] !== 'undefined') {
            this.timeZoneSelection = this.timeZonesData.find(
              item => item.key.indexOf(parts[1]) > -1
            );
          }
        }
      } else {
        this.timeZoneSelection = {
          key: 'Etc/GMT',
          value: '(UTC) Coordinated Universal Time',
        };
      }
    },
    statisticsData(newValue) {
      this.campaignResultsCards = this.campaignResultsCards.map(value => ({
        ...value,
        total: newValue[value.key]?.total || 0,
      }));
    },
  },
  async mounted() {
    await this.$store.dispatch('timezones/get');
    this.getStatistics();
  },
  methods: {
    changeTimeZoneSelection() {
      this.getStatistics();
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.getStatistics();
    },
    onChange(value) {
      this.customDateRange = value;
      this.getStatistics();
    },
    toCustomDate(date) {
      return endOfDay(date).toISOString();
    },
    fromCustomDate(date) {
      return startOfDay(date).toISOString();
    },
    async getStatistics() {
      await this.$store.dispatch(
        'campaigns/getCampaignStatistics',
        this.filters
      );
    },
  },
};
</script>

<template>
  <div class="flex-1 p-4 overflow-auto">
    <div class="flex flex-col md:flex-row">
      <div class="flex flex-wrap flex-col items-center w-full md:flex-row">
        <div class="mx-1 w-full md:w-auto md:min-w-64 multiselect-wrap--small">
          <p class="mb-2 text-xs font-medium">
            {{ $t('REPORT.DURATION_FILTER_LABEL') }}
          </p>
          <multiselect
            v-model="currentDateRangeSelection"
            track-by="name"
            label="name"
            :placeholder="$t('FORMS.MULTISELECT.SELECT_ONE')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            deselect-label=""
            :options="dateRange"
            :searchable="false"
            :allow-empty="false"
            @select="changeDateSelection"
          />
        </div>
        <div v-if="isDateRangeSelected" class="w-full md:w-auto md:min-w-64">
          <p class="mb-2 text-xs font-medium">
            {{ $t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER') }}
          </p>
          <WootDateRangePicker
            show-range
            classes="!w-full"
            :value="customDateRange"
            :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
            :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
            @change="onChange"
          />
        </div>
        <div class="mx-1 w-full md:w-auto md:min-w-64 multiselect-wrap--small">
          <p class="mb-2 text-xs font-medium">
            {{ $t('REPORT.TIMEZONES') }}
          </p>
          <multiselect
            v-model="timeZoneSelection"
            track-by="value"
            label="value"
            :placeholder="$t('FORMS.MULTISELECT.SELECT_ONE')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            deselect-label=""
            :options="timeZonesData"
            :searchable="false"
            :allow-empty="false"
            @select="changeTimeZoneSelection"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap gap-x-3 md:flex-row flex-col">
      <div
        v-for="(result, index) in campaignResultsCards"
        :key="index"
        class="bg-white dark:bg-slate-800 border-slate-100 dark:border-slate-700 border rounded-md p-3 flex-1 mb-2 sm:min-w-60"
      >
        <div class="flex justify-between">
          <p class="font-bold text-3xl" :class="`text-${result.color}-600`">
            {{ result.total }}
          </p>
          <fluent-icon
            class="ml-3"
            :class="`text-${result.color}-600`"
            :icon="result.icon"
            viewBox="0 -960 960 960"
            size="30"
          />
        </div>
        <p class="text-slate-700 dark:text-slate-300 font-semibold">
          {{ result.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.text-gray-600 {
  color: rgb(75 85 99 / 1);
}
.text-blue-600 {
  color: rgb(37 99 235 / 1);
}
.text-violet-600 {
  color: rgb(124 58 237 / 1);
}
</style>
