<script>
import { mapGetters } from 'vuex';
import { useAlert, useTrack } from 'dashboard/composables';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import Modal from '../../../../components/Modal.vue';
import * as XLSX from 'xlsx';

export default {
  components: {
    Modal,
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
      file: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
    }),
    csvUrl() {
      return '/downloads/import-contacts-sample.xlsx';
    },
  },
  mounted() {
    useTrack(CONTACTS_EVENTS.IMPORT_MODAL_OPEN);
  },
  methods: {
    async uploadFile() {
      try {
        if (!this.file) return;
        await this.$store.dispatch('contacts/import', this.file);
        this.onClose();
        useAlert(this.$t('IMPORT_CONTACTS.SUCCESS_MESSAGE'));
        useTrack(CONTACTS_EVENTS.IMPORT_SUCCESS);
      } catch (error) {
        useAlert(error.message || this.$t('IMPORT_CONTACTS.ERROR_MESSAGE'));
        useTrack(CONTACTS_EVENTS.IMPORT_FAILURE);
      }
    },
    async handleFileUpload() {
      this.file = this.$refs.file.files[0];

      if (this.file) {
        const fileExtension = this.file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'xls' || fileExtension === 'xlsx') {
          // Convercion a CSV
          const csvBlob = await this.convertToCSV();
          this.file = new File(
            [csvBlob],
            this.file.name.replace(/\.(xls|xlsx)$/, '.csv'),
            { type: 'text/csv' }
          );
        } else if (fileExtension !== 'csv') {
          useAlert(this.$t('IMPORT_CONTACTS.UNSUPPORTED_FILE'));
        }
      }
    },
    async convertToCSV() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const csvData = XLSX.utils.sheet_to_csv(worksheet);
            resolve(new Blob([csvData], { type: 'text/csv' }));
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsArrayBuffer(this.file);
      });
    },
  },
};
</script>

<template>
  <Modal v-model:show="show" :on-close="onClose">
    <div class="flex flex-col h-auto overflow-auto">
      <woot-modal-header :header-title="$t('IMPORT_CONTACTS.TITLE')">
        <p>
          {{ $t('IMPORT_CONTACTS.DESC') }}
          <a :href="csvUrl" download="import-contacts-sample">{{
            $t('IMPORT_CONTACTS.DOWNLOAD_LABEL')
          }}</a>
        </p>
      </woot-modal-header>
      <div class="flex flex-col px-8 pb-8 pt-6">
        <div class="w-full">
          <label class="flex flex-col">
            <span class="mb-2">{{ $t('IMPORT_CONTACTS.FORM.LABEL') }}:</span>
            <input
              id="file"
              ref="file"
              type="file"
              accept=".xls,.xlsx,.csv"
              @change="handleFileUpload"
            />
          </label>
        </div>
        <div class="flex flex-row justify-end w-full gap-2 pb-2 pt-4 px-0">
          <button class="button clear" @click.prevent="onClose">
            {{ $t('IMPORT_CONTACTS.FORM.CANCEL') }}
          </button>
          <woot-button
            :disabled="uiFlags.isCreating || !file"
            :loading="uiFlags.isCreating"
            @click="uploadFile"
          >
            {{ $t('IMPORT_CONTACTS.FORM.SUBMIT') }}
          </woot-button>
        </div>
      </div>
    </div>
  </Modal>
</template>
