<script>
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { ValidMimeTypes } from '../../../../../../types/valid-mime-types';
import DropZoneFiles from '../../../ui/dropZoneFiles/dropZoneFiles.vue';
import {
  BUTTON_OPTIONS,
  CALL_TO_ACTION_OPTIONS,
  HEADER_TYPES,
} from 'shared/constants/templates';
import TemplatesLocation from './TemplatesLocation.vue';
import { isPhoneNumberValidWithDialCode } from 'shared/helpers/Validators';
import parsePhoneNumber from 'libphonenumber-js';

const allKeysRequired = value => {
  const keys = Object.keys(value);
  return keys.every(key => value[key]);
};

export default {
  components: { DropZoneFiles, TemplatesLocation },
  props: {
    contacts: {
      type: Object,
      default: () => {},
    },
    template: {
      type: Object,
      default: () => {},
    },
    isAccounts: {
      type: Boolean,
      default: false,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    hideScroll: {
      type: Boolean,
      default: false,
    },
    showEmptyFields: {
      type: Boolean,
      default: false,
    },
    hideLoader: Boolean,
  },
  emits: ['sendMessage', 'goBackView'],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      sendTo: {
        required,
      },
      fileFormats: {
        required: requiredIf(() => this.isHeaderFile),
      },
      headerLocation: {
        required: requiredIf(() => this.isHeaderLocation),
      },
      processedParams: {
        requiredIfKeysPresent: requiredIf(() => this.variables),
        allKeysRequired,
      },
      buttonVariablesUrl: {
        requiredIfKeysPresent: requiredIf(() => this.areUrlButtons),
        allKeysRequired,
      },
      headerVariable: {
        required: requiredIf(() => this.isHeaderVariable),
      },
      buttonCopyCodeVariable: {
        required: requiredIf(() => this.isButtonCopyCode),
      },
      // buttonPhoneVariable: {
      //   required: requiredIf(() => this.isButtonPhoneNumber),
      // },
    };
  },
  data() {
    return {
      activeDialCode: '',
      buttonPhoneVariable: '',
      buttonCopyCodeVariable: '',
      buttonVariablesUrl: [],
      isHeaderVariable: false,
      headerLocation: {},
      headerVariable: '',
      sendFile: false,
      acceptFile: '',
      templateHeaderFormat: '',
      currentTemplate: {},
      processedParams: {},
      validMimeTypes: ValidMimeTypes,
      fileFormats: {},
      variablesContacts: [],
      sendTo: '',
      indexContactShow: 0,
      phoneNumberInvalid: false,
    };
  },
  computed: {
    variables() {
      return this.templateString.match(/{{([^}]+)}}/g);
    },
    templateString() {
      if (this.template && Object.values(this.template).length > 0) {
        return this.template?.data.components.find(
          component => component.type === 'BODY'
        ).text;
      }
      return '';
    },
    processedString() {
      return this.templateString.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = this.processVariable(variable);
        return this.processedParams[variableKey] || `{{${variable}}}`;
      });
    },
    classObject() {
      return {
        'mt-3': this.templateHeaderFormat,
        'mb-3': this.currentTemplate.buttons,
      };
    },
    areUrlButtons() {
      return this.buttonVariablesUrl && this.buttonVariablesUrl.length > 0;
    },
    isButtonCopyCode() {
      if (this.currentTemplate && this.currentTemplate.buttons) {
        return this.currentTemplate.buttons.buttons.some(
          button =>
            button.type.toLowerCase() === CALL_TO_ACTION_OPTIONS.copyCode
        );
      }
      return false;
    },
    isButtonPhoneNumber() {
      if (this.currentTemplate && this.currentTemplate.buttons) {
        return this.currentTemplate.buttons.buttons.some(
          button =>
            button.type.toLowerCase() === CALL_TO_ACTION_OPTIONS.phoneNumber
        );
      }
      return false;
    },
    isHeaderFile() {
      return (
        this.templateHeaderFormat === 'image' ||
        this.templateHeaderFormat === 'video' ||
        this.templateHeaderFormat === 'document'
      );
    },
    isHeaderLocation() {
      return this.templateHeaderFormat === 'location';
    },
    parsePhoneNumber() {
      return parsePhoneNumber(this.buttonPhoneVariable);
    },
    fileHeaders() {
      return this.contacts?.headers ?? [];
    },
  },
  watch: {
    template() {
      this.resetVariables();
      this.generateWhatsappPreview();
      this.generateHeaderVariables();
      this.generateBodyVariables();
      this.generateButtonsVariables();
    },
    showEmptyFields() {
      this.sendFile = true;
      this.v$.$touch();
      this.emptyFieldError();
    },
  },
  mounted() {
    if (this.contacts) {
      this.generateExcelFormWithContacts();
    }

    this.generateWhatsappPreview();
    this.generateHeaderVariables();
    this.generateBodyVariables();
    this.generateButtonsVariables();
  },
  methods: {
    resetVariables() {
      this.activeDialCode = '';
      this.buttonPhoneVariable = '';
      this.buttonCopyCodeVariable = '';
      this.buttonVariablesUrl = [];
      this.isHeaderVariable = false;
      this.headerLocation = {};
      this.headerVariable = '';
      this.sendFile = false;
      this.acceptFile = '';
      this.templateHeaderFormat = '';
      this.currentTemplate = {};
      this.processedParams = {};
      this.fileFormats = {};
      this.variablesContacts = [];
      this.sendTo = '';
      this.indexContactShow = 0;
      this.phoneNumberInvalid = false;
    },
    highlight(text, params) {
      let wholeText = text;
      if (!params || !wholeText) {
        if (wholeText) {
          return wholeText
            .replace(/\*([^*]+)\*/g, '<b>$1</b>')
            .replace(/_([^_]+)_/g, '<i>$1</i>')
            .replace(/~([^~]+)~/g, '<s>$1</s>')
            .replace(/\n/g, '<br>');
        }
        return wholeText;
      }

      if (params && Object.keys(params).length > 0) {
        wholeText = Object.entries(params).reduce((acc, [key, value]) => {
          if (value) {
            acc = acc.replace(
              `{{${Number(key)}}}`,
              () => `<mark class="bg-slate-300">${value}</mark>`
            );
          }
          return acc;
        }, wholeText);
      }

      return wholeText
        .replace(/\*([^*]+)\*/g, '<b>$1</b>')
        .replace(/_([^_]+)_/g, '<i>$1</i>')
        .replace(/~([^~]+)~/g, '<s>$1</s>')
        .replace(/\n/g, '<br>');
    },
    emptyFieldError() {
      const hasInvalidNumber = this.variablesContacts.some(
        entry =>
          !isPhoneNumberValidWithDialCode(entry.sendTo.replace(/\D/g, ''))
      );

      if (this.v$.$invalid || hasInvalidNumber) {
        if (hasInvalidNumber) {
          this.phoneNumberInvalid = true;
        }

        return true;
      }

      return false;
    },
    sendMessage() {
      let payload = {};
      if (this.emptyFieldError()) return;

      this.sendFile = true;
      this.v$.$touch();

      if (
        this.templateHeaderFormat === HEADER_TYPES.image ||
        this.templateHeaderFormat === HEADER_TYPES.video ||
        this.templateHeaderFormat === HEADER_TYPES.document
      ) {
        payload = {
          templateMedia: {
            id: '',
            fileArrayBuffer: this.fileFormats.fileArrayBuffer,
            format: this.templateHeaderFormat,
            type: this.fileFormats.file.type,
            fileName: this.fileFormats.fileName,
          },
        };
      }

      payload = {
        ...payload,
        message: this.processedString,
        templateParams: {
          name: this.template.name,
          category: this.template.category,
          language: this.template.languageCode,
          namespace: this.template.namespace,
          processed_params: this.processedParams,
        },
        templateJson: this.generateWhatsappJson(),
      };
      this.$emit('sendMessage', payload);
    },
    processVariable(str) {
      return str.replace(/{{|}}/g, '');
    },
    generateBodyVariables() {
      if (!this.template && Object.values(this.template).length > 0) return;

      const matchedVariables = this.templateString.match(/{{([^}]+)}}/g);
      if (!matchedVariables) return;

      const variables = matchedVariables.map(i => this.processVariable(i));
      this.processedParams = variables.reduce((acc, variable) => {
        acc[variable] = '';
        return acc;
      }, {});
    },
    generateHeaderVariables() {
      if (
        this.currentTemplate &&
        this.currentTemplate.header &&
        this.currentTemplate.header.text
      ) {
        const matchedVariablesHeader =
          this.currentTemplate.header.text.match(/{{([^}]+)}}/g);

        if (matchedVariablesHeader) {
          this.isHeaderVariable = true;
        } else {
          this.isHeaderVariable = false;
        }
      } else {
        this.isHeaderVariable = false;
      }
    },
    generateButtonsVariables() {
      if (
        this.currentTemplate &&
        this.currentTemplate.buttons &&
        this.currentTemplate.buttons.buttons.length > 0
      ) {
        this.buttonVariablesUrl = [];

        this.currentTemplate.buttons.buttons.forEach(button => {
          if (button.type.toLowerCase() === CALL_TO_ACTION_OPTIONS.url) {
            const matchedVariablesButtons = button.url.match(/{{([^}]+)}}/g);

            if (matchedVariablesButtons) {
              this.buttonVariablesUrl.push({
                ...button,
                variable: '',
              });
            }
          }
        });
      } else {
        this.buttonVariablesUrl = [];
      }
    },
    generateWhatsappPreview() {
      this.currentTemplate = {};
      if (
        this.template &&
        Object.values(this.template).length > 0 &&
        this.template?.data.components
      ) {
        this.template.data.components.forEach(component => {
          if (component.type) {
            this.currentTemplate[('' + component.type).toLowerCase()] =
              component;
          }
        });

        if (typeof this.currentTemplate.header !== 'undefined') {
          this.templateHeaderFormat = (
            '' + this.currentTemplate.header.format
          ).toLowerCase();

          if (this.templateHeaderFormat !== HEADER_TYPES.location) {
            this.acceptFile =
              this.validMimeTypes[this.templateHeaderFormat].extensions.join();
          } else {
            this.acceptFile = '';
          }
        }
      }
    },
    getFile(file) {
      this.fileFormats = file;
      this.selectionChange(file, 'fileFormats');
    },
    generateWhatsappJson() {
      return this.variablesContacts.map(value => {
        const header = this.getHeaderComponent(value);
        const body = this.getBodyTextComponents(value);
        const buttons = this.getButtonsComponent(value);
        const components = [].concat(
          header ? [header] : [],
          body,
          buttons && buttons.length > 0 ? buttons : []
        );

        return {
          recipient_type: 'individual',
          type: 'template',
          to: value.sendTo,
          template: {
            name: this.template.name,
            namespace: this.template.namespace,
            components: components,
            language: {
              code: this.template.languageCode ?? 'en',
              policy: 'deterministic',
            },
          },
        };
      });
    },
    getHeaderComponent(variables) {
      let component = null;

      if (
        this.templateHeaderFormat === HEADER_TYPES.image ||
        this.templateHeaderFormat === HEADER_TYPES.video ||
        this.templateHeaderFormat === HEADER_TYPES.document
      ) {
        component = {
          type: 'header',
          parameters: [
            {
              type: this.templateHeaderFormat,
              [this.templateHeaderFormat]: {
                id: '',
              },
            },
          ],
        };
      }

      if (
        this.templateHeaderFormat === HEADER_TYPES.text &&
        this.isHeaderVariable
      ) {
        component = {
          type: 'header',
          parameters: [
            {
              type: this.templateHeaderFormat,
              text: variables.headerVariable,
            },
          ],
        };
      }

      // if (
      //   this.templateHeaderFormat === HEADER_TYPES.text &&
      //   !this.isHeaderVariable
      // ) {
      //   const headerText = this.template.data.components.filter(
      //     value => value.type.toLowerCase() === 'header'
      //   );
      //   component = {
      //     type: 'header',
      //     format: HEADER_TYPES.text,
      //     text: headerText,
      //   };
      // }

      if (this.templateHeaderFormat === HEADER_TYPES.location) {
        component = {
          type: 'header',
          parameters: [
            {
              type: this.templateHeaderFormat,
              [this.templateHeaderFormat]: variables.headerLocation,
            },
          ],
        };
      }

      return component;
    },
    getBodyTextComponents(variables) {
      const params = [];
      const component = {
        type: 'body',
        parameters: [],
      };

      Object.values(variables.processedParams).forEach(variable => {
        params.push({
          type: 'text',
          text: variable,
        });
      });

      component.parameters = params;

      return component;
    },
    getButtonsComponent(variables) {
      let component = [];
      const areButtons = this.template.data.components.filter(
        value => value.type.toLowerCase() === 'buttons'
      );

      if (areButtons && areButtons.length > 0) {
        areButtons[0].buttons.forEach((value, index) => {
          const button = {
            type: 'button',
            sub_type: value.type.toLowerCase(),
            index,
          };

          switch (button.sub_type) {
            case BUTTON_OPTIONS.quickReply:
              button.parameters = [
                {
                  type: 'text',
                  text: value?.text ?? '',
                },
              ];
              break;
            case CALL_TO_ACTION_OPTIONS.url:
              if (value.url.match(/{{([^}]+)}}/g)) {
                const buttonMatch = variables.buttonVariablesUrl.find(
                  elem => elem.url === value.url
                );

                if (buttonMatch) {
                  button.parameters = [
                    {
                      type: 'text',
                      text: buttonMatch.variable,
                    },
                  ];
                }
              } else {
                button.parameters = [];
              }
              // else {
              //   button.parameters = [
              //     {
              //       type: 'text',
              //       text: value.url,
              //     },
              //   ];
              // }
              break;
            case CALL_TO_ACTION_OPTIONS.phoneNumber:
              // button.parameters = [
              //   {
              //     type: 'phone_number',
              //     phone_number: variables.buttonPhoneVariable,
              //   },
              // ];
              button.parameters = [];
              break;
            case CALL_TO_ACTION_OPTIONS.copyCode:
              button.parameters = [
                {
                  type: 'coupon_code',
                  coupon_code: variables.buttonCopyCodeVariable,
                },
              ];
              break;
            case CALL_TO_ACTION_OPTIONS.flow:
              button.parameters = [
                // {
                //   type: 'action',
                //   action: {
                //     flow_token: 'FLOW_TOKEN', // optional, default is "unused"
                //     flow_action_data: {
                //       //  ...
                //     }, // optional, json object with the data payload for the first screen
                //   },
                // },
              ];
              break;

            default:
              break;
          }

          component.push(button);
        });
      }

      return component.filter(item => item.parameters.length > 0);
    },
    getLocation(location) {
      this.headerLocation = location;
      this.selectionChange(location, 'headerLocation');
    },
    onPhoneNumberInputChange(value, code) {
      this.activeDialCode = code;
    },
    setPhoneCode(code) {
      if (this.buttonPhoneVariable !== '' && this.parsePhoneNumber) {
        const dialCode = this.parsePhoneNumber.countryCallingCode;
        if (dialCode === code) {
          return;
        }
        this.activeDialCode = `+${dialCode}`;
        const newPhoneNumber = this.buttonPhoneVariable.replace(
          `+${dialCode}`,
          `${code}`
        );
        this.buttonPhoneVariable = newPhoneNumber;
      } else {
        this.activeDialCode = code;
      }
    },
    generateExcelFormWithContacts() {
      this.sendTo = this.contacts.headers[0];
      this.contacts.contacts.forEach(contact => {
        this.variablesContacts.push({
          sendTo: contact[this.sendTo],
          fileFormats: {
            file: '',
            fileName: '',
            fileUrlBase64: '',
            fileArrayBuffer: '',
          },
          headerLocation: {},
          headerVariable: '',
          processedParams: {},
          buttonPhoneVariable: '',
          buttonCopyCodeVariable: '',
          buttonVariablesUrl: [],
        });
      });
      this.selectionChange(this.sendTo, 'sendTo');
    },
    selectionChange(value, type) {
      this.v$.sendTo.$touch();

      this.variablesContacts.forEach((contact, indexContact) => {
        if (type === 'processedParams') {
          const params = {};
          Object.entries(value).forEach(([key, param]) => {
            params[key] = this.contacts.contacts[indexContact][param];
          });

          contact[type] = params;
        } else if (type === 'buttonVariablesUrl') {
          const buttons = [];
          value.forEach(button => {
            buttons.push(this.contacts.contacts[indexContact][button.variable]);
          });

          contact[type] = buttons;
        } else {
          contact[type] = this.contacts.contacts[indexContact][value];
        }

        if (type === 'sendTo') {
          if (
            this.isPhoneNumberTheString(contact[type]) &&
            isPhoneNumberValidWithDialCode(contact[type].replace(/\D/g, ''))
          ) {
            this.phoneNumberInvalid = false;
            this.v$.sendTo.$reset();
          } else {
            this.phoneNumberInvalid = true;
          }
        }
      });

      this.sendMessage();
    },
    isPhoneNumberTheString(text) {
      const regex =
        /^(\+?\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
      return regex.test(text);
    },
  },
};
</script>

<template>
  <div class="w-full">
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-x-4 overflow-auto"
      :class="{
        'h-[calc(100vh-275px)]': !isAccounts && !hideScroll,
        'h-[calc(100vh-355px)]': isAccounts && !hideScroll,
        'md:max-h-[520px]': !hideScroll,
      }"
    >
      <div class="w-full">
        <div class="p-2.5">
          <p class="text-sm font-semibold mb-2.5">
            {{ $t('TEMPLATES_SETTINGS.ADD.SEND_TO') }}
          </p>
          <label
            class="mb-0"
            :class="{
              'error !rounded-b-none': v$.sendTo.$error || phoneNumberInvalid,
            }"
          >
            <select
              v-model="sendTo"
              @change="selectionChange(sendTo, 'sendTo')"
            >
              <option
                v-for="header in fileHeaders"
                :key="header"
                :value="header"
              >
                {{ header }}
              </option>
            </select>
          </label>
          <p
            v-if="
              (v$.sendTo.$dirty && v$.sendTo.$invalid) || phoneNumberInvalid
            "
            class="error !rounded-t-none !pt-0"
          >
            {{ $t('TEMPLATES_SETTINGS.ADD.FORM.SEND_TO.ERROR_INVALID_PHONE') }}
          </p>
        </div>
        <div v-if="templateHeaderFormat" class="p-2.5">
          <div
            v-if="
              templateHeaderFormat === 'image' ||
              templateHeaderFormat === 'video' ||
              templateHeaderFormat === 'document'
            "
          >
            <DropZoneFiles
              :accept-file="acceptFile"
              :header-format="templateHeaderFormat"
              :send-file="sendFile"
              @on-file="getFile($event)"
            />
            <p
              v-if="v$.fileFormats.$dirty && v$.fileFormats.$invalid"
              class="error"
            >
              {{ $t('TEMPLATES_SETTINGS.ADD.FORM.FILES_HEADER.ERROR') }}
            </p>
          </div>
          <div
            v-else-if="templateHeaderFormat === 'location'"
            class="w-full h-full"
          >
            <TemplatesLocation @get-location="getLocation($event)" />
            <p
              v-if="v$.headerLocation.$dirty && v$.headerLocation.$invalid"
              class="error"
            >
              {{ $t('TEMPLATES_SETTINGS.ADD.FORM.LOCATION_ERROR') }}
            </p>
          </div>
          <div v-else-if="isHeaderVariable && templateHeaderFormat === 'text'">
            <p class="text-sm font-semibold mb-2.5">
              {{ $t('TEMPLATES_SETTINGS.ADD.FORM.HEADER.ADD_VARIABLE_TEXT') }}
            </p>
            <div class="mb-2.5">
              <label
                :class="{ 'error !rounded-b-none': v$.headerVariable.$error }"
              >
                <select
                  v-model="headerVariable"
                  class="mb-0"
                  @change="selectionChange(headerVariable, 'headerVariable')"
                >
                  <option
                    v-for="header in fileHeaders"
                    :key="header"
                    :value="header"
                  >
                    {{ header }}
                  </option>
                </select>
              </label>
            </div>
            <p
              v-if="v$.headerVariable.$dirty && v$.headerVariable.$invalid"
              class="error !rounded-t-none"
            >
              {{ $t('CAMPAIGN.ADD.FORM.HEADER.ERROR') }}
            </p>
          </div>
        </div>
        <div v-if="variables" class="template__variables-container">
          <p class="text-sm font-semibold mb-2.5">
            {{ $t('TEMPLATES_SETTINGS.ADD.FORM.BODY.ADD_VARIABLES_TEXT') }}
          </p>
          <div
            v-for="(variable, key) in processedParams"
            :key="key"
            class="template__variable-item"
          >
            <span class="variable-label">
              {{ key }}
            </span>
            <label class="variable-input">
              <select
                v-model="processedParams[key]"
                class="mb-0"
                @change="selectionChange(processedParams, 'processedParams')"
              >
                <option
                  v-for="header in fileHeaders"
                  :key="header"
                  :value="header"
                >
                  {{ header }}
                </option>
              </select>
            </label>
          </div>
          <p v-if="v$.$dirty && v$.$invalid" class="error">
            {{ $t('TEMPLATES_SETTINGS.ADD.FORM.BODY.ERROR') }}
          </p>
        </div>
        <div
          v-if="areUrlButtons || isButtonCopyCode || isButtonPhoneNumber"
          class="p-2.5"
        >
          <p class="text-sm font-semibold mb-2.5">
            {{ $t('TEMPLATES_SETTINGS.ADD.FORM.BUTTONS.ADD_VARIABLES_TITLE') }}
          </p>
          <div v-if="isButtonCopyCode">
            <p class="text-sm font-semibold mb-2.5">
              {{ $t('TEMPLATES_SETTINGS.ADD.FORM.BUTTONS.ADD_VARIABLE_CODE') }}
            </p>
            <div class="mb-2.5">
              <label
                class="variable-input"
                :class="{
                  'error !rounded-b-none': v$.buttonCopyCodeVariable.$error,
                }"
              >
                <select
                  v-model="buttonCopyCodeVariable"
                  class="mb-0"
                  @change="
                    selectionChange(
                      buttonCopyCodeVariable,
                      'buttonCopyCodeVariable'
                    )
                  "
                >
                  <option
                    v-for="header in fileHeaders"
                    :key="header"
                    :value="header"
                  >
                    {{ header }}
                  </option>
                </select>
              </label>
            </div>
            <p
              v-if="
                v$.buttonCopyCodeVariable.$dirty &&
                v$.buttonCopyCodeVariable.$invalid
              "
              class="error !rounded-t-none"
            >
              {{
                $t(
                  'TEMPLATES_SETTINGS.ADD.FORM.BUTTONS.CALL_TO_ACTION.COPY_CODE.ERROR'
                )
              }}
            </p>
          </div>
          <!-- <div v-if="isButtonPhoneNumber" class="w-full">
            <p class="text-sm font-semibold mb-2.5">
              {{ $t('CONTACT_FORM.FORM.PHONE_NUMBER.LABEL') }}
            </p>
            <div class="mb-2.5">
              <label
                :class="{
                  'error !rounded-b-none': v$.buttonPhoneVariable.$error,
                }"
              >
                <select
                  v-model="buttonPhoneVariable"
                  class="mb-0"
                  @change="
                    selectionChange(buttonPhoneVariable, 'buttonPhoneVariable')
                  "
                >
                  <option
                    v-for="header in fileHeaders"
                    :key="header"
                    :value="header"
                  >
                    {{ header }}
                  </option>
                </select>
              </label>
            </div>
            <p
              v-if="
                v$.buttonPhoneVariable.$dirty && v$.buttonPhoneVariable.$invalid
              "
              class="error !rounded-t-none"
            >
              {{
                $t(
                  'TEMPLATES_SETTINGS.ADD.FORM.BUTTONS.CALL_TO_ACTION.PHONE_NUMBER.ERROR'
                )
              }}
            </p>
          </div> -->
          <div v-if="areUrlButtons">
            <p class="text-sm font-semibold mb-3.5">
              {{ $t('TEMPLATES_SETTINGS.ADD.FORM.BUTTONS.ADD_VARIABLES_URL') }}
            </p>
            <div
              v-for="(button, key) in buttonVariablesUrl"
              :key="key"
              class="items-center flex mb-2.5 template__variable-item"
            >
              <span
                class="bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6"
              >
                {{ key + 1 }}
              </span>
              <label class="variable-input">
                <select
                  v-model="button.variable"
                  class="mb-0"
                  @change="
                    selectionChange(buttonVariablesUrl, 'buttonVariablesUrl')
                  "
                >
                  <option
                    v-for="header in fileHeaders"
                    :key="header"
                    :value="header"
                  >
                    {{ header }}
                  </option>
                </select>
              </label>
            </div>
            <p v-if="v$.$dirty && v$.$invalid" class="error">
              {{
                $t(
                  'TEMPLATES_SETTINGS.ADD.FORM.BUTTONS.CALL_TO_ACTION.URL.ERROR'
                )
              }}
            </p>
          </div>
        </div>
        <div
          v-if="
            !variables &&
            !areUrlButtons &&
            !isButtonCopyCode &&
            !isButtonPhoneNumber &&
            ((!isHeaderVariable && templateHeaderFormat === 'text') ||
              !templateHeaderFormat)
          "
          class="p-2.5"
        >
          <p class="text-sm font-semibold mb-2.5">
            {{ $t('TEMPLATES_SETTINGS.ADD.FORM.BODY.ADD_VARIABLES_TEXT') }}
          </p>
          <p class="text-sm text-justify">
            {{
              $t('TEMPLATES_SETTINGS.ADD.FORM.BODY.TEMPLATE_WITHOUT_VARIABLES')
            }}
          </p>
        </div>
      </div>
      <div class="wa-container">
        <p class="font-bold mb-4">
          {{ $t('TEMPLATES_SETTINGS.ADD.TO') }}:
          <span class="font-normal pl-2">
            {{
              variablesContacts[indexContactShow]
                ? variablesContacts[indexContactShow].sendTo
                : ''
            }}
          </span>
        </p>
        <div
          class="wa-background-phone max-h-[500px] h-screen mx-auto relative"
        >
          <div
            class="overflow-auto absolute p-3 top-[38px] bottom-[32px] inset-x-[22px]"
          >
            <div
              v-if="currentTemplate && currentTemplate.body"
              class="bg-white p-2 rounded-t-2xl rounded-br-2xl"
            >
              <div v-if="templateHeaderFormat">
                <div v-if="templateHeaderFormat === 'image'" class="w-full">
                  <img
                    v-if="fileFormats.fileUrlBase64"
                    :src="fileFormats.fileUrlBase64"
                    alt="Selected image"
                    class="mb-3"
                  />
                  <div
                    v-if="!fileFormats.fileUrlBase64"
                    class="background-empty-image h-screen max-h-[180px]"
                  />
                </div>
                <div
                  v-else-if="templateHeaderFormat === 'video'"
                  class="w-full"
                >
                  <video v-if="fileFormats.fileUrlBase64" controls>
                    <source
                      type="video/webm"
                      :src="fileFormats.fileUrlBase64"
                      class="mb-3"
                    />
                    <source type="video/mp4" :src="fileFormats.fileUrlBase64" />
                  </video>
                  <div
                    v-if="!fileFormats.fileUrlBase64"
                    class="background-empty-video h-screen max-h-[180px]"
                  />
                </div>
                <div
                  v-else-if="templateHeaderFormat === 'document'"
                  class="background-empty-document w-full h-screen max-h-[180px]"
                />
                <div
                  v-else-if="templateHeaderFormat === 'audio'"
                  class="w-full"
                >
                  <audio
                    v-if="fileFormats.fileUrlBase64"
                    controls
                    :src="fileFormats.fileUrlBase64"
                    class="mb-3"
                  />
                  <div
                    v-if="!fileFormats.fileUrlBase64"
                    class="background-empty-audio h-screen max-h-[180px]"
                  />
                </div>
                <div v-if="templateHeaderFormat === 'location'" class="w-full">
                  <div
                    class="background-empty-location h-screen max-h-[180px]"
                  />
                </div>
                <div v-else-if="templateHeaderFormat === 'text'" class="w-full">
                  <p
                    class="text-base font-semibold"
                    v-html="
                      highlight(currentTemplate.header.text, {
                        1: variablesContacts[indexContactShow].headerVariable,
                      })
                    "
                  />
                </div>
              </div>
              <p
                v-if="currentTemplate.body"
                class="whitespace-pre-line text-black-700 break-words"
                :class="classObject"
                v-html="
                  highlight(
                    currentTemplate.body.text,
                    variablesContacts[indexContactShow].processedParams
                  )
                "
              />
              <p v-if="currentTemplate.footer" class="text-xs">
                {{ currentTemplate.footer.text }}
              </p>
              <div v-if="currentTemplate.buttons" class="pt-2">
                <button
                  v-for="(button, index) in currentTemplate.buttons.buttons"
                  :key="index"
                  type="button"
                  class="cursor-pointer bg-white text-black-700 !border-slate-300 hover:bg-woot-50 dark:hover:bg-slate-50 w-full !rounded-lg mb-2"
                >
                  {{ button.text || 'Copy code' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer v-show="!hideButtons" class="mt-3">
      <woot-button variant="smooth" @click="$emit('goBackView')">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>
      <woot-button type="button" :is-loading="!hideLoader" @click="sendMessage">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL') }}
      </woot-button>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.template__variables-container {
  @apply p-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .label {
    @apply text-xs;
  }

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}

.wa-container {
  .wa-background-phone {
    background-image: url('../../../../assets/images/templates/background-wa-phone.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 300px;
  }

  .background-empty-image {
    background-image: url('../../../../assets/images/templates/empty-image.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .background-empty-video {
    background-image: url('../../../../assets/images/templates/empty-video.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .background-empty-document {
    background-image: url('../../../../assets/images/templates/empty-document.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .background-empty-audio {
    background-image: url('../../../../assets/images/templates/empty-audio.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .background-empty-location {
    background-image: url('../../../../assets/images/templates/empty-location.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

footer {
  @apply flex justify-end;

  button {
    @apply ml-2.5;
  }
}

.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}

.template-input {
  @apply bg-slate-25 dark:bg-slate-900 text-slate-700 dark:text-slate-100;
}
</style>
