import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const TemplatesHome = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/templates'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'TEMPLATES_SETTINGS.HEADER',
          headerButtonText: 'TEMPLATES_SETTINGS.NEW_TEMPLATE',
          icon: 'description',
          showNewButton: true,
        };
      },
      children: [
        {
          path: '',
          redirect: to => {
            return { name: 'templates_wrapper', params: to.params };
          },
        },
        {
          path: 'list',
          name: 'templates_list',
          component: TemplatesHome,
          meta: {
            permissions: ['administrator', 'agent'],
          },
        },
      ],
    },
  ],
};
