export const HEADER_TYPES = {
  text: 'text',
  image: 'image',
  document: 'document',
  video: 'video',
  location: 'location',
  none: 'none',
  media: 'media',
  audio: 'audio',
};

export const BUTTON_OPTIONS = {
  callToAction: 'call_to_action',
  quickReply: 'quick_reply',
};

export const CALL_TO_ACTION_OPTIONS = {
  phoneNumber: 'phone_number',
  url: 'url',
  copyCode: 'copy_code',
  flow: 'flow',
};

export const TYPES = {
  standard: 'standard',
  'media&interactive': 'media&interactive',
};

export const CATEGORIES = {
  marketing: 'marketing',
  utility: 'utility',
  authentication: 'authentication',
};

export const LANGUAGES = {
  af: 'Afrikaans (af)',
  sq: 'Albanian (sq)',
  ar: 'Arabic (ar)',
  az: 'Azerbaijani (az)',
  bn: 'Bengali (bn)',
  bg: 'Bulgarian (bg)',
  ca: 'Catalan (ca)',
  zh_CN: 'Chinese (CHN) (zh_CN)',
  zh_HK: 'Chinese (HKG) (zh_HK)',
  zh_TW: 'Chinese (TAI) (zh_TW)',
  hr: 'Croatian (hr)',
  cs: 'Czech (cs)',
  da: 'Danish (da)',
  nl: 'Dutch (nl)',
  en: 'English (en)',
  en_GB: 'English (UK) (en_GB)',
  en_US: 'English (US) (en_US)',
  et: 'Estonian (et)',
  fil: 'Filipino (fil)',
  fi: 'Finnish (fi)',
  fr: 'French (fr)',
  ka: 'Georgian (ka)',
  de: 'German (de)',
  el: 'Greek (el)',
  gu: 'Gujarati (gu)',
  ha: 'Hausa (ha)',
  he: 'Hebrew (he)',
  hi: 'Hindi (hi)',
  hu: 'Hungarian (hu)',
  id: 'Indonesian (id)',
  ga: 'Irish (ga)',
  it: 'Italian (it)',
  ja: 'Japanese (ja)',
  kn: 'Kannada (kn)',
  kk: 'Kazakh (kk)',
  rw_RW: 'Kinyarwanda (rw_RW)',
  ko: 'Korean (ko)',
  ky_KG: 'Kyrgyz (Kyrgyzstan) (ky_KG)',
  lo: 'Lao (lo)',
  lv: 'Latvian (lv)',
  lt: 'Lithuanian (lt)',
  mk: 'Macedonian (mk)',
  ms: 'Malay (ms)',
  ml: 'Malayalam (ml)',
  mr: 'Marathi (mr)',
  nb: 'Norwegian (nb)',
  fa: 'Persian (fa)',
  pl: 'Polish (pl)',
  pt_BR: 'Portuguese (BR) (pt_BR)',
  pt_PT: 'Portuguese (POR) (pt_PT)',
  pa: 'Punjabi (pa)',
  ro: 'Romanian (ro)',
  ru: 'Russian (ru)',
  sr: 'Serbian (sr)',
  sk: 'Slovak (sk)',
  sl: 'Slovenian (sl)',
  es: 'Spanish (es)',
  es_AR: 'Spanish (ARG) (es_AR)',
  es_ES: 'Spanish (SPA) (es_ES)',
  es_MX: 'Spanish (MEX) (es_MX)',
  sw: 'Swahili (sw)',
  sv: 'Swedish (sv)',
  ta: 'Tamil (ta)',
  te: 'Telugu (te)',
  th: 'Thai (th)',
  tr: 'Turkish (tr)',
  uk: 'Ukrainian (uk)',
  ur: 'Urdu (ur)',
  uz: 'Uzbek (uz)',
  vi: 'Vietnamese (vi)',
  zu: 'Zulu (zu)',
};
