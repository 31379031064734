import types from '../../mutation-types';
import VersionUbluxAPI from '../../../api/ublux/versionUblux';

export const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isInitializing: false,
    isDisconnect: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getVersion: _state => {
    return _state.records;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_VERSION_UBLUX_UI_FLAG, { isFetching: true });
    try {
      const response = await VersionUbluxAPI.getVersionUblux();
      commit(types.SET_VERSION_APP_UBLUX, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_VERSION_UBLUX_UI_FLAG, { isFetching: false });
    }
  },
  initWebsocket: async ({ commit }) => {
    commit(types.SET_VERSION_UBLUX_UI_FLAG, { isInitializing: true });
    try {
      VersionUbluxAPI.versionSocket();
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_VERSION_UBLUX_UI_FLAG, { isInitializing: false });
    }
  },
  disconnectWebsocket: async ({ commit }) => {
    commit(types.SET_VERSION_UBLUX_UI_FLAG, { isDisconnect: true });
    try {
      VersionUbluxAPI.disconnectSocket();
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_VERSION_UBLUX_UI_FLAG, { isDisconnect: false });
    }
  },
};

export const mutations = {
  [types.SET_VERSION_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_VERSION_APP_UBLUX](_state, data) {
    _state.records = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
