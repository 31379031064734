<script>
import { mapGetters } from 'vuex';
import PageHeader from '../../settings/SettingsSubPageHeader.vue';
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import { required } from '@vuelidate/validators';
import { useAlert } from 'dashboard/composables';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    PageHeader,
    TemplatesPicker,
  },
  emits: ['onBack', 'onClose', 'template'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      targetFrom: {},
      whatsappTemplateSelected: {},
    };
  },
  validations: {
    whatsappTemplateSelected: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      phoneNumbers: 'phoneNumbersFrom/getPhoneNumbersFrom',
      excel: 'campaigns/getDataFromExcel',
    }),
  },
  watch: {
    excel(value) {
      if (
        !value ||
        Object.values(value).length <= 0 ||
        (value && value.template && Object.values(value.template).length <= 0)
      ) {
        this.v$.$reset();
        this.targetFrom = {};
        this.whatsappTemplateSelected = {};
      }
    },
  },
  mounted() {
    if (
      this.excel &&
      this.excel.template &&
      Object.values(this.excel.template).length > 0
    ) {
      this.whatsappTemplateSelected = this.excel.template.templateSelected;
      this.getPhoneNumbersFrom(this.excel.template.channel_id);
    } else {
      this.getPhoneNumbersFrom();
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onBack() {
      this.$emit('onBack', {
        template: this.whatsappTemplateSelected,
        key: 'campaign',
      });
    },
    nextComponent() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        useAlert(this.$t('CAMPAIGN.TEMPLATE_REQUIRED'));
        return;
      }

      this.$emit('template', {
        templateSelected: this.whatsappTemplateSelected,
        channel_id: this.targetFrom.channel_id,
      });
    },
    selectWaTemplate(template) {
      this.whatsappTemplateSelected = template;
    },
    async getPhoneNumbersFrom(channelId) {
      if (this.phoneNumbers && this.phoneNumbers.length > 0) {
        if (channelId) {
          this.targetFrom = this.phoneNumbers.find(
            value => value.channel_id === channelId
          );
        } else {
          this.targetFrom = this.phoneNumbers[0];
        }
      }
    },
    updateInboxFrom() {
      this.whatsappTemplateSelected = {};
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-max min-h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <PageHeader
      :header-title="$t('CAMPAIGN.ADD.SELECT_TEMPLATE.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.SELECT_TEMPLATE.DESC')"
    />
    <div class="flex flex-wrap">
      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="nextComponent()"
      >
        <div class="w-full">
          <div class="w-full multiselect-from mb-3">
            <label>
              {{ $t('NEW_CONVERSATION.FORM.FROM.LABEL') }}
            </label>
            <div>
              <multiselect
                v-model="targetFrom"
                track-by="phoneNumber"
                label="friendlyName"
                :placeholder="$t('FORMS.MULTISELECT.SELECT')"
                selected-label=""
                select-label=""
                deselect-label=""
                :max-height="160"
                close-on-select
                :allow-empty="false"
                :options="[...phoneNumbers]"
                @update:model-value="updateInboxFrom"
              >
                <template #option="{ option }">
                  <p class="mb-0">{{ option.friendlyName }}</p>
                  <p class="mb-0 text-xs flex items-center">
                    <fluent-icon
                      icon="phone"
                      class="search-icon text-woot-600 dark:text-woot-200 mr-2"
                      size="14"
                    />
                    {{ option.phoneNumber }}
                  </p>
                </template>
              </multiselect>
            </div>
          </div>
          <TemplatesPicker
            :inbox-id="targetFrom.cw_inbox_id"
            is-accounts
            :selected-wa-template="whatsappTemplateSelected"
            hide-buttons
            show-categories-filter
            @selected-template="selectWaTemplate"
          />
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full mt-3">
          <woot-button
            variant="clear"
            type="button"
            class="mr-auto"
            @click.prevent="onClose"
          >
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button variant="smooth" type="button" @click.prevent="onBack">
            {{ $t('CAMPAIGN.ADD.BACK_BUTTON_TEXT') }}
          </woot-button>
          <woot-button type="submit">
            {{ $t('CAMPAIGN.ADD.NEXT_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.multiselect-from .multiselect {
  max-height: 30px;

  &:not(.no-margin) {
    @apply mb-1;
  }

  .multiselect__tags,
  .multiselect__select {
    min-height: 2.5rem;
  }

  .multiselect__tags .multiselect__single {
    @apply text-sm;
    line-height: 14px !important;
  }

  .multiselect__content-wrapper .multiselect__option span {
    width: 100%;
  }
}
</style>
