<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { OnClickOutside } from '@vueuse/components';
import { useMapGetter } from 'dashboard/composables/store.js';
import Button from 'dashboard/components-next/button/Button.vue';

const emit = defineEmits(['close', 'confirmationCreated']);
const { t } = useI18n();
const dialogRef = ref(null);
const dialogContentRef = ref(null);
const isRTL = useMapGetter('accounts/isRTL');
const uIFlags = useMapGetter('contactConversations/getUIFlags');

const open = () => {
  dialogRef.value?.showModal();
};
const handleDialogConfirm = value => {
  emit('confirmationCreated', value);
  dialogRef.value?.close();
};
const handleDialogCancel = value => {
  emit('confirmationCreated', value);
};
const close = () => {
  emit('close');
  dialogRef.value?.close();
};

defineExpose({ open, close });
</script>

<template>
  <Teleport to="body">
    <dialog
      ref="dialogRef"
      class="w-full max-w-lg transition-all duration-300 ease-in-out shadow-xl rounded-xl"
      :class="overflowYAuto ? 'overflow-y-auto' : 'overflow-visible'"
      :dir="isRTL ? 'rtl' : 'ltr'"
      @close="close"
    >
      <OnClickOutside @trigger="close">
        <div
          ref="dialogContentRef"
          class="flex flex-col w-full h-auto gap-6 p-6 overflow-visible text-left align-middle transition-all duration-300 ease-in-out transform bg-n-alpha-3 backdrop-blur-[100px] shadow-xl rounded-xl"
          @click.stop
        >
          <div class="flex flex-col gap-2">
            <h3 class="text-base font-medium leading-6 text-n-slate-12">
              {{ t('NEW_CONVERSATION.CONFIRM_DIALOG.TITLE') }}
            </h3>
            <slot name="description">
              <p class="mb-0 text-sm text-n-slate-11">
                {{ t('NEW_CONVERSATION.CONFIRM_DIALOG.DESCRIPTION') }}
              </p>
            </slot>
          </div>
          <div class="flex items-center justify-end w-full gap-3">
            <Button
              type="reset"
              variant="smooth"
              :label="t('NEW_CONVERSATION.CONFIRM_DIALOG.CLOSE')"
              class="w-max clear !outline-none"
              @click="close"
            />
            <Button
              type="button"
              variant="faded"
              color="slate"
              :label="t('NEW_CONVERSATION.CONFIRM_DIALOG.NEGATIVE')"
              class="w-max"
              :is-loading="uIFlags.isSearching"
              :disabled="uIFlags.isSearching"
              @click="handleDialogCancel(false)"
            />
            <Button
              type="button"
              color="blue"
              :label="t('NEW_CONVERSATION.CONFIRM_DIALOG.CONFIRM')"
              class="w-max"
              :disabled="uIFlags.isSearching"
              @click="handleDialogConfirm(true)"
            />
          </div>
        </div>
      </OnClickOutside>
    </dialog>
  </Teleport>
</template>

<style scoped>
dialog::backdrop {
  @apply dark:bg-n-alpha-white bg-n-alpha-black2;
}
</style>
