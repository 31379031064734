/* global axios */
import Cookies from 'js-cookie';

class FlowsUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  get url() {
    return import.meta.env.VITE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  // eslint-disable-next-line class-methods-use-this
  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-Origin': 'inbox',
        Authorization: this.token,
      },
    };
  }

  getFlows(wa_id) {
    return axios.chatwoot.get(
      `${this.url}/was/admin/provider/clients/flows/${wa_id}`,
      this.httpOptions
    );
  }

  sendFlowAutomation(data) {
    return axios.chatwoot.post(
      `${this.url}/cw-adapter/automations/wa-flows`,
      data,
      this.httpOptions
    );
  }

  updateFlowAutomation(id, data) {
    return axios.chatwoot.patch(
      `${this.url}/cw-adapter/automations/wa-flows/${id}`,
      data,
      this.httpOptions
    );
  }

  deleteFlowAutomation(id) {
    return axios.chatwoot.delete(
      `${this.url}/cw-adapter/automations/wa-flows/${id}`,
      this.httpOptions
    );
  }

  getFlowAutomation(id) {
    return axios.chatwoot.get(
      `${this.url}/cw-adapter/automations/wa-flows/${id}`,
      this.httpOptions
    );
  }

  sendTemplateAutomation(data) {
    return axios.chatwoot.post(
      `${this.url}/cw-adapter/automations/wa-templates`,
      data,
      this.httpOptions
    );
  }

  updateTemplateAutomation(id, data) {
    return axios.chatwoot.patch(
      `${this.url}/cw-adapter/automations/wa-templates/${id}`,
      data,
      this.httpOptions
    );
  }

  deleteTemplateAutomation(id) {
    return axios.chatwoot.delete(
      `${this.url}/cw-adapter/automations/wa-templates/${id}`,
      this.httpOptions
    );
  }
}

export default new FlowsUbluxAPI();
