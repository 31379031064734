<script>
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue';

import NextSidebar from 'next/sidebar/Sidebar.vue';
import Sidebar from '../../components/layout/Sidebar.vue';
import WootKeyShortcutModal from 'dashboard/components/widgets/modal/WootKeyShortcutModal.vue';
import AddAccountModal from 'dashboard/components/layout/sidebarComponents/AddAccountModal.vue';
import AccountSelector from 'dashboard/components/layout/sidebarComponents/AccountSelector.vue';
import AddLabelModal from 'dashboard/routes/dashboard/settings/labels/AddLabel.vue';
import NotificationPanel from 'dashboard/routes/dashboard/notifications/components/NotificationPanel.vue';

import { useUISettings } from 'dashboard/composables/useUISettings';
import { useAccount } from 'dashboard/composables/useAccount';
import { useMobile } from 'dashboard/composables/useMobile';

import wootConstants from 'dashboard/constants/globals';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { FEATURE_FLAGS } from 'dashboard/featureFlags';

const CommandBar = defineAsyncComponent(
  () => import('./commands/commandbar.vue')
);
import { emitter } from 'shared/helpers/mitt';

export default {
  components: {
    NextSidebar,
    Sidebar,
    CommandBar,
    WootKeyShortcutModal,
    AddAccountModal,
    AccountSelector,
    AddLabelModal,
    NotificationPanel,
  },
  setup() {
    const { uiSettings, updateUISettings } = useUISettings();
    const { accountId } = useAccount();
    const { isMobile } = useMobile();

    return {
      uiSettings,
      updateUISettings,
      accountId,
      isMobile,
    };
  },
  data() {
    return {
      showAccountModal: false,
      showCreateAccountModal: false,
      showAddLabelModal: false,
      showShortcutModal: false,
      isNotificationPanel: false,
      displayLayoutType: '',
      hasBanner: '',
    };
  },
  computed: {
    ...mapGetters({
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    currentRoute() {
      return ' ';
    },
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    previouslyUsedDisplayType() {
      const {
        previously_used_conversation_display_type: conversationDisplayType,
      } = this.uiSettings;
      return conversationDisplayType;
    },
    previouslyUsedSidebarView() {
      const { previously_used_sidebar_view: showSecondarySidebar } =
        this.uiSettings;
      return showSecondarySidebar;
    },
    showNextSidebar() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.CHATWOOT_V4
      );
    },
  },
  watch: {
    displayLayoutType() {
      const { LAYOUT_TYPES } = wootConstants;
      this.updateUISettings({
        conversation_display_type:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? LAYOUT_TYPES.EXPANDED
            : this.previouslyUsedDisplayType,
        show_secondary_sidebar:
          this.displayLayoutType === LAYOUT_TYPES.EXPANDED
            ? false
            : this.previouslyUsedSidebarView,
      });
    },
  },
  mounted() {
    this.handleResize();
    this.$nextTick(this.checkBanner);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('resize', this.checkBanner);
    emitter.on(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.checkBanner);
    emitter.off(BUS_EVENTS.TOGGLE_SIDEMENU, this.toggleSidebar);
  },

  methods: {
    checkBanner() {
      this.hasBanner =
        document.getElementsByClassName('woot-banner').length > 0;
    },
    handleResize() {
      const { SMALL_SCREEN_BREAKPOINT, LAYOUT_TYPES } = wootConstants;
      let throttled = false;
      const delay = 150;

      if (throttled) {
        return;
      }
      throttled = true;

      setTimeout(() => {
        throttled = false;
        if (window.innerWidth <= SMALL_SCREEN_BREAKPOINT) {
          this.displayLayoutType = LAYOUT_TYPES.EXPANDED;
        } else {
          this.displayLayoutType = LAYOUT_TYPES.CONDENSED;
        }
      }, delay);
    },
    toggleSidebar() {
      this.updateUISettings({
        show_secondary_sidebar: !this.isSidebarOpen,
        previously_used_sidebar_view: !this.isSidebarOpen,
      });
    },
    openCreateAccountModal() {
      this.showAccountModal = false;
      this.showCreateAccountModal = true;
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
    },
    toggleAccountModal() {
      this.showAccountModal = !this.showAccountModal;
    },
    toggleKeyShortcutModal() {
      this.showShortcutModal = true;
    },
    closeKeyShortcutModal() {
      this.showShortcutModal = false;
    },
    showAddLabelPopup() {
      this.showAddLabelModal = true;
    },
    hideAddLabelPopup() {
      this.showAddLabelModal = false;
    },
    openNotificationPanel() {
      this.isNotificationPanel = true;
    },
    closeNotificationPanel() {
      this.isNotificationPanel = false;
    },
    closeSecondarySidebar() {
      emitter.emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>

<template>
  <div class="flex flex-wrap app-wrapper dark:text-slate-300">
    <NextSidebar
      v-if="showNextSidebar"
      @toggle-account-modal="toggleAccountModal"
      @open-notification-panel="openNotificationPanel"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-create-account-modal="openCreateAccountModal"
    />
    <Sidebar
      v-else
      class="sidebar-over"
      :class="{
        'sidebar-open-mobile': isSidebarOpen && isMobile,
        'sidebar-open-web': isSidebarOpen && !isMobile,
      }"
      :route="currentRoute"
      :has-banner="hasBanner"
      :show-secondary-sidebar="isSidebarOpen"
      @open-notification-panel="openNotificationPanel"
      @toggle-account-modal="toggleAccountModal"
      @open-key-shortcut-modal="toggleKeyShortcutModal"
      @close-key-shortcut-modal="closeKeyShortcutModal"
      @show-add-label-popup="showAddLabelPopup"
    />
    <div
      class="app-overlay bg-[#000000] dark:bg-[#737373]"
      :class="{
        'app-overlay-open !bg-[#00000080] dark:!bg-[#73737380]': isSidebarOpen,
      }"
      @click="closeSecondarySidebar"
    />
    <main class="app-content flex flex-1 h-full min-h-0 px-0 overflow-hidden">
      <router-view />
      <CommandBar />
      <AccountSelector
        :show-account-modal="showAccountModal"
        @close-account-modal="toggleAccountModal"
        @show-create-account-modal="openCreateAccountModal"
      />
      <AddAccountModal
        :show="showCreateAccountModal"
        @close-account-create-modal="closeCreateAccountModal"
      />
      <WootKeyShortcutModal
        v-model:show="showShortcutModal"
        @close="closeKeyShortcutModal"
        @clickaway="closeKeyShortcutModal"
      />
      <NotificationPanel
        v-if="isNotificationPanel"
        @close="closeNotificationPanel"
      />
      <woot-modal
        v-model:show="showAddLabelModal"
        :on-close="hideAddLabelPopup"
      >
        <AddLabelModal @close="hideAddLabelPopup" />
      </woot-modal>
    </main>
  </div>
</template>

<style scoped lang="scss">
.app-content {
  transition: margin-left 0.5s;
}

@media (max-width: 639px) {
  .app-overlay {
    @apply fixed top-0 w-0 h-full;
    opacity: 0;
    z-index: 1;
    transition:
      background-color 0.5s,
      opacity 0.5s,
      width 1.5s;
  }

  .app-overlay-open {
    @apply w-full;
    opacity: 1;
    transition:
      opacity 1s,
      width 0.5s;
  }

  .sidebar-over {
    @apply fixed top-0 left-0 w-0 overflow-x-hidden duration-[400] z-10;
  }

  .sidebar-open-mobile {
    width: 192px;
  }

  .sidebar-open-web {
    width: 220px;
  }
}
</style>
