/* eslint no-console: 0 */
/* global axios */
import ApiClient from '../ApiClient';
import { MESSAGE_TYPE, MESSAGE_TYPE_TEXT } from 'shared/constants/messages';

export const buildCreatePayload = ({
  message,
  isPrivate,
  contentAttributes,
  echoId,
  files,
  ccEmails = '',
  bccEmails = '',
  toEmails = '',
  messageType,
  templateParams,
}) => {
  let payload;
  let message_type = messageType;

  switch (messageType) {
    case MESSAGE_TYPE.INCOMING:
      message_type = MESSAGE_TYPE_TEXT.INCOMING;
      break;
    case MESSAGE_TYPE.OUTGOING:
      message_type = MESSAGE_TYPE_TEXT.OUTGOING;
      break;
    case MESSAGE_TYPE.ACTIVITY:
      message_type = MESSAGE_TYPE_TEXT.ACTIVITY;
      break;
    case MESSAGE_TYPE.TEMPLATE:
      message_type = MESSAGE_TYPE_TEXT.TEMPLATE;
      break;

    default:
      message_type = MESSAGE_TYPE_TEXT.OUTGOING;
      break;
  }

  if (files && files.length !== 0) {
    payload = new FormData();
    if (message) {
      payload.append('content', message);
    }
    files.forEach(file => {
      payload.append('attachments[]', file);
    });
    payload.append('private', isPrivate);
    payload.append('echo_id', echoId);
    payload.append('cc_emails', ccEmails);
    payload.append('bcc_emails', bccEmails);

    if (toEmails) {
      payload.append('to_emails', toEmails);
    }
    if (contentAttributes) {
      payload.append('content_attributes', JSON.stringify(contentAttributes));
    }
  } else {
    payload = {
      content: message,
      private: isPrivate,
      echo_id: echoId,
      content_attributes: contentAttributes,
      cc_emails: ccEmails,
      bcc_emails: bccEmails,
      to_emails: toEmails,
      message_type,
      template_params: templateParams,
    };
  }
  return payload;
};

class MessageApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create({
    conversationId,
    message,
    private: isPrivate,
    contentAttributes,
    echo_id: echoId,
    files,
    ccEmails = '',
    bccEmails = '',
    toEmails = '',
    message_type: messageType,
    templateParams,
  }) {
    return axios.chatwoot({
      method: 'post',
      url: `${this.url}/${conversationId}/messages`,
      data: buildCreatePayload({
        message,
        isPrivate,
        contentAttributes,
        echoId,
        files,
        ccEmails,
        bccEmails,
        toEmails,
        messageType,
        templateParams,
      }),
    });
  }

  delete(conversationID, messageId) {
    return axios.chatwoot.delete(
      `${this.url}/${conversationID}/messages/${messageId}`
    );
  }

  retry(conversationID, messageId) {
    return axios.chatwoot.post(
      `${this.url}/${conversationID}/messages/${messageId}/retry`
    );
  }

  getPreviousMessages({ conversationId, after, before }) {
    const params = { before };
    if (after && Number(after) !== Number(before)) {
      params.after = after;
    }
    return axios.chatwoot.get(`${this.url}/${conversationId}/messages`, {
      params,
    });
  }

  translateMessage(conversationId, messageId, targetLanguage) {
    return axios.chatwoot.post(
      `${this.url}/${conversationId}/messages/${messageId}/translate`,
      {
        target_language: targetLanguage,
      }
    );
  }
}

export default new MessageApi();
