import Auth from '../api/auth';

const parseErrorCode = error => Promise.reject(error);

export default axios => {
  const { apiHost = '' } = window.chatwootConfig || {};
  const wootApi = axios.create({ baseURL: `${apiHost}/` });

  // Add Auth Headers to requests if logged in
  if (Auth.hasAuthCookie()) {
    const {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid,
    } = Auth.getAuthDataWoot();
    Object.assign(wootApi.defaults.headers.common, {
      'access-token': accessToken,
      'token-type': tokenType,
      client,
      expiry,
      uid,
    });
  }

  // Response parsing interceptor
  wootApi.interceptors.response.use(
    response => response,
    async error => {
      // Si recibes un error 401
      if (error.response.status === 401) {
        await Auth.logout();
        return parseErrorCode(error);
      }

      return parseErrorCode(error);
    }
  );
  return wootApi;
};
