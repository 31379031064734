<script>
import Cookies from 'js-cookie';
import Spinner from 'shared/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      ubluxSDK: null,
      showSpinner: true,
    };
  },
  computed: {
    ubluxSession() {
      return JSON.parse(Cookies.get('ublux_session_info'));
    },
  },
  async mounted() {
    await this.authenticate();
    this.initCallLogs();
  },
  methods: {
    initCallLogs() {
      if (this.ubluxSDK) {
        this.ubluxSDK.callLogs.init({
          containerId: '#call-logs-container',
        });
        this.ubluxSDK.callLogs.render();

        this.ubluxSDK.callLogs.on('calllogs_error', () => {
          this.showSpinner = false;
        });

        this.ubluxSDK.callLogs.on('calllogs_ready', () => {
          this.showSpinner = false;
        });

        this.ubluxSDK.callLogs.on('calllogs_loaded', () => {
          this.showSpinner = false;
        });
      }
    },
    async authenticate() {
      this.ubluxSDK = await window.UbluxSDK.init({
        authorization: {
          access_token: this.ubluxSession.accessToken,
        },
      });
    },
  },
};
</script>

<template>
  <div
    class="mb-4 h-full w-full call-logs-components"
    :class="{ 'flex justify-center': showSpinner }"
  >
    <Spinner v-if="showSpinner" size="tiny" class="mt-4" />
    <div v-show="!showSpinner" id="call-logs-container" class="h-full w-full" />
  </div>
</template>

<style lang="scss">
.call-logs-components {
  .spinner {
    &.tiny {
      z-index: 1;
      width: 2.625rem;
      height: 2.625rem;

      &:before {
        border: 3px solid rgba(247, 104, 8, 0.8);
        border-top-color: rgba(247, 104, 8, 0.3);
        margin-top: -0.48rem !important;
        margin-left: -0.26rem !important;
        width: 2.625rem !important;
        height: 2.625rem !important;
      }
    }
  }
}
</style>
