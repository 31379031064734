/* global axios */

import ApiClient from './ApiClient';

class IntegrationsAPI extends ApiClient {
  constructor() {
    super('integrations/apps', { accountScoped: true });
  }

  connectSlack(code) {
    return axios.chatwoot.post(`${this.baseUrl()}/integrations/slack`, {
      code,
    });
  }

  updateSlack({ referenceId }) {
    return axios.chatwoot.patch(`${this.baseUrl()}/integrations/slack`, {
      reference_id: referenceId,
    });
  }

  listAllSlackChannels() {
    return axios.chatwoot.get(
      `${this.baseUrl()}/integrations/slack/list_all_channels`
    );
  }

  delete(integrationId) {
    return axios.chatwoot.delete(
      `${this.baseUrl()}/integrations/${integrationId}`
    );
  }

  createHook(hookData) {
    return axios.chatwoot.post(
      `${this.baseUrl()}/integrations/hooks`,
      hookData
    );
  }

  deleteHook(hookId) {
    return axios.chatwoot.delete(
      `${this.baseUrl()}/integrations/hooks/${hookId}`
    );
  }

  requestCaptain(body) {
    return axios.chatwoot.post(
      `${this.baseUrl()}/integrations/captain/proxy`,
      body
    );
  }
}

export default new IntegrationsAPI();
