import { frontendURL } from '../../../helper/URLHelper';

import SettingsContent from '../settings/Wrapper.vue';
import Index from './Index.vue';
import CreateStepWrap from './Create/Index.vue';
import CreateCampaign from './Create/CreateCampaign.vue';
import SelectTemplate from './Create/SelectTemplate.vue';
import LoadContacts from './Create/LoadContacts.vue';
import VariablesTemplate from './Create/VariablesTemplate.vue';

const campaignsRoutes = {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.HEADER',
        icon: 'campaign',
        viewBox: '0 -960 960 960',
        headerButtonText: 'CAMPAIGN.LIST.ADD_CAMPAIGN',
      },
      children: [
        {
          path: '',
          redirect: to => {
            return { name: 'campaigns_list', params: to.params };
          },
        },
        {
          path: 'list',
          name: 'campaigns_list',
          meta: {
            permissions: ['administrator', 'agent'],
          },
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: () => {
        return {
          headerTitle: 'CAMPAIGN.HEADER',
          headerButtonText: 'CAMPAIGN.LIST.ADD_CAMPAIGN',
          icon: 'campaign',
          viewBox: '0 -960 960 960',
          newButtonRoutes: ['campaigns_new'],
          showBackButton: true,
        };
      },
      children: [
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'campaigns_new',
              component: CreateCampaign,
              meta: {
                permissions: ['administrator', 'agent'],
              },
            },
            {
              path: 'selected_template',
              name: 'campaigns_selected_template',
              component: SelectTemplate,
              meta: {
                permissions: ['administrator', 'agent'],
              },
            },
            {
              path: 'load_contacts',
              name: 'campaigns_load_contacts',
              component: LoadContacts,
              meta: {
                permissions: ['administrator', 'agent'],
              },
            },
            {
              path: 'finish',
              name: 'campaigns_finish',
              component: VariablesTemplate,
              meta: {
                permissions: ['administrator', 'agent'],
              },
            },
          ],
        },
      ],
    },
  ],
};

export default campaignsRoutes;
