<script>
import { mapGetters } from 'vuex';
import ConversationForm from 'dashboard/routes/dashboard/conversation/contact/ConversationForm.vue';

export default {
  components: {
    ConversationForm,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
    isFromConversations: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onClose', 'update:show'],
  data() {
    return {
      overflowHidden: false,
    };
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
      templates: 'templates/getTemplates',
    }),
    localShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  watch: {
    'contact.id'(id) {
      this.$store.dispatch('contacts/fetchContactableInbox', id);
    },
    contact(newValue) {
      if (
        newValue &&
        newValue.contactableInboxes &&
        newValue.contactableInboxes.length > 0
      ) {
        if (
          !newValue.contactableInboxes[0].inbox.message_templates ||
          (Object.keys(newValue.contactableInboxes[0].inbox.message_templates)
            .length <= 0 &&
            this.templates &&
            Object.keys(this.templates).length > 0)
        ) {
          this.$store.dispatch(
            'contacts/updateContactInboxTemplates',
            newValue
          );
        }
      }
    },
  },
  mounted() {
    const { id } = this.contact;
    this.$store.dispatch('contacts/fetchContactableInbox', id);
  },
  methods: {
    onCancel() {
      this.$emit('onClose');
    },
    onSuccess() {
      this.$emit('onClose');
    },
    async onSubmit(params) {
      const data = await this.$store.dispatch('contactConversations/create', {
        params,
      });
      return data;
    },
    onSendHiddenContainer(hidden) {
      this.overflowHidden = hidden;
    },
  },
};
</script>

<template>
  <woot-modal
    v-model:show="localShow"
    :overflow-hidden="overflowHidden"
    :on-close="onCancel"
  >
    <div class="flex flex-col h-auto overflow-auto">
      <woot-modal-header
        :header-title="$t('NEW_CONVERSATION.TITLE')"
        :header-content="$t('NEW_CONVERSATION.DESC')"
      />
      <ConversationForm
        v-if="inboxesList && inboxesList.length > 0"
        :contact="contact"
        :on-submit="onSubmit"
        @success="onSuccess"
        @on-close="onCancel"
        @overflow-hidden-container="onSendHiddenContainer($event)"
      />
      <div v-else class="px-8 py-9">
        <div class="w-5 h-5">
          <fluent-icon
            class="text-slate-600 dark:text-slate-400"
            icon="inbox"
            view-box="0 0 512 512"
            size="40"
          />
        </div>
        <p class="text-base font-medium mb-0 sm:ml-5 sm:mr-10">
          {{ $t('CONVERSATION.NOT_INBOXES') }}
        </p>
      </div>
    </div>
  </woot-modal>
</template>
