import { throwErrorMessage } from 'dashboard/store/utils/api';
import types from '../../mutation-types';
import ContactsUbluxAPI from '../../../api/ublux/contactsUblux';

export const state = {
  conversationsContactIds: [],
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getConversationsContactIds(_state) {
    return _state.conversationsContactIds;
  },
};

export const actions = {
  searchConversationsContact: async ({ commit }, contact_id) => {
    commit(types.SET_CONTACTS_UBLUX_UI_FLAG, { isFetching: true });
    try {
      const response =
        await ContactsUbluxAPI.getConversationsContact(contact_id);
      commit(types.ADD_CONVERSATIONS_CONTACT_UBLUX, response.data);
      commit(types.SET_CONTACTS_UBLUX_UI_FLAG, { isFetching: false });
      return response.data;
    } catch (error) {
      // Ignore error
      commit(types.SET_CONTACTS_UBLUX_UI_FLAG, { isFetching: false });
      return throwErrorMessage(error);
    }
  },
  clearConversationsContact: ({ commit }) => {
    commit(types.ADD_CONVERSATIONS_CONTACT_UBLUX, []);
  },
};

export const mutations = {
  [types.SET_CONTACTS_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_CONVERSATIONS_CONTACT_UBLUX]: ($state, conversations) => {
    $state.conversationsContactIds = [...conversations];
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
