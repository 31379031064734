<script setup>
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { OnClickOutside } from '@vueuse/components';
import { useMapGetter } from 'dashboard/composables/store.js';
import Button from 'dashboard/components-next/button/Button.vue';

const emit = defineEmits(['close', 'goTo']);
const { t } = useI18n();

const inboxes = ref([]);
const targetInbox = ref(null);
const dialogRef = ref(null);
const dialogContentRef = ref(null);
const isRTL = useMapGetter('accounts/isRTL');
const phoneNumbers = useMapGetter('phoneNumbersFrom/getPhoneNumbersFrom');
const conversationsContactIds = useMapGetter(
  'contactsUblux/getConversationsContactIds'
);

const open = () => {
  dialogRef.value?.showModal();
};
const handleDialogConfirm = () => {
  const conversation = conversationsContactIds.value.find(
    value => value.inbox_id === targetInbox.value.cw_inbox_id
  );
  emit('goTo', conversation);
  dialogRef.value?.close();
};
const close = () => {
  emit('close');
  dialogRef.value?.close();
};
function assignInboxesData() {
  const conversations = conversationsContactIds.value;
  if (conversations && conversations.length > 0) {
    const inboxIdsConversations = conversations.map(convo => convo.inbox_id);
    inboxes.value = phoneNumbers.value.filter(phone =>
      inboxIdsConversations.includes(phone.cw_inbox_id)
    );
  }
  inboxes.value = phoneNumbers.value ?? [];
}
watch(conversationsContactIds, () => assignInboxesData());
onMounted(() => {
  assignInboxesData();
});

defineExpose({ open, close });
</script>

<template>
  <Teleport to="body">
    <dialog
      ref="dialogRef"
      class="w-full max-w-lg transition-all duration-300 ease-in-out shadow-xl rounded-xl overflow-visible"
      :dir="isRTL ? 'rtl' : 'ltr'"
      @close="close"
    >
      <OnClickOutside @trigger="close">
        <div
          ref="dialogContentRef"
          class="flex flex-col w-full h-auto gap-6 p-6 overflow-visible text-left align-middle transition-all duration-300 ease-in-out transform bg-n-alpha-3 backdrop-blur-[100px] shadow-xl rounded-xl"
          @click.stop
        >
          <div class="flex flex-col gap-2">
            <h3 class="text-base font-medium leading-6 text-n-slate-12">
              {{ t('NEW_CONVERSATION.CONFIRM_DIALOG.TITLE_SELECT_INBOX') }}
            </h3>
            <slot name="description">
              <p class="mb-0 text-sm text-n-slate-11">
                {{
                  t('NEW_CONVERSATION.CONFIRM_DIALOG.DESCRIPTION_SELECT_INBOX')
                }}
              </p>
            </slot>
          </div>
          <slot name="form">
            <div class="w-full multiselect-from">
              <label>
                {{ $t('NEW_CONVERSATION.FORM.INBOX.LABEL') }}
              </label>
              <div class="multiselect-wrap--small">
                <multiselect
                  v-model="targetInbox"
                  track-by="phoneNumber"
                  label="friendlyName"
                  :placeholder="$t('FORMS.MULTISELECT.SELECT')"
                  selected-label=""
                  select-label=""
                  class="reset-base"
                  deselect-label=""
                  :max-height="160"
                  close-on-select
                  :allow-empty="false"
                  :options="[...inboxes]"
                >
                  <template #option="{ option }">
                    <p class="mb-0">{{ option.friendlyName }}</p>
                    <p class="mb-0 text-xs flex items-center">
                      <fluent-icon
                        icon="phone"
                        class="search-icon text-woot-600 dark:text-woot-200 mr-2"
                        size="14"
                      />
                      {{ option.phoneNumber }}
                    </p>
                  </template>
                </multiselect>
              </div>
            </div>
          </slot>
          <div class="flex items-center justify-between w-full gap-3">
            <Button
              type="reset"
              variant="smooth"
              :label="t('NEW_CONVERSATION.CONFIRM_DIALOG.CLOSE')"
              class="w-full clear !outline-none"
              @click="close"
            />
            <Button
              type="button"
              color="blue"
              :label="t('NEW_CONVERSATION.CONFIRM_DIALOG.GO')"
              class="w-full"
              :disabled="!targetInbox"
              @click="handleDialogConfirm"
            />
          </div>
        </div>
      </OnClickOutside>
    </dialog>
  </Teleport>
</template>

<style scoped>
dialog::backdrop {
  @apply dark:bg-n-alpha-white bg-n-alpha-black2;
}
</style>
