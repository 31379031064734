<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import InfiniteScrollContacts from './infiniteScrollContacts.vue';

export default {
  components: {
    WootDropdownDivider,
    Spinner,
    EmptyState,
    InfiniteScrollContacts,
  },
  emits: ['showConversation', 'openNewContact'],
  data() {
    return {
      searchQuery: '',
      showConversationModal: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters({
      records: 'contacts/getContactsInfinite',
      meta: 'contacts/getMetaInfinite',
      uiFlags: 'contacts/getUIFlags',
      currentAccountId: 'getCurrentAccountId',
    }),
    searchButtonClass() {
      return this.searchQuery !== '' ? 'show' : '';
    },
    showEmptySearchResult() {
      return !!this.searchQuery && this.records.length === 0;
    },
    contacts() {
      return this.records;
    },
    isLoading() {
      return this.uiFlags.isFetching;
    },
  },
  methods: {
    fetchContacts() {
      let value = '';
      if (this.searchQuery.charAt(0) === '+') {
        value = this.searchQuery.substring(1);
      } else {
        value = this.searchQuery;
      }

      const requestParams = {
        page: this.page,
        sortAttr: 'name',
        label: '',
      };

      if (!value) {
        this.$store.dispatch('contacts/getContactsInfinite', requestParams);
      } else {
        this.$store.dispatch('contacts/searchInfinite', {
          search: encodeURIComponent(value),
          ...requestParams,
        });
      }
    },
    onInputSearch(event) {
      const newQuery = event.target.value;
      const refetchAllContacts = !!this.searchQuery && newQuery === '';
      this.searchQuery = newQuery;
      if (refetchAllContacts) {
        this.page = 1;
        this.fetchContacts();
      }
    },
    onSearchSubmit() {
      this.page = 1;
      this.selectedContactId = '';
      if (this.searchQuery) {
        this.fetchContacts();
      }
    },
    loadMoreContacts() {
      if (this.contacts.length < this.meta.countInfinite) {
        this.page += 1;
        this.fetchContacts();
      }
    },
    toggleConversationModal(value) {
      this.$emit('showConversation', value);
    },
    redirectNewTeam() {
      this.$router.push(
        `/app/accounts/${this.currentAccountId}/settings/teams/new`
      );
    },
    redirectNewContact() {
      this.$emit('openNewContact');
    },
  },
};
</script>

<template>
  <div>
    <woot-modal-header
      :header-title="$t('CONVERSATION.CHAT_MOBILE.NEW_CHAT')"
    />
    <div class="p-5 sm:p-8 mx-2">
      <div class="flex items-center relative search-wrap">
        <div class="flex items-center absolute h-full left-2.5">
          <fluent-icon
            icon="search"
            size="16"
            class="h-4 text-sm leading-9 text-slate-700 dark:text-slate-200"
          />
        </div>
        <input
          type="text"
          :placeholder="$t('CONTACTS_PAGE.SEARCH_INPUT_PLACEHOLDER')"
          class="contact-search border-slate-100 dark:border-slate-600"
          :value="searchQuery"
          @keyup.enter="onSearchSubmit"
          @input="onInputSearch"
        />
        <woot-button
          :is-loading="false"
          class="clear"
          :class-names="searchButtonClass"
          @click="onSearchSubmit"
        >
          {{ $t('CONTACTS_PAGE.SEARCH_BUTTON') }}
        </woot-button>
      </div>
      <div
        class="flex flex-col mt-5 p-4 rounded-lg bg-slate-25 dark:bg-slate-900 border border-solid border-slate-50 dark:border-slate-700/50"
      >
        <woot-button
          class="!bg-white !text-slate-700 hover:!bg-woot-500/40 dark:hover:!bg-woot-500 dark:!bg-slate-800 dark:!text-slate-200"
          size="medium"
          variant="clear"
          icon="people-team-add"
          @click="redirectNewTeam"
        >
          <span class="ml-2">
            {{ $t('CONVERSATION.CHAT_MOBILE.NEW_TEAM') }}
          </span>
        </woot-button>
        <WootDropdownDivider />
        <woot-button
          class="!bg-white !text-slate-700 hover:!bg-woot-500/40 dark:hover:!bg-woot-500 dark:!bg-slate-800 dark:!text-slate-200"
          size="medium"
          variant="clear"
          icon="person-add"
          @click="redirectNewContact"
        >
          <span class="ml-2">
            {{ $t('CONVERSATION.CHAT_MOBILE.NEW_CONTACT') }}
          </span>
        </woot-button>
      </div>
      <p class="mt-5 mb-4 font-medium">
        {{ $t('CONVERSATION.CHAT_MOBILE.CONTACTS') }}
      </p>
      <EmptyState
        v-if="showEmptySearchResult"
        :title="$t('CONTACTS_PAGE.LIST.404')"
        class="mb-6 pt-0 pb-4"
      />
      <EmptyState
        v-else-if="!isLoading && !contacts.length"
        :title="$t('CONTACTS_PAGE.LIST.NO_CONTACTS')"
        class="mb-6 pt-0 pb-4"
      />
      <div
        v-else-if="isLoading && !contacts.length"
        class="items-center flex text-base justify-center mb-6"
      >
        <Spinner color-scheme="primary" />
        <span>{{ $t('CONVERSATION.CHAT_MOBILE.LOADING_CONTACTS') }}</span>
      </div>
      <InfiniteScrollContacts
        v-else
        :contacts="contacts"
        :load-more="loadMoreContacts"
        :is-loading="isLoading"
        :search-query="searchQuery"
        @show-conversation="toggleConversationModal"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
  }

  .button {
    transition: transform 100ms linear;
    @apply ml-2 h-8 right-1 absolute py-0 px-2 opacity-0 -translate-x-px invisible;
  }

  .button.show {
    @apply opacity-100 translate-x-0 visible;
  }
}

.row--user-block {
  @apply items-center flex text-left;

  .user-block {
    @apply items-start flex flex-col my-0 mx-2;
  }

  .user-name {
    @apply text-sm font-medium m-0 capitalize;
  }

  .user-email {
    @apply m-0;
  }
}
</style>
