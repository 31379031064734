/* global axios */
import Cookies from 'js-cookie';

class ContactsUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  get url() {
    return import.meta.env.VITE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  // eslint-disable-next-line class-methods-use-this
  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-Origin': 'inbox',
        Authorization: this.token,
      },
    };
  }

  getConversationsContact(contact_id) {
    // Se obtienen las conversaciones de cualquier inbox que esten con estos estados: { open, pending, snoozed }
    return axios.chatwoot.get(
      `${this.url}/cw-adapter/conversations/contact/${contact_id}`,
      this.httpOptions
    );
  }
}

export default new ContactsUbluxAPI();
