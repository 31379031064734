/* global axios */
import Cookies from 'js-cookie';
import { io } from 'socket.io-client';

class VersionUbluxAPI {
  constructor() {
    this.websocketInstance = null;
  }

  // eslint-disable-next-line class-methods-use-this
  get initWebsocket() {
    if (!this.websocketInstance) {
      this.websocketInstance = io(`${this.url}/cw-adapter/version`, {
        transports: ['websocket'],
        secure: true,
        auth: {
          token: this.token,
        },
        // cors: { origin: '*' },
        // cors: {
        //   origin: 'http://localhost:3000',
        //   methods: ['GET'],
        // },
      });

      this.websocketInstance.on('connect_error', error => {
        console.error('Error de conexión:', error);
      });

      this.websocketInstance.on('error', error => {
        console.error('Error en el WebSocket:', error);
      });
    }
    return this.websocketInstance;
  }

  // eslint-disable-next-line class-methods-use-this
  get url() {
    return import.meta.env.VITE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-Origin': 'inbox',
        Authorization: this.token,
      },
    };
  }

  // eslint-disable-next-line class-methods-use-this
  versionSocket() {
    this.initWebsocket.on('mensajeBroadcast', data => {
      console.log('mensajeBroadcast', data);
    });
    this.initWebsocket.on('connect', () => {
      console.log('Conectado al servidor de Socket.IO');
    });
    this.initWebsocket.on('disconnect', () => {
      console.log('Desconectado del servidor');
    });
  }

  disconnectSocket() {
    this.initWebsocket.disconnect();
  }

  // eslint-disable-next-line class-methods-use-this
  getVersionUblux() {
    return axios.chatwoot.get(
      `${this.url}/cw-adapter/version`,
      this.httpOptions
    );
  }
}

export default new VersionUbluxAPI();
