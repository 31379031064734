<script>
import router from '../../../index';
import PageHeader from '../../settings/SettingsSubPageHeader.vue';
import { minLength, required, requiredIf } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import InputRadioGroup from '../../settings/inbox/components/InputRadioGroup.vue';
import Cookies from 'js-cookie';
import { useAlert } from 'dashboard/composables';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    PageHeader,
    InputRadioGroup,
  },
  emits: ['onClose', 'campaign'],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      campaignName: {
        required,
        minLength: minLength(1),
      },
      // channelId: {
      //   required,
      // },
      isScheduled: {
        required,
      },
      timezone: {
        required: requiredIf(() => this.isScheduledOnTime),
      },
      startAt: {
        required: requiredIf(() => this.isScheduledOnTime),
      },
      smsFromPhoneNumber: {
        required: requiredIf(() => this.isSMSWithoutWa),
      },
    };
  },
  data() {
    return {
      campaignName: '',
      description: '',
      // channelId: '',
      isScheduled: false,
      timezone: {},
      startAt: '',
      smsOnInvalid: false,
      smsFromPhoneNumber: '',
      scheduledTypes: [
        {
          value: false,
          label: this.$t(
            'CAMPAIGN.ADD.CREATE_CAMPAIGN.SCHEDULED.SELECT.MANUALLY'
          ),
        },
        {
          value: true,
          label: this.$t(
            'CAMPAIGN.ADD.CREATE_CAMPAIGN.SCHEDULED.SELECT.ON_DATE_AT_TIME'
          ),
        },
      ],
      SMSWithoutWa: [
        {
          id: false,
          title: this.$t('CAMPAIGN.CHANGE_STATUS.PAUSED.CANCEL'),
          checked: true,
        },
        {
          id: true,
          title: this.$t('CAMPAIGN.CHANGE_STATUS.PAUSED.CONFIRM'),
          checked: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      // phoneNumbersFrom: 'phoneNumbersFrom/getPhoneNumbersFrom',
      timezones: 'timezones/getTimeZones',
      voipPhoneNumbers: 'voipNumberPhone/getVoipPhoneNumbers',
      excel: 'campaigns/getDataFromExcel',
    }),
    isScheduledOnTime() {
      return this.isScheduled;
    },
    isSMSWithoutWa() {
      return this.smsOnInvalid;
    },
    numbersWithSMSEnabled() {
      return this.voipPhoneNumbers.filter(
        phoneNumber => phoneNumber.isSmsEnabled
      );
    },
  },
  watch: {
    excel(value) {
      if (
        !value ||
        Object.values(value).length <= 0 ||
        (value && value.campaign && Object.values(value.campaign).length <= 0)
      ) {
        this.v$.$reset();

        // if (this.phoneNumbersFrom && this.phoneNumbersFrom.length > 0) {
        //   this.channelId = this.phoneNumbersFrom[0].channel_id;
        // }

        this.campaignName = '';
        this.description = '';
        this.startAt = '';
        this.isScheduled = false;
        this.smsOnInvalid = false;
        this.smsFromPhoneNumber = '';
        this.timezone = '';
      }
    },
  },
  mounted() {
    // if (this.phoneNumbersFrom && this.phoneNumbersFrom.length > 0) {
    //   this.channelId = this.phoneNumbersFrom[0].channel_id;
    // }

    if (
      this.excel &&
      this.excel.campaign &&
      Object.values(this.excel.campaign).length > 0
    ) {
      this.campaignName = this.excel.campaign.campaignData.name;
      this.description = this.excel.campaign.campaignData.description;
      // this.channelId = this.excel.campaign.campaignData.channel_id;
      this.startAt = this.excel.campaign.startAt;
      this.isScheduled = this.excel.campaign.campaignData.isScheduled;
      this.smsOnInvalid = this.excel.campaign.campaignData.smsOnInvalid;
      this.smsFromPhoneNumber =
        this.excel.campaign.campaignData.smsFromPhoneNumber;
      this.timezone = this.excel.campaign.timezone;

      this.SMSWithoutWa.forEach((option, index) => {
        if (option.id === this.smsOnInvalid) {
          this.SMSWithoutWa[index].checked = true;
        } else {
          this.SMSWithoutWa[index].checked = true;
        }
      });
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    async createTeam(data) {
      try {
        const campaign = await this.$store.dispatch('teams/create', {
          ...data,
        });

        router.replace({
          name: 'campaigns_selected_template',
          params: {
            page: 'new',
            campaignId: campaign.id,
          },
        });
      } catch (error) {
        useAlert(this.$t('TEAMS_SETTINGS.TEAM_FORM.ERROR_MESSAGE'));
      }
    },
    changeScheduled() {
      if (this.isScheduled) {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (Object.values(this.timezones).length > 0) {
          this.timezone = this.timezones.find(
            item => item.key === localTimeZone
          );
          if (!this.timezone || Object.values(this.timezone).length <= 0) {
            const parts = localTimeZone.split('/');
            if (typeof parts[1] !== 'undefined') {
              this.timezone = this.timezones.find(
                item => item.key.indexOf(parts[1]) > -1
              );
            }
          }
        } else {
          this.timezone = {
            key: 'Etc/GMT',
            value: '(UTC) Coordinated Universal Time',
          };
        }
      } else {
        this.timezone = {};
        this.startAt = '';
      }
    },
    handleSMSWithoutWaChange(value) {
      this.smsOnInvalid = value.id;

      if (this.smsOnInvalid && !this.smsFromPhoneNumber) {
        if (this.numbersWithSMSEnabled.length > 0) {
          this.smsFromPhoneNumber = this.numbersWithSMSEnabled[0].number;
        }
      } else if (!this.smsOnInvalid) {
        this.smsFromPhoneNumber = '';
      }
    },
    nextComponent() {
      this.v$.$touch();
      if (this.v$.$invalid) return;

      this.$emit('campaign', {
        campaignData: {
          name: this.campaignName,
          idUser: JSON.parse(Cookies.get('ublux_session_info')).userId,
          description: this.description,
          // channel_id: this.channelId,
          startAt: this.startAt
            ? this.startAt.slice(0, 19).replace('T', ' ')
            : null,
          isScheduled: this.isScheduled,
          smsOnInvalid: this.smsOnInvalid,
          smsFromPhoneNumber: this.smsFromPhoneNumber,
          timezone: this.timezone && this.startAt ? this.timezone.key : null,
        },
        timezone: this.timezone,
        startAt: this.startAt,
      });
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-max min-h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <PageHeader
      :header-title="$t('CAMPAIGN.ADD.CREATE_CAMPAIGN.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.CREATE_CAMPAIGN.DESC')"
    />
    <div class="flex flex-wrap">
      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="nextComponent()"
      >
        <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-5">
          <div class="w-full">
            <label :class="{ error: v$.campaignName.$error }">
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.NAME.LABEL') }}
              <input
                v-model.trim="campaignName"
                type="text"
                :placeholder="
                  $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.NAME.PLACEHOLDER')
                "
                @input="v$.campaignName.$touch()"
              />
              <span v-if="v$.campaignName.$error" class="message">
                {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.NAME.ERROR') }}
              </span>
            </label>
          </div>
          <div class="w-full">
            <label>
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.DESCRIPTION.LABEL') }}
              <input
                v-model.trim="description"
                type="text"
                :placeholder="
                  $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.DESCRIPTION.PLACEHOLDER')
                "
              />
            </label>
          </div>
        </div>
        <!-- <div class="w-full">
          <label :class="{ error: v$.channelId.$error }">
            {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SEND_FROM.LABEL') }}
            <select v-model="channelId">
              <option
                v-for="phoneNumberFrom in phoneNumbersFrom"
                :key="phoneNumberFrom.channel_id"
                :value="phoneNumberFrom.channel_id"
              >
                {{ phoneNumberFrom.friendlyName }} -
                {{ phoneNumberFrom.phoneNumber }}
              </option>
            </select>
            <span v-if="v$.channelId.$error" class="message">
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SEND_FROM.ERROR') }}
            </span>
          </label>
        </div> -->
        <div
          class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-5"
          :class="{ 'md:mt-3': !isScheduled }"
        >
          <div class="w-full">
            <label :class="{ error: v$.isScheduled.$error }">
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SCHEDULED.LABEL') }}
              <select v-model="isScheduled" @change="changeScheduled()">
                <option
                  v-for="scheduled in scheduledTypes"
                  :key="scheduled.value"
                  :value="scheduled.value"
                >
                  {{ scheduled.label }}
                </option>
              </select>
              <span v-if="v$.isScheduled.$error" class="message">
                {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SCHEDULED.ERROR') }}
              </span>
            </label>
          </div>
          <div
            v-if="!isScheduled"
            class="flex items-center w-full rounded border border-blue-400 bg-blue-50 text-sm text-blue-700 p-4"
          >
            <fluent-icon
              icon="circle-info"
              viewBox="0 0 512 512"
              size="24"
              type="solid"
              class="text-blue-700 min-w-6 mr-3"
            />
            {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SCHEDULED.INFO_MANUALLY') }}
          </div>
          <div v-if="isScheduled" class="w-full">
            <label :class="{ error: v$.startAt.$error }">
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.START_AT.LABEL') }}
              <input
                v-model.trim="startAt"
                type="datetime-local"
                :placeholder="
                  $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.START_AT.PLACEHOLDER')
                "
                @input="v$.startAt.$touch()"
              />
              <span v-if="v$.startAt.$error" class="message">
                {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.START_AT.ERROR') }}
              </span>
            </label>
          </div>
        </div>
        <div v-if="isScheduled" class="w-full">
          <label :class="{ error: v$.timezone.$error }">
            {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.TIMEZONE.LABEL') }}
            <select v-model="timezone">
              <option v-for="zone in timezones" :key="zone.key" :value="zone">
                {{ zone.value }}
              </option>
            </select>
            <span v-if="v$.timezone.$error" class="message">
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.TIMEZONE.ERROR') }}
            </span>
          </label>
        </div>
        <div
          v-if="voipPhoneNumbers && voipPhoneNumbers.length > 0"
          class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-5"
          :class="{ 'mt-4 md:mt-3': !isScheduled }"
        >
          <div class="mt-2">
            <InputRadioGroup
              :label="$t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SMS.LABEL')"
              :items="SMSWithoutWa"
              :action="handleSMSWithoutWaChange"
            />
          </div>
          <div v-if="smsOnInvalid" class="w-full">
            <label :class="{ error: v$.smsFromPhoneNumber.$error }">
              {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SEND_FROM.LABEL') }}
              <select v-model="smsFromPhoneNumber">
                <option
                  v-for="voipPhoneNumber in voipPhoneNumbers"
                  :key="voipPhoneNumber.id"
                  :value="voipPhoneNumber.number"
                >
                  {{ voipPhoneNumber.friendlyName }} ({{
                    voipPhoneNumber.number
                  }})
                </option>
              </select>
              <span v-if="v$.smsFromPhoneNumber.$error" class="message">
                {{ $t('CAMPAIGN.ADD.CREATE_CAMPAIGN.SMS_PHONE.ERROR') }}
              </span>
            </label>
          </div>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full mt-3">
          <woot-button variant="clear" type="button" @click.prevent="onClose">
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button type="submit">
            {{ $t('CAMPAIGN.ADD.NEXT_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.radio-group-label {
  @apply leading-5;
}
</style>
