<script>
import EmptyState from '../../../../components/widgets/EmptyState.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { useMobile } from 'dashboard/composables/useMobile';

export default {
  components: {
    EmptyState,
  },
  setup() {
    const { isMobile } = useMobile();
    return { isMobile };
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <EmptyState
      :title="$t('TEAMS_SETTINGS.FINISH.TITLE')"
      :message="$t('TEAMS_SETTINGS.FINISH.MESSAGE')"
      :button-text="$t('TEAMS_SETTINGS.FINISH.BUTTON_TEXT')"
    >
      <div class="w-full text-center">
        <router-link
          class="button success nice rounded"
          :to="
            !isMobile
              ? {
                  name: 'settings_teams_list',
                }
              : dashboardPath
          "
        >
          {{ $t('TEAMS_SETTINGS.FINISH.BUTTON_TEXT') }}
        </router-link>
      </div>
    </EmptyState>
  </div>
</template>
