<script>
import { ValidMimeTypes } from '../../../../../types/valid-mime-types';
import { SizeLimitTypes } from '../../../../../types/size-limit-types';

export default {
  name: 'DropZoneFiles',
  props: {
    patchFile: {
      type: Object,
      default: () => {},
    },
    headerFormat: {
      type: String,
      default: '',
    },
    acceptFile: {
      type: String,
      default: '',
    },
    sendFile: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onFile'],
  data() {
    return {
      file: null,
      fileName: '',
      fileOver: false,
      fileUrlBase64: undefined,
      fileArrayBuffer: undefined,
      invalidFormatError: false,
      oversizeError: false,
      sendFile$: this.sendFile,
      validMimeTypes: ValidMimeTypes,
      sizeLimitTypes: SizeLimitTypes,
    };
  },
  computed: {
    formatFile() {
      return this.validMimeTypes[this.headerFormat].extensions.join(', ');
    },
    sizeLimit() {
      return this.sizeLimitTypes[this.headerFormat].toString().substring(0, 2);
    },
  },
  mounted() {
    if (this.patchFile && Object.values(this.patchFile).length > 0) {
      this.file = this.patchFile.file;
      this.fileName = this.patchFile.fileName;
      this.fileUrlBase64 = this.patchFile.fileUrlBase64;
      this.fileArrayBuffer = this.patchFile.fileArrayBuffer;
    }
  },
  methods: {
    uploadFile(files) {
      if (files === null || files.length === 0) {
        return;
      }

      if (files && this.isValidFile(files[0])) {
        this.sendFile$ = false;
        this.file = files[0];
        this.fileName = files[0].name;
        const readerBase64 = new FileReader();
        readerBase64.onload = e => {
          this.fileUrlBase64 = e.target?.result;
          this.issueSecurities();
        };
        readerBase64.readAsDataURL(this.file);

        const readerArrayBuffer = new FileReader();
        readerArrayBuffer.onload = () => {
          this.fileArrayBuffer = readerArrayBuffer.result;
          this.issueSecurities();
        };
        readerArrayBuffer.readAsArrayBuffer(this.file);
      }
    },
    isValidFile(file) {
      this.invalidFormatError = false;
      this.oversizeError = false;
      let MIME = this.validMimeTypes[this.headerFormat].mimeTypes;

      if (!MIME.includes(file.type)) {
        // Notification: "Error: It must be ${ formatFile } format"
        this.invalidFormatError = true;
        return false;
      }

      if (file.size > this.sizeLimitTypes[this.headerFormat]) {
        // Notification: "Error: The file cannot exceed ${ SizeLimitTypes[this.headerFormat].toString().substring(0, 2) } MB"
        this.oversizeError = true;
        return false;
      }

      return true;
    },
    resetFile() {
      this.fileName = '';
      this.fileUrlBase64 = undefined;
      this.fileArrayBuffer = undefined;
      this.file = null;
    },
    issueSecurities() {
      this.$emit('onFile', {
        file: this.file,
        fileName: this.fileName,
        fileUrlBase64: this.fileUrlBase64,
        fileArrayBuffer: this.fileArrayBuffer,
      });
    },
    onDragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = true;
    },
    onDragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
    },
    onDrop(event) {
      event.preventDefault();
      event.stopPropagation();
      this.fileOver = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.$refs.fileDropRef.files = files;
        this.uploadFile(files);
      }
    },
  },
};
</script>

<template>
  <div class="drop-zone">
    <p class="text-slate-600 dark:text-slate-300 font-medium">
      {{
        $t('GENERAL.SELECT_FILE', {
          headerFormat: headerFormat,
        })
      }}
    </p>
    <div
      v-if="!file"
      class="upload-file rounded-md h-28 mb-5 mt-1"
      :class="{ 'border-red-600': !file && sendFile$ }"
    >
      <input
        id="fileDropRef"
        ref="fileDropRef"
        type="file"
        :accept="acceptFile"
        class="drop-zone-file hidden"
        @change="uploadFile($refs.fileDropRef.files)"
      />
      <div
        class="h-28 w-auto cursor-pointer px-2 relative"
        @dragover.prevent="onDragOver($event)"
        @dragleave.prevent="onDragLeave($event)"
        @drop.prevent="onDrop($event)"
        @click="$refs.fileDropRef.click()"
      >
        <div class="absolute ms-2 mt-2 text-slate-600 dark:text-slate-300">
          <span :class="{ 'text-red-600': !file && sendFile$ }">
            {{ $t('GENERAL.CLICK') }}
          </span>
        </div>
        <div class="flex justify-center items-center h-full pt-7">
          <fluent-icon icon="file-upload" class="file-upload" size="50" />
        </div>
      </div>
    </div>
    <div v-if="file" class="flex justify-between items-center mb-5 mt-1">
      <div v-if="headerFormat === 'image'" class="flex items-center">
        <img
          v-if="fileUrlBase64"
          :src="fileUrlBase64"
          alt="Selected image"
          class="w-6 h-auto rounded-md"
        />
        <p class="ml-2 mb-0 break-all">{{ fileName }}</p>
      </div>
      <div v-else-if="headerFormat === 'video'" class="flex items-center">
        <fluent-icon icon="movie-filter" class="movie-filter" size="24" />
        <p class="ml-2 mb-0 break-all">{{ fileName }}</p>
      </div>
      <div v-else-if="headerFormat === 'document'" class="flex items-center">
        <fluent-icon icon="description" class="description" size="24" />
        <p class="ml-2 mb-0 break-all">{{ fileName }}</p>
      </div>
      <div v-else-if="headerFormat === 'audio'" class="flex items-center">
        <fluent-icon icon="music-note" class="music-note" size="24" />
        <p class="ml-2 mb-0 break-all">{{ fileName }}</p>
      </div>
      <button @click="resetFile()">
        <fluent-icon icon="change-circle" class="change-circle" size="24" />
      </button>
    </div>
    <div>
      <p v-if="invalidFormatError" class="error">
        {{
          $t('TEMPLATES_SETTINGS.ADD.FORM.FILES_HEADER.INVALID_FORMAT', {
            formatFile: formatFile,
          })
        }}
      </p>
      <p v-else-if="oversizeError" class="error">
        {{
          $t('TEMPLATES_SETTINGS.ADD.FORM.FILES_HEADER.ERROR_SIZE', {
            sizeLimit: sizeLimit,
          })
        }}
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.drop-zone {
  .upload-file {
    border: dashed 1.5px rgb(203 213 225);
  }
}

.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
</style>
