<script>
export default {
  name: 'SettingsFiltersCategoriesUblux',
  props: {
    resetValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['categoryFilterSelection'],
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
    options() {
      return [
        {
          label: this.$t('TEMPLATES_SETTINGS.CATEGORIES.UTILITY'),
          value: 'UTILITY',
        },
        {
          label: this.$t('TEMPLATES_SETTINGS.CATEGORIES.MARKETING'),
          value: 'MARKETING',
        },
        {
          label: this.$t('TEMPLATES_SETTINGS.CATEGORIES.AUTHENTICATION'),
          value: 'AUTHENTICATION',
        },
      ];
    },
  },
  watch: {
    resetValue(value) {
      if (value) {
        this.selectedOption = null;
      }
    },
  },
  methods: {
    handleInput(isEnabled) {
      this.$emit('categoryFilterSelection', {
        type: 'category',
        data: this.selectedOption,
        isEnabled,
      });
    },
  },
};
</script>

<template>
  <div class="multiselect-wrap--small">
    <multiselect
      v-model="selectedOption"
      class="no-margin"
      :placeholder="$t('TEMPLATES_SETTINGS.FILTERS.FILTER_CATEGORY_LABEL')"
      label="label"
      track-by="value"
      :options="options"
      :option-height="24"
      :show-labels="false"
      :searchable="false"
      @select="handleInput(true)"
      @remove="handleInput(false)"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .multiselect__tags .multiselect__placeholder {
    @apply truncate w-full max-w-44;
  }
}
</style>
