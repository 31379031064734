<script>
import TemplatesPicker from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplatesPicker.vue';
import TemplateParser from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParser.vue';
export default {
  components: {
    TemplatesPicker,
    TemplateParser,
  },
  props: {
    inboxId: {
      type: Number,
      default: 0,
    },
    hideLoader: Boolean,
  },
  emits: ['pickTemplate', 'onSend', 'cancel', 'overflowHiddenContainer'],
  data() {
    return {
      selectedWaTemplate: null,
      goBack: false,
    };
  },
  computed: {
    showPicker() {
      if (!this.goBack || !this.selectedWaTemplate) {
        if (this.goBack) {
          this.onGoBack();
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    inboxId(value) {
      if (value) {
        this.selectedWaTemplate = null;
        this.onGoBack();
      }
    },
  },
  mounted() {
    this.$emit('overflowHiddenContainer', true);
  },
  methods: {
    pickTemplate(template) {
      this.$emit('pickTemplate', true);
      this.goBack = true;
      this.selectedWaTemplate = template;
    },
    onResetTemplate() {
      this.$emit('pickTemplate', false);
      this.selectedWaTemplate = null;
    },
    onSendMessage(message) {
      this.$emit('onSend', message);
      // this.onGoBack();
      // this.selectedWaTemplate = null;
    },
    onGoBack() {
      this.goBack = false;
    },
    onClose() {
      this.onResetTemplate();
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <div class="flex flex-wrap mx-0">
    <TemplatesPicker
      v-if="showPicker"
      :inbox-id="inboxId"
      is-accounts
      :selected-wa-template="selectedWaTemplate"
      @on-select="pickTemplate"
      @close="onClose"
    />
    <TemplateParser
      v-else
      :template="selectedWaTemplate"
      is-accounts
      :hide-loader="hideLoader"
      @go-back-view="onGoBack"
      @send-message="onSendMessage"
    />
  </div>
</template>
