<script>
import TemplateParser from './conversation/WhatsappTemplates/TemplateParser.vue';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    TemplateParser,
  },
  // The value types are dynamic, hence prop validation removed to work with our action schema
  // eslint-disable-next-line vue/require-prop-types
  props: {
    templates: { type: Array, required: true },
    modelValue: { type: Object, required: true },
  },
  emits: ['update:modelValue'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      selectedTemplate: {},
      variables: {},
    };
  },
  validations: {},
  computed: {
    haveTemplate() {
      return (
        this.selectedTemplate && Object.values(this.selectedTemplate).length > 0
      );
    },
    patchVariables() {
      return this.variables && Object.values(this.variables).length > 0
        ? this.variables.patch
        : {};
    },
    isPatch() {
      return (
        this.modelValue &&
        Object.values(this.modelValue).length > 0 &&
        this.modelValue.details
      );
    },
  },
  watch: {
    modelValue(newValue) {
      if (!newValue || Object.values(newValue).length <= 0) {
        this.selectedTemplate = {};
      }
    },
  },
  mounted() {
    if (this.isPatch) {
      this.selectedTemplate = this.modelValue.details.template;
      this.variables = this.modelValue.details.variables;
    }
  },
  methods: {
    updateValue() {
      if (this.isPatch) {
        this.$emit('update:modelValue', [
          this.modelValue.url,
          {
            template: this.selectedTemplate,
            variables: this.variables,
          },
          this.modelValue.metadata,
        ]);
      } else {
        this.$emit('update:modelValue', {
          template: this.selectedTemplate,
          variables: this.variables,
        });
      }
    },
    setVariables(variables) {
      this.variables = variables;
      this.updateValue();
    },
    replaceLabel(label) {
      return label.replaceAll('_', ' ');
    },
  },
};
</script>

<template>
  <div>
    <div class="multiselect-wrap--small mt-1">
      <!-- <multiselect
        v-model="selectedTemplate"
        track-by="id"
        label="name"
        :placeholder="$t('FORMS.MULTISELECT.SELECT')"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :max-height="160"
        :options="templates"
        :allow-empty="false"
        :option-height="104"
        class="!mb-0"
        @update:model-value="updateValue"
      /> -->
      <select
        v-model="selectedTemplate"
        class="w-full bg-white dark:bg-slate-900 mb-0 sm:mr-1 text-slate-800 dark:text-slate-100 border-slate-200 dark:border-slate-600"
        @change="updateValue()"
      >
        <optgroup
          v-for="(group, i) in templates"
          :key="i"
          :label="replaceLabel(group.name)"
        >
          <template v-if="group.attributes?.length > 0">
            <option
              v-for="attribute in group.attributes"
              :key="attribute.id"
              :value="attribute"
              :selected="true"
            >
              {{ replaceLabel(attribute.name) }}
            </option>
          </template>
          <template v-else>
            <option :selected="false" disabled>-</option>
          </template>
        </optgroup>
      </select>
    </div>
    <div v-if="haveTemplate" class="mt-8 mb-4">
      <TemplateParser
        :template="selectedTemplate"
        :patch-variables="patchVariables"
        mode-url-media="link"
        need-save-info
        :is-accounts="false"
        hide-loader
        hide-buttons
        hide-scroll
        get-values-by-blur
        :is-variables-required="false"
        @send-message="setVariables"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cols-2 {
  grid-template-columns: minmax(0, 1fr) 130px;
}

.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
</style>
