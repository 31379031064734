<script>
import { Letter } from 'vue-letter';
import GalleryView from '../components/GalleryView.vue';
import { MESSAGE_TYPE } from 'shared/constants/messages';

export default {
  components: { Letter, GalleryView },
  props: {
    message: {
      type: String,
      default: '',
    },
    messageType: {
      type: Number,
      default: 1,
    },
    isEmail: {
      type: Boolean,
      default: true,
    },
    displayQuotedButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showQuotedContent: false,
      showGalleryViewer: false,
      attachment: {},
      availableAttachments: [],
    };
  },
  computed: {
    isQuotedContentPresent() {
      if (!this.isEmail) {
        return this.message.includes('<blockquote');
      }
      return this.showQuotedContent;
    },
    showQuoteToggle() {
      if (!this.isEmail) {
        return false;
      }
      return this.displayQuotedButton;
    },
    isMessageDeleted() {
      const spanish = this.message
        .toLowerCase()
        .includes('este mensaje se ha eliminado');
      const english = this.message
        .toLowerCase()
        .includes('this message was deleted');

      return spanish || english;
    },
    isTemplate() {
      return this.messageType === MESSAGE_TYPE.TEMPLATE;
    },
    moreOneButtons() {
      if (this.isTemplate) {
        const buttonsDiv = document.querySelector('.buttons');

        if (buttonsDiv) {
          const buttonCount = buttonsDiv.querySelectorAll('button').length;

          if (buttonCount > 1) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    toggleQuotedContent() {
      this.showQuotedContent = !this.showQuotedContent;
    },
    handleClickOnContent(event) {
      // if event target is IMG and not close in A tag
      // then open image preview
      const isImageElement = event.target.tagName === 'IMG';
      const isWrappedInLink = event.target.closest('A');

      if (isImageElement && !isWrappedInLink) {
        this.openImagePreview(event.target.src);
      }
    },
    openImagePreview(src) {
      this.showGalleryViewer = true;
      this.attachment = {
        file_type: 'image',
        data_url: src,
        message_id: Math.floor(Math.random() * 100),
      };
      this.availableAttachments = [{ ...this.attachment }];
    },
    onClose() {
      this.showGalleryViewer = false;
      this.resetAttachmentData();
    },
    resetAttachmentData() {
      this.attachment = {};
      this.availableAttachments = [];
    },
  },
};
</script>

<template>
  <div
    class="message-text__wrap"
    :class="{
      'show--quoted': isQuotedContentPresent,
      'hide--quoted': !isQuotedContentPresent,
    }"
  >
    <div
      v-if="!isEmail"
      v-dompurify-html="message"
      class="text-content"
      :class="{
        italic: isMessageDeleted,
        template: isTemplate,
        grid2: moreOneButtons,
      }"
    />
    <div v-else @click="handleClickOnContent">
      <Letter
        class="text-content bg-white dark:bg-white text-slate-900 dark:text-slate-900 p-2 rounded-[4px]"
        :html="message"
      />
    </div>
    <button
      v-if="showQuoteToggle"
      class="py-1 text-xs cursor-pointer text-slate-300 dark:text-slate-300"
      @click="toggleQuotedContent"
    >
      <span v-if="showQuotedContent" class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-up" size="16" />
        {{ $t('CHAT_LIST.HIDE_QUOTED_TEXT') }}
      </span>
      <span v-else class="flex items-center gap-0.5">
        <fluent-icon icon="chevron-down" size="16" />
        {{ $t('CHAT_LIST.SHOW_QUOTED_TEXT') }}
      </span>
    </button>
    <GalleryView
      v-if="showGalleryViewer"
      v-model:show="showGalleryViewer"
      :attachment="attachment"
      :all-attachments="availableAttachments"
      @error="onClose"
      @close="onClose"
    />
  </div>
</template>

<style lang="scss">
.text-content {
  overflow: auto;

  ul,
  ol {
    padding-left: var(--space-two);
  }

  table {
    margin: 0;
    border: 0;

    td {
      margin: 0;
      border: 0;
    }

    tr {
      border-bottom: 0 !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: var(--font-size-normal);
  }
}

.template {
  @apply mb-1.5;

  .title {
    @apply flex items-center mb-2;

    .title__icon {
      @apply text-blue-500 dark:text-blue-700 min-w-4;
    }

    p {
      @apply text-blue-500 dark:text-blue-700 text-xs break-all ml-2;
      margin-bottom: 0 !important;
    }
  }

  .header {
    @apply mb-3;

    &:has(svg) {
      @apply flex justify-center items-center bg-white/90 rounded-lg;
    }

    svg {
      @apply py-6;
      max-width: 80px;
    }

    .header__text {
      @apply text-base font-semibold mb-0;
    }
  }

  .body {
    @apply whitespace-pre-line break-words;
  }

  .footer {
    @apply text-xs text-black-600 dark:text-white/70;
  }

  &.grid2 .buttons {
    @apply grid grid-cols-1 sm:grid-cols-2 gap-x-3;
  }

  .buttons {
    @apply mt-3;

    button {
      @apply cursor-pointer bg-white/90 dark:bg-slate-700/50 dark:text-white w-full mb-1;
      @apply border-slate-200 dark:border-slate-500 rounded-lg #{!important};
    }
  }
}

.show--quoted {
  blockquote {
    @apply block;
  }
}

.hide--quoted {
  blockquote {
    @apply hidden;
  }
}
</style>
