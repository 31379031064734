import { computed } from 'vue';

/**
 * Composable to determine if the device is mobile.
 * @returns {Boolean} - True if it is mobile, false otherwise.
 */
export function useMobile() {
  const isMobile = computed(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    }

    return false;
  });

  return {
    isMobile,
  };
}
