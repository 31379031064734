<script>
import { mapGetters } from 'vuex';
import TemplatesPicker from './TemplatesPicker.vue';
import TemplateParser from './TemplateParser.vue';

export default {
  components: {
    TemplatesPicker,
    TemplateParser,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    inboxId: {
      type: Number,
      default: 0,
    },
    hideLoader: Boolean,
  },
  emits: ['onSend', 'cancel', 'update:show'],
  data() {
    return {
      selectedWaTemplate: null,
      goBack: false,
      inbox: { friendlyName: '', phoneNumber: '' },
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      phoneNumbers: 'phoneNumbersFrom/getPhoneNumbersFrom',
    }),
    localShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    modalHeaderContent() {
      return this.selectedWaTemplate
        ? this.$t('WHATSAPP_TEMPLATES.MODAL.TEMPLATE_SELECTED_SUBTITLE', {
            templateName: this.selectedWaTemplate.name,
          })
        : this.$t('WHATSAPP_TEMPLATES.MODAL.SUBTITLE');
    },
    contactPhone() {
      return this.currentChat.meta.sender.phone_number;
    },
    showPicker() {
      if (!this.goBack || !this.selectedWaTemplate) {
        if (this.goBack) {
          this.onGoBack();
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    inboxId() {
      this.getPhoneNumbersFrom();
    },
  },
  mounted() {
    this.getPhoneNumbersFrom();
  },
  methods: {
    getPhoneNumbersFrom() {
      if (this.phoneNumbers && this.phoneNumbers.length > 0) {
        this.inbox = this.phoneNumbers.find(
          value => value.cw_inbox_id === this.inboxId
        ) || { friendlyName: '', phoneNumber: '' };
      }
    },
    pickTemplate(template) {
      this.goBack = true;
      this.selectedWaTemplate = template;
    },
    onResetTemplate() {
      this.selectedWaTemplate = null;
    },
    onGoBack() {
      this.goBack = false;
    },
    onSendMessage(message) {
      this.$emit('onSend', {
        message: message,
        phones: {
          from: this.inbox.phoneNumber,
          to: this.contactPhone,
        },
      });
      this.onGoBack();
      this.selectedWaTemplate = null;
    },
    onClose() {
      this.onResetTemplate();
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <woot-modal
    v-model:show="localShow"
    :on-close="onClose"
    overflow-hidden
    size="medium"
  >
    <woot-modal-header
      :header-title="$t('WHATSAPP_TEMPLATES.MODAL.TITLE')"
      :header-content="modalHeaderContent"
    />
    <div class="row modal-content">
      <div class="w-full mb-3 pl-3 pr-4 sm:px-0">
        <label>
          {{ $t('NEW_CONVERSATION.FORM.FROM.LABEL') }}
          <div
            v-if="inbox"
            class="h-[2.8rem] rounded-sm py-1 px-2 bg-slate-25 dark:bg-slate-900 border border-solid border-slate-75 dark:border-slate-600"
          >
            <h4
              class="flex flex-col m-0 ml-2 mr-2 text-slate-700 dark:text-slate-100 text-sm"
            >
              {{ inbox.friendlyName }}
              <span class="flex items-center text-xs font-normal">
                <fluent-icon
                  icon="phone"
                  class="search-icon text-woot-600 dark:text-woot-200 mr-2"
                  size="14"
                />
                {{ inbox.phoneNumber }}
              </span>
            </h4>
          </div>
        </label>
      </div>
      <TemplatesPicker
        v-if="showPicker"
        :inbox-id="inboxId"
        :is-accounts="false"
        :selected-wa-template="selectedWaTemplate"
        show-categories-filter
        @on-select="pickTemplate"
        @close="onClose"
      />
      <TemplateParser
        v-else
        :template="selectedWaTemplate"
        :is-accounts="false"
        :hide-loader="hideLoader"
        @go-back-view="onGoBack"
        @send-message="onSendMessage"
      />
    </div>
  </woot-modal>
</template>

<style scoped lang="scss">
.modal-container {
  overflow: hidden !important;
}

@media (max-width: 639px) {
  .modal-content {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
</style>
