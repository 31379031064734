/* global axios */
import Cookies from 'js-cookie';

class AgentsUbluxAPI {
  // eslint-disable-next-line class-methods-use-this
  get url() {
    return import.meta.env.VITE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  // eslint-disable-next-line class-methods-use-this
  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-Origin': 'inbox',
        Authorization: this.token,
      },
    };
  }

  getAgent(agent_id) {
    return axios.chatwoot.get(
      `${this.url}/cw-adapter/agent-bots/${agent_id}`,
      this.httpOptions
    );
  }

  createAgent(data) {
    return axios.chatwoot.post(
      `${this.url}/cw-adapter/agent-bots`,
      data,
      this.httpOptions
    );
  }

  updateAgent(agent_id, data) {
    return axios.chatwoot.patch(
      `${this.url}/cw-adapter/agent-bots/${agent_id}`,
      data,
      this.httpOptions
    );
  }

  deleteAgent(agent_id) {
    return axios.chatwoot.delete(
      `${this.url}/cw-adapter/agent-bots/${agent_id}`,
      this.httpOptions
    );
  }
}

export default new AgentsUbluxAPI();
