<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PassOfTime',
  props: {
    contactPhoneNumber: {
      type: String,
      default: '',
    },
    inboxId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      time: { hours: 0, minutes: 0, seconds: 0 },
      timer: null,
      phoneNumbers: [],
      isConversationView: false,
    };
  },
  computed: {
    ...mapGetters({
      timeLeft: 'timeLeft/getTimeLeft',
    }),
    strokeDashArray() {
      const r = 14.2013;
      const circumference = 2 * Math.PI * r;
      return circumference;
    },
    strokeDashOffset() {
      const r = 14.2013;
      const circumference = 2 * Math.PI * r;

      // Convertir el tiempo total desde el backend a segundos
      const totalSeconds =
        this.time.hours * 3600 + this.time.minutes * 60 + this.time.seconds;

      // Obtener el tiempo restante en segundos
      const remainingSeconds =
        this.time.hours * 3600 + this.time.minutes * 60 + this.time.seconds;

      // Calcular el progreso como un valor entre 0 y 1
      const progress = remainingSeconds / totalSeconds;

      // El offset debe reflejar la cantidad de progreso restante
      return circumference * (1 - progress);
    },
    formattedTime() {
      const { hours, minutes, seconds } = this.time;
      if (hours === 0 && minutes === 0 && seconds > 0) {
        return `${String(seconds).padStart(2, '0')}s`;
      }

      if (hours === 0) {
        return `${String(minutes).padStart(2, '0')}m`;
      }

      return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(
        2,
        '0'
      )}m`;
    },
    totalSecondsRemaining() {
      return (
        this.time.hours * 3600 + this.time.minutes * 60 + this.time.seconds
      );
    },
  },
  watch: {
    isConversationView(newValue) {
      if (!newValue) {
        this.stopTimer();
      }
    },
    timeLeft(newValue) {
      if (newValue && newValue.length > 0) {
        this.assignTime(newValue);
      }
    },
    async contactPhoneNumber(newValue) {
      if (newValue) {
        await this.$store.dispatch('timeLeft/get', {
          inbox_id: this.inboxId,
          contact_wa_id: newValue.replace('+', ''),
        });
      }
    },
    async inboxId(newValue) {
      if (newValue) {
        await this.$store.dispatch('timeLeft/get', {
          inbox_id: newValue,
          contact_wa_id: this.contactPhoneNumber.replace('+', ''),
        });
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('timeLeft/get', {
      inbox_id: this.inboxId,
      contact_wa_id: this.contactPhoneNumber.replace('+', ''),
    });
    this.assignTime(this.timeLeft);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  methods: {
    startTimer() {
      if (this.timer) {
        return;
      }

      setTimeout(() => {
        if (this.time.minutes > 0) {
          this.time.seconds = 59;
          this.time.minutes -= 1;

          this.timer = setInterval(() => {
            this.decrementTime();
          }, 1000);
        } else if (this.time.hours <= 0 && this.time.minutes <= 0) {
          this.getStopTimer();
        }
      }, this.time.seconds * 1000);
    },
    decrementTime() {
      if (this.time.seconds > 0) {
        // Si hay segundos, restamos 1 segundo
        this.time.seconds -= 1;
      } else if (this.time.minutes > 0) {
        // Si los segundos llegaron a 0, restamos 1 minuto y reiniciamos los segundos a 59
        this.time.seconds = 59;
        this.time.minutes -= 1;
      } else if (this.time.hours > 0) {
        // Si los minutos llegaron a 0, restamos 1 hora, reiniciamos los minutos a 59 y los segundos a 59
        this.time.seconds = 59;
        this.time.minutes = 59;
        this.time.hours -= 1;
      } else {
        // Si las horas, minutos y segundos llegan a 0, detenemos el temporizador y hacemos la llamada al backend
        this.getStopTimer();
      }
    },
    getStopTimer() {
      this.stopTimer();
      this.$store.dispatch('timeLeft/get', {
        inbox_id: this.inboxId,
        contact_wa_id: this.contactPhoneNumber.replace('+', ''),
      });
    },
    stopTimer() {
      clearInterval(this.timer);
      this.isConversationView = false;
    },
    async fetchTimeFromBackend() {
      try {
        const timeContact = this.timeLeft.find(
          value =>
            value.inbox_id === this.inboxId &&
            value.contact_wa_id === this.contactPhoneNumber.replace('+', '')
        );

        this.time = timeContact.time_left;

        if (timeContact.new_message && this.totalSecondsRemaining <= 0) {
          this.time = { hours: 23, minutes: 59, seconds: 59 };
        }

        if (this.totalSecondsRemaining > 0 || timeContact.new_message) {
          if (timeContact.new_message && !this.isConversationView) {
            this.isConversationView = true;
          }
          this.startTimer();
        }
      } catch (error) {
        this.isConversationView = false;
      }
    },
    assignTime(newTime) {
      const time = newTime.find(
        value =>
          value.inbox_id === this.inboxId &&
          value.contact_wa_id === this.contactPhoneNumber.replace('+', '')
      );
      if (time && time.time_left) {
        const totalTime =
          time.time_left.hours * 3600 +
          time.time_left.minutes * 60 +
          time.time_left.seconds;
        this.isConversationView = totalTime > 0;
        this.fetchTimeFromBackend();
      }
    },
  },
};
</script>

<template>
  <div>
    <div
      v-if="isConversationView"
      class="w-max mx-auto bg-white dark:bg-slate-600 rounded-lg mt-4"
    >
      <div
        class="flex items-center text-xs text-slate-500 dark:text-slate-300 leading-4 hover:text-slate-900 dark:hover:text-slate-100 py-1.5 px-3"
      >
        <span>
          {{ $t('CONVERSATION.CONVERSATION_EXPIRATION') }}
          {{ formattedTime }}
        </span>
        <!-- <div class="flex items-center justify-center">
        <svg
          class="border-indigo-600"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 34 34"
          fill="none"
        >
          <g id="Component 2">
            <circle
              id="Ellipse 717"
              cx="17.0007"
              cy="17.0001"
              r="14.2013"
              stroke="#D1D5DB"
              stroke-width="4"
              stroke-dasharray="2 3"
            />
            <circle
              id="progress-path"
              cx="17.0007"
              cy="17.0001"
              r="14.2013"
              :stroke-dasharray="strokeDashArray"
              :stroke-dashoffset="strokeDashOffset"
              stroke-width="4"
              stroke-linecap="round"
              transform="rotate(-90 17 17)"
            />
          </g>
        </svg>
      </div> -->
      </div>
    </div>
  </div>
</template>
