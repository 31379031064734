export const CONTENT_TYPES = {
  INCOMING_EMAIL: 'incoming_email',
};

export const CONTENT_TYPE = {
  TEXT: 'text', // 0
  INPUT_TEXT: 'input_text', // 1
  INPUT_TEXTAREA: 'input_textarea', // 2
  INPUT_EMAIL: 'input_email', // 3
  INPUT_SELECT: 'input_select', // 4
  CARDS: 'cards', // 5
  FORM: 'form', // 6
  ARTICLE: 'article', // 7
  INCOMING_EMAIL: 'incoming_email', // 8
  INPUT_CSAT: 'input_csat', // 9
  INTEGRATIONS: 'integrations', // 10
  STICKERS: 'sticker', // 11
};
