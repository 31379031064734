import * as types from '../mutation-types';
import ContactAPI from '../../api/contacts';
import ConversationApi from '../../api/conversations';
import TemplatesUbluxAPI from '../../api/ublux/templatesUblux';
import { MESSAGE_TYPE_TEXT } from 'shared/constants/messages';

export const createMessagePayload = (payload, message) => {
  const { content, cc_emails, bcc_emails } = message;
  payload.append('message[content]', content);
  if (cc_emails) payload.append('message[cc_emails]', cc_emails);
  if (bcc_emails) payload.append('message[bcc_emails]', bcc_emails);
};

export const createConversationPayload = ({ params, contactId, files }) => {
  const { inboxId, message, sourceId, mailSubject, assigneeId } = params;
  const payload = new FormData();

  if (message) {
    createMessagePayload(payload, message);
  }

  if (files && files.length > 0) {
    files.forEach(file => payload.append('message[attachments][]', file));
  }

  payload.append('inbox_id', inboxId);
  payload.append('contact_id', contactId);
  payload.append('source_id', sourceId);
  payload.append('additional_attributes[mail_subject]', mailSubject);
  payload.append('assignee_id', assigneeId);

  return payload;
};

export const createWhatsAppConversationPayload = ({ params, messageText }) => {
  const { inboxId, message, contactId, sourceId, assigneeId } = params;

  const payload = {
    inbox_id: inboxId,
    contact_id: contactId,
    source_id: sourceId,
    message: {
      ...message,
      content: messageText,
      message_type: MESSAGE_TYPE_TEXT.ACTIVITY,
    },
    assignee_id: assigneeId,
  };

  return payload;
};

const setNewConversationPayload = ({ params, contactId, files }) => {
  return createConversationPayload({
    params,
    contactId,
    files,
  });
};

const setNewConversationPayloadWhatsapp = ({ params, messageText }) => {
  return createWhatsAppConversationPayload({ params, messageText });
};

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getContactConversation: $state => id => {
    return $state.records[Number(id)] || [];
  },
};

export const actions = {
  create: async ({ commit }, { params }) => {
    commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
      isCreating: true,
    });
    const { contactId, files } = params;

    try {
      const payload = setNewConversationPayload({
        params,
        contactId,
        files,
      });

      const { data } = await ConversationApi.create(payload);
      commit(types.default.ADD_CONTACT_CONVERSATION, {
        id: contactId,
        data,
      });

      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isCreating: false,
      });
    }
  },
  get: async ({ commit }, contactId) => {
    commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
      isFetching: true,
    });
    try {
      const response = await ContactAPI.getConversations(contactId);
      commit(types.default.SET_CONTACT_CONVERSATIONS, {
        id: contactId,
        data: response.data.payload,
      });
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isFetching: false,
      });
    } catch (error) {
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isFetching: false,
      });
    }
  },
  createConversationWhatsapp: async (
    { commit },
    { params, phones, messageText }
  ) => {
    commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
      isCreating: true,
    });
    const { contactId } = params;

    try {
      const payload = setNewConversationPayloadWhatsapp({
        params,
        messageText,
      });

      // Para la parte de contacto
      if (
        params.originalPayload.templateMedia &&
        Object.entries(params.originalPayload.templateMedia).length > 0
      ) {
        await TemplatesUbluxAPI.fetchDataMedia(params.originalPayload, phones);
      } else {
        const template = {
          ...params.originalPayload.templateJson,
          to: phones.to,
        };
        await TemplatesUbluxAPI.submit(template, phones.from);
      }

      const { data } = await ConversationApi.create(payload);
      commit(types.default.ADD_CONTACT_CONVERSATION, {
        id: contactId,
        data,
      });

      return data;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG, {
        isCreating: false,
      });
    }
  },
};

export const mutations = {
  [types.default.SET_CONTACT_CONVERSATIONS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_CONTACT_CONVERSATIONS]: ($state, { id, data }) => {
    $state.records = {
      ...$state.records,
      [id]: data,
    };
  },
  [types.default.ADD_CONTACT_CONVERSATION]: ($state, { id, data }) => {
    const conversations = $state.records[id] || [];

    const updatedConversations = [...conversations];
    const index = conversations.findIndex(
      conversation => conversation.id === data.id
    );

    if (index !== -1) {
      updatedConversations[index] = { ...conversations[index], ...data };
    } else {
      updatedConversations.push(data);
    }

    $state.records = {
      ...$state.records,
      [id]: updatedConversations,
    };
  },
  [types.default.DELETE_CONTACT_CONVERSATION]: ($state, id) => {
    const { [id]: deletedRecord, ...remainingRecords } = $state.records;
    $state.records = remainingRecords;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
