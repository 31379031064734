<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    campaignId: {
      type: String,
      default: '',
    },
    showModal: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['cancel'],
  data() {
    return {
      isModalVisible: this.showModal,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaigns/getUIFlags',
      contactList: 'campaigns/getCampaignContacts',
    }),
    campaignName() {
      return this.contactList && this.contactList.length > 0
        ? this.contactList[0].wasCampaign.name
        : '';
    },
    tableHeaders() {
      return [
        this.$t('CAMPAIGN.CAMPAIGN_CONTACTS.TABLE_HEADER.PHONE_NUMBER'),
        this.$t('CAMPAIGN.CAMPAIGN_CONTACTS.TABLE_HEADER.STATUS'),
      ];
    },
  },
  methods: {
    onClose() {
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <woot-modal
    v-model:show="isModalVisible"
    :on-close="onClose"
    overflow-hidden
    size="medium"
  >
    <woot-modal-header
      :header-title="$t('CAMPAIGN.CAMPAIGN_CONTACTS.TITLE')"
      :header-content="
        $t('CAMPAIGN.CAMPAIGN_CONTACTS.SUBTITLE', {
          campaignName: campaignName,
        })
      "
    />
    <div class="row modal-content">
      <div class="w-full lg:w-3/5 overflow-y-auto table-content">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('CAMPAIGN.CAMPAIGN_CONTACTS.LOADING')"
        />
        <div v-else>
          <p v-if="!contactList.length">
            {{ $t('CAMPAIGN.CAMPAIGN_CONTACTS.404') }}
          </p>
          <table v-else class="woot-table">
            <thead>
              <th v-for="thHeader in tableHeaders" :key="thHeader">
                {{ thHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="contact in contactList" :key="contact.id">
                <td>
                  <span>{{ contact.to }}</span>
                </td>
                <td>
                  <span>
                    {{ contact.status }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex justify-end w-full mt-6">
        <woot-button variant="smooth" @click.prevent="onClose">
          {{ $t('CAMPAIGN.CAMPAIGN_CONTACTS.CLOSE') }}
        </woot-button>
      </div>
    </div>
  </woot-modal>
</template>

<style scoped lang="scss">
.modal-container {
  overflow: hidden !important;
}

@media (max-width: 639px) {
  .modal-content {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .table-content {
    height: calc(100vh - 250px);
  }
}
</style>
