<script>
import { mapGetters } from 'vuex';
import SwitchLayout from './SwitchLayout.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import NewChatMobile from 'dashboard/components/widgets/conversation/newChatMobile.vue';
import CreateContact from 'dashboard/routes/dashboard/conversation/contact/CreateContact.vue';
import { useMobile } from 'dashboard/composables/useMobile';
import { emitter } from 'shared/helpers/mitt';
import NewConversation from 'dashboard/routes/dashboard/conversation/contact/NewConversation.vue';

export default {
  components: {
    SwitchLayout,
    NewChatMobile,
    CreateContact,
    NewConversation,
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  props: {
    isOnExpandedLayout: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['toggleConversationLayout'],
  setup() {
    const { isMobile } = useMobile();

    return {
      isMobile,
    };
  },
  data() {
    return {
      showNewChatMobile: false,
      showConversationModal: false,
      showCreateModal: false,
      contactId: '',
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    searchUrl() {
      return frontendURL(`accounts/${this.accountId}/search`);
    },
    contact() {
      if (this.contactId) {
        return this.$store.getters['contacts/getContactInfinite'](
          this.contactId
        );
      }
      return {};
    },
  },
  methods: {
    onShowNewChatModal() {
      this.showNewChatMobile = true;
    },
    closeNewChatModal() {
      this.showNewChatMobile = false;
    },
    toggleConversationModal() {
      this.showConversationModal = !this.showConversationModal;
    },
    onToggleCreate() {
      this.showCreateModal = !this.showCreateModal;
    },
    handleShowConversation(value) {
      this.contactId = value.id;
      this.toggleConversationModal();
      emitter.emit(
        BUS_EVENTS.NEW_CONVERSATION_MODAL,
        this.showConversationModal
      );
      this.closeNewChatModal();
    },
  },
};
</script>

<template>
  <div class="relative">
    <div
      class="flex px-4 pb-1 flex-row gap-1 pt-2.5 border-b border-transparent"
    >
      <woot-sidemenu-icon
        size="tiny"
        class="relative top-0 ltr:-ml-1.5 rtl:-mr-1.5"
      />
      <router-link
        :to="searchUrl"
        class="inline-flex items-center flex-1 h-7 gap-1 px-2 py-0 text-left rounded-md search-link rtl:mr-3 rtl:text-right border border-slate-300 bg-white dark:bg-slate-800"
      >
        <div class="flex">
          <fluent-icon
            icon="search"
            class="search--icon text-slate-500 dark:text-slate-200"
            size="10"
          />
        </div>
        <p
          class="mb-0 overflow-hidden text-xs search--label max-w-[50vw] whitespace-nowrap text-ellipsis text-slate-500 dark:text-slate-200 truncate ml-1"
        >
          {{ $t('CONVERSATION.SEARCH_MESSAGES') }}
        </p>
      </router-link>
      <SwitchLayout
        v-show="!isMobile"
        :is-on-expanded-layout="isOnExpandedLayout"
        @toggle="$emit('toggleConversationLayout')"
      />
      <woot-button
        v-show="isMobile"
        class="!bg-[#0c7dfb]/30 !text-[#0c7dfb] hover:bg-[#0c7dfb]/60 dark:hover:text-white"
        size="tiny"
        variant="smooth"
        icon="plus-sign"
        @click="onShowNewChatModal"
      />
    </div>
    <woot-modal
      v-model:show="showNewChatMobile"
      :on-close="closeNewChatModal"
      size="medium"
    >
      <NewChatMobile
        v-if="showNewChatMobile"
        @open-new-contact="onToggleCreate"
        @show-conversation="handleShowConversation"
      />
    </woot-modal>
    <NewConversation
      v-if="contact && contact.id"
      :show="showConversationModal"
      :contact="contact"
      is-from-conversations
      @on-close="toggleConversationModal"
    />
    <CreateContact :show="showCreateModal" @on-close="onToggleCreate" />
  </div>
</template>

<style lang="scss" scoped>
.search-link {
  &:hover {
    .search--icon,
    .search--label {
      @apply hover:text-woot-500 dark:hover:text-woot-500;
    }
  }
}
</style>
