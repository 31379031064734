<script>
import router from '../../../index';
import { useAlert } from 'dashboard/composables';

export default {
  data() {
    return {
      campaign: {},
      template: {},
      contacts: {},
      variables: {},
    };
  },
  computed: {
    items() {
      const steps = [
        'WIZARD_CREATE',
        'WIZARD_ADD_TEMPLATE_CAMPAIGN',
        'WIZARD_ADD_CONTACTS_CAMPAIGN',
        'WIZARD_FINISH',
      ];

      const routes = {
        WIZARD_CREATE: 'campaigns_new',
        WIZARD_ADD_TEMPLATE_CAMPAIGN: 'campaigns_selected_template',
        WIZARD_ADD_CONTACTS_CAMPAIGN: 'campaigns_load_contacts',
        WIZARD_FINISH: 'campaigns_finish',
      };

      return steps.map(step => {
        return {
          title: this.$t(`CAMPAIGN.CREATE_FLOW.${step}.TITLE`),
          body: this.$t(`CAMPAIGN.CREATE_FLOW.${step}.BODY`),
          route: routes[step],
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch('campaigns/getDataFromExcel', {});
    this.onClearData();
  },
  methods: {
    nextRouter(name) {
      router.replace({
        name: name,
        params: {
          page: 'new',
        },
      });
    },
    getCampaign(campaign) {
      this.campaign = campaign;
      this.setValues();
      this.nextRouter('campaigns_selected_template');
    },
    getTemplate(template) {
      this.template = template;

      if (template.templateSelected.id !== this.template.templateSelected.id) {
        this.variables = {};
      }

      this.setValues();
      this.nextRouter('campaigns_load_contacts');
    },
    getContacts(contacts) {
      this.contacts = contacts;
      this.setValues();
      this.nextRouter('campaigns_finish');
    },
    getVariablesTemplate(variables) {
      this.variables = variables.variables;
      this.setValues();

      if (variables.submit) {
        this.onSubmit();
      }
    },
    onClose() {
      this.nextRouter('campaigns_list');
      this.onClearData();
      this.$store.dispatch('campaigns/getDataFromExcel', {});
    },
    onClearData() {
      this.campaign = {};
      this.template = {};
      this.contacts = {};
      this.variables = {};
    },
    onBack(event) {
      // regresar
      switch (event.key) {
        case 'campaign':
          this.nextRouter('campaigns_new');
          break;
        case 'template':
          this.nextRouter('campaigns_selected_template');
          break;
        case 'contacts':
          this.nextRouter('campaigns_load_contacts');
          break;
        case 'variables':
          this.nextRouter('campaigns_finish');
          break;
        default:
          break;
      }
    },
    setValues() {
      this.$store.dispatch('campaigns/getDataFromExcel', {
        campaign: this.campaign,
        template: this.template,
        contacts: this.contacts,
        variables: this.variables,
      });
    },
    async onSubmit() {
      try {
        await this.$store.dispatch('campaigns/create', {
          campaign: {
            ...this.campaign.campaignData,
            channel_id: this.template.channel_id,
            wasTemplateLanguageId:
              this.template.templateSelected.wasLanguage.code,
            wasTemplateId: this.template.templateSelected.id,
          },
          variables: this.variables,
        });

        useAlert(this.$t('CAMPAIGN.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message || this.$t('CAMPAIGN.ADD.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div
    class="overflow-auto p-4 max-w-full my-auto flex flex-row flex-nowrap h-full bg-slate-25 dark:bg-slate-800"
  >
    <woot-wizard class="hidden md:block !w-1/4" :items="items" />
    <router-view
      @campaign="getCampaign"
      @template="getTemplate"
      @contacts="getContacts"
      @variables="getVariablesTemplate"
      @on-close="onClose()"
      @on-back="onBack"
    />
  </div>
</template>
