<script>
import { useInfiniteScroll } from '@vueuse/core';
import Spinner from 'shared/components/Spinner.vue';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';

export default {
  components: {
    WootDropdownDivider,
    Thumbnail,
    Spinner,
  },
  props: {
    loadMore: {
      type: Function,
      default: () => {},
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  emits: ['showConversation'],
  mounted() {
    const scrollContainer = this.$refs.scrollContainer;

    if (scrollContainer) {
      useInfiniteScroll(
        scrollContainer,
        () => {
          if (!this.isLoading) {
            this.loadMore();
          }
        },
        { distance: 10 }
      );
    }
  },
  methods: {
    toggleConversationModal(value) {
      this.$emit('showConversation', value);
    },
  },
};
</script>

<template>
  <div
    ref="scrollContainer"
    class="overflow-auto max-h-[calc(100vh-360px)] flex flex-col p-4 mb-4 rounded-lg bg-slate-25 dark:bg-slate-900 border border-solid border-slate-50 dark:border-slate-700/50"
  >
    <div
      v-for="(contact, index) in contacts"
      :key="index"
      :class="{
        'rounded-t-lg': index === 0,
        'rounded-b-lg': index === contacts.length - 1 && !isLoading,
      }"
      class="bg-white dark:bg-slate-800"
    >
      <woot-button
        class="!bg-white dark:!bg-slate-800 !text-slate-700 hover:!bg-woot-500/40 dark:hover:!bg-woot-500 dark:!text-slate-200 h-11 w-full"
        variant="clear"
        @click="toggleConversationModal(contact)"
      >
        <div class="row--user-block">
          <Thumbnail
            :src="contact.thumbnail"
            size="32px"
            :username="contact.name"
            :status="contact.availability_status"
          />
          <div class="user-block">
            <h6
              class="text-base overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {{ contact.name }}
            </h6>
          </div>
        </div>
      </woot-button>
      <WootDropdownDivider
        v-if="index !== contacts.length - 1"
        class="!border-slate-400/20 dark:!border-slate-200/20"
      />
    </div>
    <div
      v-if="isLoading && !searchQuery"
      class="bg-white dark:bg-slate-800 rounded-b-lg flex flex-col"
    >
      <WootDropdownDivider
        class="!border-slate-400/20 dark:!border-slate-200/20"
      />
      <Spinner
        size="large"
        color-scheme="primary"
        class="mx-auto !p-[1.5rem] before:!border-slate-200/80 w-full"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.row--user-block {
  @apply items-center flex text-left;

  .user-block {
    @apply items-start flex flex-col my-0 mx-2;
  }

  .user-name {
    @apply text-sm font-medium m-0 capitalize;
  }

  .user-email {
    @apply m-0;
  }
}
</style>
