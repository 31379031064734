import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import CampaignsUbluxAPI from '../../../api/ublux/campaignsUblux';
import TemplatesUbluxAPI from '../../../api/ublux/templatesUblux';
import AnalyticsHelper from '../../../helper/AnalyticsHelper';
import { CAMPAIGNS_EVENTS } from '../../../helper/AnalyticsHelper/events';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';

export const state = {
  campaignContacts: [],
  excelData: {},
  records: [],
  campaignStatistics: [],
  meta: {
    totalItems: 0,
    currentPage: 1,
    totalPages: 0,
    itemsPerPage: 100,
  },
  uiFlags: {
    isStatusCampaign: false,
    isDownloadExcel: false,
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCampaigns: _state => {
    return _state.records;
  },
  getMeta: _state => {
    return _state.meta;
  },
  getDataFromExcel: _state => {
    return _state.excelData;
  },
  getCampaignContacts: _state => {
    return _state.campaignContacts;
  },
  getStatistics: _state => {
    return _state.campaignStatistics;
  },
};

export const actions = {
  get: async ({ commit }, { page = 1, limit = 100, filters = null } = {}) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CampaignsUbluxAPI.getCampaigns(
        page,
        limit,
        filters
      );

      const campaignsData = data.data.map(campaign => {
        if (
          campaign &&
          campaign.startAt &&
          campaign.wasCampaignStatus.id === 'ToBeStarted'
        ) {
          const dateLuxon = DateTime.fromJSDate(new Date(campaign.startAt), {
            zone: 'utc',
          });
          if (dateLuxon >= DateTime.now()) {
            const dateStatus = dateLuxon
              .setZone(campaign.timezone)
              .toFormat('LLL dd, yyyy hh:mm a');
            return {
              ...campaign,
              wasCampaignStatus: {
                id: 'Scheduled',
                name: `Scheduled for ${dateStatus}`,
              },
            };
          }
        }
        return campaign;
      });

      commit(types.CLEAR_CAMPAIGNS);
      commit(types.SET_CAMPAIGNS, campaignsData);
      commit(types.SET_CAMPAIGN_META, data.meta);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },
  getDataFromExcel: async ({ commit }, data) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      commit(types.SET_EXCEL_DATA_CAMPAIGNS, data);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },
  getCampaignContacts: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CampaignsUbluxAPI.getCampaignContacts(id);
      commit(types.SET_CONTACTS_CAMPAIGNS, data);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit, dispatch }, campaignParams) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isCreating: true });
    try {
      let message = [];
      const responseCampaign = await CampaignsUbluxAPI.createCampaign(
        campaignParams.campaign
      );

      if (
        campaignParams.variables.templateMedia &&
        Object.entries(campaignParams.variables.templateMedia).length > 0
      ) {
        const responseMedia = await TemplatesUbluxAPI.postMedia(
          campaignParams.variables.templateMedia.fileArrayBuffer,
          campaignParams.variables.templateMedia.type
        );

        message = campaignParams.variables.templateJson.map(value => {
          const headerComponent = value.template.components.find(
            component => component.type === 'header'
          );

          headerComponent.parameters = [
            {
              type: campaignParams.variables.templateMedia.format,
              [campaignParams.variables.templateMedia.format]: {
                id: responseMedia.data.media[0].id,
              },
            },
          ];

          return {
            data: value.template.components,
            wasCampaignId: responseCampaign.data.id,
            to: value.to,
          };
        });
      } else {
        message = campaignParams.variables.templateJson.map(value => ({
          wasCampaignId: responseCampaign.data.id,
          to: value.to,
          data: value.template.components,
        }));
      }

      await CampaignsUbluxAPI.createMessage(message);
      await dispatch('get');

      AnalyticsHelper.track(CAMPAIGNS_EVENTS.CREATE_CAMPAIGN);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isCreating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isDeleting: true });
    try {
      await CampaignsUbluxAPI.delete(id);

      AnalyticsHelper.track(CAMPAIGNS_EVENTS.DELETE_CAMPAIGN);
      commit(types.DELETE_CAMPAIGN, id);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isDeleting: false });
    }
  },
  downloadExcel: async ({ commit }, campaignParams) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isDownloadExcel: true });
    try {
      const response = await CampaignsUbluxAPI.downloadExcel(
        campaignParams.campaignId
      );
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, `${campaignParams.name}.xlsx`);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isDownloadExcel: false });
    }
  },
  setStatusCampaign: async ({ commit, dispatch }, campaignParams) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isStatusCampaign: true });
    try {
      await CampaignsUbluxAPI.setStatusCampaign(campaignParams);
      dispatch('get');
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isStatusCampaign: false });
    }
  },
  getCampaignStatistics: async ({ commit }, filters) => {
    commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await CampaignsUbluxAPI.getStatistics(filters);

      commit(types.SET_CAMPAIGNS_STATISTICS, data);
    } catch (error) {
      // handleError(error);
    } finally {
      commit(types.SET_CAMPAIGNS_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_CAMPAIGNS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_CAMPAIGNS]: MutationHelpers.set,
  [types.ADD_CAMPAIGN]: MutationHelpers.create,
  [types.DELETE_CAMPAIGN]: MutationHelpers.destroy,
  [types.CLEAR_CAMPAIGNS]: $state => {
    $state.records = {};
  },
  [types.SET_CAMPAIGN_META]: ($state, data) => {
    const { totalItems, currentPage, totalPages, itemsPerPage } = data;
    $state.meta.totalItems = totalItems;
    $state.meta.currentPage = currentPage;
    $state.meta.totalPages = totalPages;
    $state.meta.itemsPerPage = itemsPerPage;
  },
  [types.SET_EXCEL_DATA_CAMPAIGNS]: ($state, data) => {
    $state.excelData = data;
  },
  [types.SET_CONTACTS_CAMPAIGNS]: ($state, data) => {
    $state.campaignContacts = data;
  },
  [types.SET_CAMPAIGNS_STATISTICS]: ($state, data) => {
    $state.campaignStatistics = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
