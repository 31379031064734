import types from '../../mutation-types';
import AgentsUbluxAPI from '../../../api/ublux/agentsUblux';

export const state = {
  record: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getAgent: _state => {
    return _state.record;
  },
};

export const actions = {
  get: async ({ commit }, agent_id) => {
    commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isFetching: true });
    try {
      if (agent_id) {
        const response = await AgentsUbluxAPI.getAgent(agent_id);
        commit(types.SET_AGENTS_UBLUX, response.data);
      }
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, params) => {
    commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isCreating: true });
    try {
      const response = await AgentsUbluxAPI.createAgent(params);

      commit(types.SET_AGENTS_UBLUX, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, params) => {
    commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isUpdating: true });
    try {
      const response = await AgentsUbluxAPI.updateAgent(
        params.agent_id,
        params
      );
      commit(types.EDIT_AGENT_UBLUX, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, agent_id) => {
    commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isDeleting: true });
    try {
      await AgentsUbluxAPI.deleteAgent(agent_id);
      commit(types.DELETE_AGENTS_UBLUX, agent_id);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AGENTS_UBLUX_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_AGENTS_UBLUX_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_AGENTS_UBLUX](_state, data) {
    _state.record = data;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
