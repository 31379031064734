<script setup>
import { computed, ref } from 'vue';
import { useAlert } from 'dashboard/composables';
import { useToggle } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useStore, useStoreGetters } from 'dashboard/composables/store';
import { useEmitter } from 'dashboard/composables/emitter';
import { useKeyboardEvents } from 'dashboard/composables/useKeyboardEvents';
import { findSnoozeTime } from 'dashboard/helper/snoozeHelpers';
import { getUnixTime } from 'date-fns';

import WootDropdownHeader from 'shared/components/ui/dropdown/DropdownHeader.vue';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';
import WootDropdownDivider from 'shared/components/ui/dropdown/DropdownDivider.vue';
import wootConstants from 'dashboard/constants/globals';
import CustomSnoozeModal from 'dashboard/components/CustomSnoozeModal.vue';
// import EmailRequiredModal from '../widgets/conversation/conversation/emailRequiredModal.vue';
import {
  CMD_REOPEN_CONVERSATION,
  CMD_RESOLVE_CONVERSATION,
  CMD_SNOOZE_CONVERSATION,
} from 'dashboard/helper/commandbar/events';

const store = useStore();
const getters = useStoreGetters();
const { t } = useI18n();

// const confirmEmailDialogRef = ref(null);
const arrowDownButtonRef = ref(null);
const isLoading = ref(false);

const [showActionsDropdown, toggleDropdown] = useToggle();
const [showCustomSnoozeModal, toggleSnooze] = useToggle();
// const [showResolverEmailModal, toggleEmail] = useToggle();
const closeDropdown = () => toggleDropdown(false);
const openDropdown = () => toggleDropdown(true);
const hideSnoozeModal = () => toggleSnooze(false);
// const showEmailModal = () => toggleEmail(true);
// const hideEmailModal = () => toggleEmail(false);

const currentChat = computed(() => getters.getSelectedChat.value);
// const hubspotAccounts = computed(() => getters['hubspot/getAccounts'].value);

const isOpen = computed(
  () => currentChat.value.status === wootConstants.STATUS_TYPE.OPEN
);
const isPending = computed(
  () => currentChat.value.status === wootConstants.STATUS_TYPE.PENDING
);
const isResolved = computed(
  () => currentChat.value.status === wootConstants.STATUS_TYPE.RESOLVED
);
const isSnoozed = computed(
  () => currentChat.value.status === wootConstants.STATUS_TYPE.SNOOZED
);

const buttonClass = computed(() => {
  if (isPending.value) return 'primary';
  if (isOpen.value) return 'success';
  if (isResolved.value) return 'warning';
  return '';
});

const showAdditionalActions = computed(
  () => !isPending.value && !isSnoozed.value
);

// const currentContact = computed(() => {
//   return getters['contacts/getContact'].value(currentChat.value.meta.sender.id);
// });

const showOpenButton = computed(() => {
  return isPending.value || isSnoozed.value;
});

const getConversationParams = () => {
  const allConversations = document.querySelectorAll(
    '.conversations-list .conversation'
  );

  const activeConversation = document.querySelector(
    'div.conversations-list div.conversation.active'
  );
  const activeConversationIndex = [...allConversations].indexOf(
    activeConversation
  );
  const lastConversationIndex = allConversations.length - 1;

  return {
    all: allConversations,
    activeIndex: activeConversationIndex,
    lastIndex: lastConversationIndex,
  };
};

const openSnoozeModal = () => {
  const ninja = document.querySelector('ninja-keys');
  ninja.open({ parent: 'snooze_conversation' });
  const shadowRoot = ninja.shadowRoot;
  const style = document.createElement('style');
  style.textContent = `
        .modal-footer {
          display: grid !important;
          gap: 8px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media (min-width: 640px) {
          .modal-footer {
            grid-template-columns: repeat(4, minmax(0, 1fr));
          }
        }
      `;
  shadowRoot.appendChild(style);
};

const toggleStatus = (status, snoozedUntil) => {
  closeDropdown();
  isLoading.value = true;
  store
    .dispatch('toggleStatus', {
      conversationId: currentChat.value.id,
      status,
      snoozedUntil,
    })
    .then(() => {
      useAlert(t('CONVERSATION.CHANGE_STATUS'));
      isLoading.value = false;
    });
};

const onCmdOpenConversation = () => {
  toggleStatus(wootConstants.STATUS_TYPE.OPEN);
};

const onCmdResolveConversation = async () => {
  // Revizar que el contacto tiene su email y una cuenta de hubspot
  // let haveHubspotAccount = -1;
  //
  // if (hubspotAccounts.value && hubspotAccounts.value.length > 0) {
  //   haveHubspotAccount = hubspotAccounts.value.findIndex(value =>
  //     value ? Number(value.inbox_id) === currentChat.value.inbox_id : false
  //   );
  // }

  // if (!currentContact.value.email && haveHubspotAccount >= 0) {
  //   showEmailModal();
  //   const response = await confirmEmailDialogRef.value?.showConfirmation();
  //   if (response.accept) {
  //     toggleStatus(wootConstants.STATUS_TYPE.RESOLVED);
  //   }
  // } else {
  //   hideEmailModal();
  toggleStatus(wootConstants.STATUS_TYPE.RESOLVED);
  // }
};

const onCmdSnoozeConversation = snoozeType => {
  if (snoozeType === wootConstants.SNOOZE_OPTIONS.UNTIL_CUSTOM_TIME) {
    toggleSnooze(true);
  } else {
    toggleStatus(
      wootConstants.STATUS_TYPE.SNOOZED,
      findSnoozeTime(snoozeType) || null
    );
  }
};

const conversationStatuses = computed(() => {
  return [
    {
      label: t('CONVERSATION.HEADER.RESOLVE_ACTION'),
      value: wootConstants.STATUS_TYPE.RESOLVED,
      disabled: isResolved.value,
      hidden: !showAdditionalActions.value,
      icon: 'next-plan',
      viewbox: '0 -960 960 960',
      click: onCmdResolveConversation,
    },
    {
      label: t('CONVERSATION.HEADER.REOPEN_ACTION'),
      value: 'reopen',
      disabled: isOpen.value,
      hidden: !showAdditionalActions.value,
      icon: 'checkmark-circle',
      viewbox: '0 0 24 24',
      click: onCmdOpenConversation,
    },
  ];
});

const chooseSnoozeTime = customSnoozeTime => {
  toggleSnooze(false);
  if (customSnoozeTime) {
    toggleStatus(
      wootConstants.STATUS_TYPE.SNOOZED,
      getUnixTime(customSnoozeTime)
    );
  }
};

const keyboardEvents = {
  'Alt+KeyM': {
    action: () => arrowDownButtonRef.value?.$el.click(),
    allowOnFocusedInput: true,
  },
  'Alt+KeyE': {
    action: async () => {
      await toggleStatus(wootConstants.STATUS_TYPE.RESOLVED);
    },
  },
  '$mod+Alt+KeyE': {
    action: async event => {
      const { all, activeIndex, lastIndex } = getConversationParams();
      await toggleStatus(wootConstants.STATUS_TYPE.RESOLVED);

      if (activeIndex < lastIndex) {
        all[activeIndex + 1].click();
      } else if (all.length > 1) {
        all[0].click();
        document.querySelector('.conversations-list').scrollTop = 0;
      }
      event.preventDefault();
    },
  },
};

useKeyboardEvents(keyboardEvents);

useEmitter(CMD_REOPEN_CONVERSATION, onCmdOpenConversation);
useEmitter(CMD_RESOLVE_CONVERSATION, onCmdResolveConversation);
useEmitter(CMD_SNOOZE_CONVERSATION, onCmdSnoozeConversation);
</script>

<template>
  <div class="relative flex items-center justify-end resolve-actions">
    <div class="button-group hidden sm:flex">
      <woot-button
        v-if="isOpen"
        class-names="resolve"
        color-scheme="success"
        icon="checkmark"
        emoji="✅"
        :is-loading="isLoading"
        @click="onCmdResolveConversation"
      >
        {{ t('CONVERSATION.HEADER.RESOLVE_ACTION') }}
      </woot-button>
      <woot-button
        v-else-if="isResolved"
        class-names="resolve"
        color-scheme="warning"
        icon="arrow-redo"
        emoji="👀"
        :is-loading="isLoading"
        @click="onCmdOpenConversation"
      >
        {{ t('CONVERSATION.HEADER.REOPEN_ACTION') }}
      </woot-button>
      <woot-button
        v-else-if="showOpenButton"
        class-names="resolve"
        color-scheme="primary"
        icon="person"
        :is-loading="isLoading"
        @click="onCmdOpenConversation"
      >
        {{ t('CONVERSATION.HEADER.OPEN_ACTION') }}
      </woot-button>
      <woot-button
        v-if="showAdditionalActions"
        ref="arrowDownButtonRef"
        :color-scheme="buttonClass"
        :disabled="isLoading"
        icon="chevron-down"
        emoji="🔽"
        @click="openDropdown"
      />
    </div>
    <div class="flex sm:hidden">
      <button
        v-if="isOpen"
        class="p-1 mr-1 rounded-lg hover:bg-slate-75 dark:hover:bg-slate-700/50 rtl:rotate-180"
        @click="onCmdResolveConversation"
      >
        <fluent-icon
          icon="checkmark"
          size="18"
          class="text-green-700 dark:text-green-400"
        />
      </button>
      <button
        v-if="showOpenButton && !isOpen"
        class="p-1 mr-1 rounded-lg hover:bg-slate-75 dark:hover:bg-slate-700/50 rtl:rotate-180"
        @click="onCmdOpenConversation"
      >
        <fluent-icon
          :icon="isResolved ? 'arrow-redo' : 'person'"
          size="18"
          class="text-yellow-600"
        />
      </button>
      <button
        v-if="showAdditionalActions"
        ref="arrowDownButtonRef"
        class="p-1 rounded-lg hover:bg-slate-75 dark:hover:bg-slate-700/50 rtl:rotate-180"
        @click="openDropdown"
      >
        <fluent-icon
          icon="more-vertical"
          size="18"
          class="text-slate-700 dark:text-slate-75"
        />
      </button>
    </div>
    <div
      v-if="showActionsDropdown"
      v-on-clickaway="closeDropdown"
      class="dropdown-pane dropdown-pane--open"
    >
      <WootDropdownMenu class="mb-0">
        <div class="flex flex-col sm:hidden">
          <WootDropdownHeader
            :title="t('CONVERSATION.HEADER.CHANGE_STATUS_TITLE') + ':'"
          />
          <WootDropdownItem
            v-for="(status, index) in conversationStatuses"
            :id="index"
            :key="status.value"
            class="flex items-baseline"
          >
            <woot-button
              v-show="!status.hidden"
              size="small"
              :icon="status.icon"
              :view-box="status.viewbox"
              :color-scheme="status.disabled ? '' : 'secondary'"
              :variant="status.disabled ? 'smooth' : 'clear'"
              :class="status.color"
              :is-loading="isLoading"
              class="status-change"
              class-names="status-change--dropdown-button"
              @click="() => status.click"
            >
              <span class="flex justify-between items-center w-full">
                {{ status.label }}
                <fluent-icon
                  v-if="status.disabled"
                  class="icon"
                  size="14"
                  icon="checkmark"
                />
              </span>
            </woot-button>
          </WootDropdownItem>
          <WootDropdownDivider v-if="showAdditionalActions" />
        </div>
        <div v-if="showAdditionalActions">
          <WootDropdownItem v-if="!isPending">
            <woot-button
              variant="clear"
              color-scheme="secondary"
              size="small"
              icon="snooze"
              @click="() => openSnoozeModal()"
            >
              {{ t('CONVERSATION.RESOLVE_DROPDOWN.SNOOZE_UNTIL') }}
            </woot-button>
          </WootDropdownItem>
          <WootDropdownItem v-if="!isPending">
            <woot-button
              variant="clear"
              color-scheme="secondary"
              size="small"
              icon="book-clock"
              @click="() => toggleStatus(wootConstants.STATUS_TYPE.PENDING)"
            >
              {{ t('CONVERSATION.RESOLVE_DROPDOWN.MARK_PENDING') }}
            </woot-button>
          </WootDropdownItem>
        </div>
      </WootDropdownMenu>
    </div>
    <woot-modal
      v-model:show="showCustomSnoozeModal"
      :on-close="hideSnoozeModal"
    >
      <CustomSnoozeModal
        @close="hideSnoozeModal"
        @choose-time="chooseSnoozeTime"
      />
    </woot-modal>
    <!--    <EmailRequiredModal-->
    <!--      ref="confirmEmailDialogRef"-->
    <!--      :contact-id="currentContact.id"-->
    <!--    />-->
  </div>
</template>

<style lang="scss" scoped>
.dropdown-pane {
  @apply left-auto top-[1.6rem] sm:top-[2.625rem] mt-0.5 right-0 max-w-[12.5rem] min-w-[9.75rem];

  .dropdown-menu__item {
    @apply mb-0;
  }

  .status-change:not(:last-child) {
    @apply mb-1;
  }

  ::v-deep .button .icon + .button__content {
    width: 100% !important;
  }
}
</style>
