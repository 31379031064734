import Cookies from 'js-cookie';
import CacheEnabledApiClient from '../CacheEnabledApiClient';

/* global axios */
class CampaignsUbluxAPI extends CacheEnabledApiClient {
  constructor() {
    super('templates', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'template';
  }

  // eslint-disable-next-line class-methods-use-this
  get url() {
    return import.meta.env.VITE_APP_API_WA;
  }

  // eslint-disable-next-line class-methods-use-this
  get token() {
    const sessionInfo = Cookies.get('cw_d_session_info');
    const sessionInfoObject = JSON.parse(sessionInfo);

    return sessionInfoObject.authorization;
  }

  // eslint-disable-next-line class-methods-use-this
  get httpOptions() {
    return {
      headers: {
        'Content-Type': 'application/json',
        'X-Origin': 'inbox',
        Authorization: this.token,
      },
    };
  }

  getCampaigns(page, limit, filters) {
    let pagination = `?limit=${limit}&page=${page}`;
    let $filters = '';

    if (filters) {
      Object.keys(filters).forEach((key, index) => {
        if (index === 0) {
          $filters += `?${key}=${filters[key]}`;
        } else {
          $filters += `&${key}=${filters[key]}`;
        }
      });
    }

    if ($filters) {
      pagination = `&limit=${limit}&page=${page}`;
    }

    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-campaigns/find${$filters}${pagination}`,
      this.httpOptions
    );
  }

  createCampaign(payload) {
    return axios.chatwoot.post(
      `${this.url}/was/ublux/v2/was-campaigns/create`,
      payload,
      this.httpOptions
    );
  }

  createMessage(payload) {
    return axios.chatwoot.post(
      `${this.url}/was/ublux/v2/was-campaign-messages/create`,
      payload,
      this.httpOptions
    );
  }

  delete(id) {
    return axios.chatwoot.delete(
      `${this.url}/was/ublux/v2/was-campaigns/delete/${id}`,
      this.httpOptions
    );
  }

  downloadExcel(id) {
    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-campaigns/${id}/download`,
      {
        ...this.httpOptions,
        responseType: 'blob',
      }
    );
  }

  setStatusCampaign(params) {
    return axios.chatwoot.post(
      `${this.url}/was/ublux/v2/was-campaigns/set-status`,
      params,
      this.httpOptions
    );
  }

  getCampaignContacts(id) {
    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-campaign-messages/get-all/${id}`,
      this.httpOptions
    );
  }

  getStatistics(filters) {
    let $filters = '';
    if (filters) {
      Object.keys(filters).forEach((key, index) => {
        if (index === 0) {
          $filters += `?${key}=${encodeURIComponent(filters[key])}`;
        } else {
          $filters += `&${key}=${encodeURIComponent(filters[key])}`;
        }
      });
    }

    return axios.chatwoot.get(
      `${this.url}/was/ublux/v2/was-campaigns/get-statistics${$filters}`,
      this.httpOptions
    );
  }
}

export default new CampaignsUbluxAPI();
