<script>
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  emits: ['getLocation'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      address: '',
      locations: [],
      selectedLocation: {},
    };
  },
  validations: {
    selectedLocation: {
      required,
    },
    address: {
      required,
    },
  },
  methods: {
    async getCoordinates() {
      try {
        // Llamada a la API de Nominatim
        const response = await fetch(
          `https://us1.locationiq.com/v1/search?key=pk.601b61345c61ce35f5a449fcf3e3dd1a&q=${encodeURIComponent(
            this.address
          )}&format=json`
        );
        this.locations = await response.json();
      } catch {
        // Error
      }
    },
    onLocationChange() {
      this.$emit('getLocation', {
        latitude: this.selectedLocation.lat,
        longitude: this.selectedLocation.lon,
        name: this.selectedLocation.display_name,
        address: this.selectedLocation.display_name,
      });
    },
  },
};
</script>

<template>
  <div>
    <form class="!p-0 mb-2" @submit.prevent="getCoordinates">
      <p class="text-sm font-semibold mb-2.5">
        {{ $t('WHATSAPP_TEMPLATES.LOCATION.LABEL') }}
      </p>
      <div class="flex justify-between">
        <woot-input
          v-model="address"
          type="text"
          class="w-full"
          :styles="{ marginBottom: 0 }"
        />
        <woot-submit-button
          class="ml-1"
          :button-text="$t('WHATSAPP_TEMPLATES.LOCATION.SEARCH_LOCATION')"
        />
      </div>
      <p v-if="v$.address.$dirty && v$.address.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.LOCATION.ERROR_MESSAGE') }}
      </p>
    </form>

    <div v-show="locations && locations.length > 0" class="w-full">
      <label :class="{ error: v$.selectedLocation.$error }">
        <select v-model="selectedLocation" @change="onLocationChange">
          <option
            v-for="(location, index) in locations"
            :key="index"
            :value="location"
          >
            {{ location.display_name }}
          </option>
        </select>
        <span v-if="v$.selectedLocation.$error" class="message">
          {{ $t('WHATSAPP_TEMPLATES.LOCATION.ERROR_MESSAGE_SELECTED') }}
        </span>
      </label>
    </div>
  </div>
</template>
