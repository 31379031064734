import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import TemplatesUbluxAPI from '../../../api/ublux/templatesUblux';

const timeToMilliseconds = timeData =>
  timeData.hours * 60 * 60 * 1000 +
  timeData.minutes * 60 * 1000 +
  timeData.seconds * 1000;

const millisecondsToTime = ms => {
  let hours = Math.floor(ms / (60 * 60 * 1000));
  ms %= 60 * 60 * 1000;
  let minutes = Math.floor(ms / (60 * 1000));
  ms %= 60 * 1000;
  let seconds = Math.floor(ms / 1000);

  return { hours, minutes, seconds };
};

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getUIFlags: $state => {
    return $state.uiFlags;
  },
  getTimeLeft: $state => {
    return $state.records;
  },
};

export const actions = {
  get: async ({ commit, state: $state }, time) => {
    commit(types.SET_TIME_LEFT_UI_FLAG, { isFetching: true });
    try {
      const timeLeft = $state.records.find(
        value =>
          value.inbox_id === time.inbox_id &&
          value.contact_wa_id === time.contact_wa_id
      );

      if (!timeLeft) {
        const response = await TemplatesUbluxAPI.getTimeLeft(
          time.inbox_id,
          time.contact_wa_id
        );

        commit(types.ADD_TIME_LEFT, {
          inbox_id: time.inbox_id,
          contact_wa_id: time.contact_wa_id,
          time_left: response.data,
          time_left_original: response.data,
          new_message: false,
          start_time: new Date(),
        });
      } else {
        // Calcular el tiempo transcurrido en milisegundos
        const elapsedTimeMs = new Date() - new Date(timeLeft.start_time);
        // Convertir el tiempo original a milisegundos
        const originalTimeMs = timeToMilliseconds(timeLeft.time_left_original);
        // Calcular el tiempo restante
        const remainingTimeMs = originalTimeMs - elapsedTimeMs;

        if (timeLeft.new_message) {
          const response = await TemplatesUbluxAPI.getTimeLeft(
            time.inbox_id,
            time.contact_wa_id
          );

          commit(types.EDIT_TIME_LEFT, {
            ...timeLeft,
            new_message: false,
            time_left: response.data,
            time_left_original: response.data,
            start_time: new Date(),
          });
        } else if (remainingTimeMs > 0) {
          commit(types.EDIT_TIME_LEFT, {
            ...timeLeft,
            time_left: millisecondsToTime(remainingTimeMs),
          });
        } else {
          const response = await TemplatesUbluxAPI.getTimeLeft(
            time.inbox_id,
            time.contact_wa_id
          );

          commit(types.EDIT_TIME_LEFT, {
            ...timeLeft,
            time_left: response.data,
            time_left_original: response.data,
            start_time: new Date(),
          });
        }
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_TIME_LEFT_UI_FLAG, { isFetching: false });
    }
  },
  updateAttributesNewMessage: async ({ commit }, data) => {
    commit(types.UPDATE_TIME_LEFT_ATTRIBUTES, {
      new_message: data.new_message,
      inbox_id: data.inbox_id,
      contact_wa_id: data.contact_wa_id,
    });
  },
};

export const mutations = {
  [types.SET_TIME_LEFT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.ADD_TIME_LEFT](_state, data) {
    _state.records = [..._state.records, data];
  },
  [types.EDIT_TIME_LEFT](_state, data) {
    _state.records = _state.records.map(element => {
      if (
        element.inbox_id === data.inbox_id &&
        element.contact_wa_id === data.contact_wa_id
      ) {
        return data;
      }
      return element;
    });
  },
  [types.UPDATE_TIME_LEFT_ATTRIBUTES](_state, data) {
    const index = _state.records.findIndex(
      element =>
        element.inbox_id === data.inbox_id &&
        element.contact_wa_id === data.contact_wa_id
    );

    if (index !== -1) {
      _state.records.splice(index, 1, { ..._state.records[index], ...data });
    }
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
