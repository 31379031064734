<script>
import Campaign from './Campaigns.vue';

export default {
  components: {
    Campaign,
  },
  data() {
    return { showAddPopup: false };
  },
  computed: {},
  async mounted() {
    await this.$store.dispatch('campaigns/get');
    await this.$store.dispatch('timezones/get');
    await this.$store.dispatch('voipNumberPhone/get');
  },
  methods: {
    handleClick() {
      this.$store.dispatch('campaigns/getDataFromExcel', {});
    },
  },
};
</script>

<template>
  <div class="flex-1 overflow-auto p-4">
    <router-link
      to="new"
      class="button success button--fixed-top px-3.5 py-1 rounded-[5px] flex gap-2"
      @click="handleClick()"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ $t('CAMPAIGN.LIST.ADD_CAMPAIGN') }}
      </span>
    </router-link>
    <Campaign />
  </div>
</template>
