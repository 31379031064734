<script>
import { mapGetters } from 'vuex';
import PageHeader from '../../settings/SettingsSubPageHeader.vue';
import { read as XLSRead, utils as XLSXUtils } from 'xlsx';
import { useAlert } from 'dashboard/composables';

export default {
  components: {
    PageHeader,
  },
  emits: ['onBack', 'onClose', 'contacts'],
  data() {
    return {
      fileExtensions: ['xls', 'xlsx', 'csv', 'txt'],
      fileHeaders: [],
      whatsappContacts: [],
      contacts: [],
      fileLoadedName: '',
      fileTotalRecords: 0,
      validExcel: true,
    };
  },
  computed: {
    ...mapGetters({
      excel: 'campaigns/getDataFromExcel',
    }),
  },
  watch: {
    excel(value) {
      if (
        !value ||
        Object.values(value).length <= 0 ||
        (value && value.contacts && Object.values(value.contacts).length <= 0)
      ) {
        if (this.v$) {
          this.v$.$reset();
        }

        this.contacts = [];
        this.fileHeaders = [];
        this.fileLoadedName = '';
        this.fileTotalRecords = 0;
      }
    },
  },
  mounted() {
    if (
      this.excel &&
      this.excel.contacts &&
      Object.values(this.excel.contacts).length > 0
    ) {
      this.contacts = this.excel.contacts.contacts;
      this.fileHeaders = this.excel.contacts.headers;
      this.fileLoadedName = this.excel.contacts.fileName;
      this.fileTotalRecords = this.excel.contacts.fileTotal;
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onBack() {
      this.$emit('onBack', {
        headers: this.fileHeaders,
        contacts: this.contacts,
        key: 'template',
      });
    },
    onFileChange(event) {
      if (
        event.target?.files[0]?.name &&
        this.isValidExtension(event.target?.files[0]?.type)
      ) {
        const extension = event.target.files[0].name
          .split('.')
          .pop()
          .toLowerCase();
        this.fileLoadedName = event.target.files[0].name;

        if (this.fileExtensions.indexOf(extension) > -1) {
          const reader = new FileReader();
          reader.readAsArrayBuffer(event.target.files[0]);

          reader.onload = () => {
            try {
              const data = new Uint8Array(reader.result);
              const xlsRead = XLSRead(data, {
                type: 'array',
                cellFormula: false,
                cellDates: true,
              });
              const sheetNameList = xlsRead.SheetNames;
              let xlsJson = XLSXUtils.sheet_to_json(
                xlsRead.Sheets[sheetNameList[0]],
                {
                  raw: true,
                  header: 1,
                  blankrows: false,
                  rawNumbers: true,
                  dateNF: 'dd/mm/yyyy',
                }
              );
              const doesItHaveSpecialCharacters = xlsJson.find(element =>
                element.some(valor => /[ñáéíóú]/i.test(valor.toString()))
              );

              if (!doesItHaveSpecialCharacters) {
                xlsJson = this.convertDataToUTF8(data);
              }

              this.validExcel = true;
              this.validExcel = xlsJson.every(
                xls =>
                  xls.length === xlsJson[0].length &&
                  xls.every(element => !!element)
              );

              if (
                this.validExcel &&
                xlsJson &&
                Array.isArray(xlsJson) &&
                xlsJson.length > 0
              ) {
                this.whatsappContacts = [...xlsJson];
                this.fileTotalRecords = this.whatsappContacts.length - 1;

                if (this.fileTotalRecords >= 1) {
                  this.fileHeaders = this.whatsappContacts[0];

                  this.transformContacts();
                } else {
                  this.validExcel = false;
                  useAlert(this.$t('CAMPAIGN.ADD.LOAD_CONTACTS.INVALID_EXCEL'));
                }
              } else {
                useAlert(this.$t('CAMPAIGN.ADD.LOAD_CONTACTS.INVALID_EXCEL'));
              }
            } catch {
              // Error
            }
          };
        }
      } else {
        useAlert(this.$t('CAMPAIGN.ADD.LOAD_CONTACTS.BE_TYPE'));
      }
    },
    isValidExtension(mimeType) {
      const validMimeTypesRegex =
        /\/(vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet|csv)$/;
      return validMimeTypesRegex.test(mimeType);
    },
    convertDataToUTF8(data) {
      const decoder = new TextDecoder('utf-8');
      const utf8Data = decoder.decode(data);
      const xlsRead = XLSRead(utf8Data, {
        type: 'string',
        cellFormula: false,
        cellDates: true,
      });

      const sheetNameList = xlsRead.SheetNames;
      return XLSXUtils.sheet_to_json(xlsRead.Sheets[sheetNameList[0]], {
        raw: true,
        header: 1,
        blankrows: false,
        rawNumbers: true,
        dateNF: 'dd/mm/yyyy',
      });
    },
    transformContacts() {
      const fields = this.whatsappContacts[0];
      this.contacts = [];

      for (let i = 1; i < this.whatsappContacts.length; i += 1) {
        const contact = this.whatsappContacts[i];
        const item = {};

        for (let field = 0; field < fields.length; field += 1) {
          const fieldStr = ('' + fields[field]).trim();

          if (fieldStr) {
            const contactString = contact[field].toString();
            item[fieldStr] = this.isPhoneNumber(contactString)
              ? contactString.replace(/\D/g, '')
              : contactString;
          }
        }

        this.contacts.push(item);
      }
    },
    isPhoneNumber(text) {
      const phoneNumberPattern = /^[\d()+\-\s]*$/;
      const minMaxPhoneNumberPattern = /^\d{8,15}$/;
      return (
        phoneNumberPattern.test(text) &&
        minMaxPhoneNumberPattern.test(text.replace(/[^0-9]/g, ''))
      );
    },
    getContactsData() {
      if (this.fileTotalRecords > 0 && this.validExcel) {
        this.$emit('contacts', {
          headers: this.fileHeaders,
          contacts: this.contacts,
          fileName: this.fileLoadedName,
          fileTotal: this.fileTotalRecords,
        });
      } else {
        useAlert(this.$t('CAMPAIGN.ADD.LOAD_CONTACTS.VALID_EXCEL'));
      }
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-max min-h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <PageHeader
      :header-title="$t('CAMPAIGN.ADD.LOAD_CONTACTS.TITLE')"
      :header-content="$t('CAMPAIGN.ADD.LOAD_CONTACTS.DESC')"
    />
    <div class="flex flex-wrap">
      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="getContactsData()"
      >
        <div class="flex justify-center flex-col my-6 sm:my-10 w-full">
          <div class="flex flex-col sm:flex-row sm:items-center">
            <p>{{ $t('CAMPAIGN.ADD.LOAD_CONTACTS.SELECT_EXCEL') }}:</p>
            <div class="flex-1 sm:text-center mt-2 sm:mt-0">
              <input
                id="hiddenFileInput"
                ref="hiddenFileInput"
                type="file"
                accept=".xls, .xlsx, .csv"
                class="hidden"
                @change="onFileChange($event)"
              />

              <button
                type="button"
                class="!bg-slate-200 rounded-lg border-slate-400 flex mx-auto"
                @click="$refs.hiddenFileInput.click()"
              >
                <fluent-icon
                  icon="snippet-folder"
                  viewBox="0 -960 960 960"
                  size="24"
                  type="solid"
                  class="text-slate-500"
                />
                <span class="pl-2 font-medium dark:text-black-800">
                  {{ $t('CAMPAIGN.ADD.LOAD_CONTACTS.SELECT_FILE') }}
                </span>
              </button>
            </div>
          </div>
          <div v-if="fileLoadedName && validExcel" class="mt-5 flex-1">
            <p class="flex flex-col sm:flex-row">
              {{ $t('CAMPAIGN.ADD.LOAD_CONTACTS.FILE_LOADED') }}:
              <span class="font-bold pl-3 sm:ml-0">{{ fileLoadedName }}</span>
            </p>
            <p class="flex flex-col sm:flex-row">
              {{ $t('CAMPAIGN.ADD.LOAD_CONTACTS.ROWS') }}:
              <span class="font-bold pl-3 sm:ml-0">{{ fileTotalRecords }}</span>
            </p>
          </div>
        </div>
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full mt-3">
          <woot-button
            variant="clear"
            type="button"
            class="mr-auto"
            @click.prevent="onClose"
          >
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button variant="smooth" type="button" @click.prevent="onBack">
            {{ $t('CAMPAIGN.ADD.BACK_BUTTON_TEXT') }}
          </woot-button>
          <woot-button type="submit">
            {{ $t('CAMPAIGN.ADD.NEXT_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>
