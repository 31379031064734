import { computed } from 'vue';
import { useStoreGetters } from 'dashboard/composables/store';

/**
 * Composable to determine if the current user is an administrator.
 * @returns {Boolean} - True if the current user is an administrator, false otherwise.
 */
export function useSuperAdmin() {
  const getters = useStoreGetters();

  const currentUser = computed(() => getters.getCurrentUser.value);
  const isSuperAdmin = computed(() => currentUser.value === 'SuperAdmin');

  return {
    isSuperAdmin,
  };
}
