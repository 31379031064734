<script>
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';

export default {
  emits: ['onClose'],
  data() {
    return {
      value: null,
      appliedCallerIds: [],
    };
  },
  computed: {
    ...mapGetters({
      uIFlags: 'phones/getUIFlags',
      phoneByUser: 'phones/getUserPhones',
    }),
    values: {
      get() {
        return this.value || null;
      },
      set(value) {
        this.value = value;
      },
    },
    isPhoneTypeMobile() {
      return this.phoneByUser.length > 0 && this.phoneMobileUblux;
    },
    phoneMobileUblux() {
      return (
        this.phoneByUser.length > 0 &&
        this.phoneByUser.find(value => value.phoneType === 'Mobile')
      );
    },
  },
  mounted() {
    if (this.isPhoneTypeMobile) {
      this.appliedCallerIds = this.callerId(this.phoneMobileUblux);
      this.value = this.appliedCallerIds.find(
        val => val.id === this.phoneMobileUblux.callerIdIndex
      );
    }
  },
  methods: {
    onCloseModal() {
      this.value = null;
      this.$emit('onClose');
    },
    async onSubmitCallerIds() {
      if (this.value) {
        try {
          await this.$store.dispatch('phones/update', {
            id: this.phoneMobileUblux.id,
            callerIdIndex: this.value.id,
          });
          useAlert(this.$t('CONVERSATION.CALLER_ID.API.SUCCESS_MESSAGE'));
          this.onCloseModal();
        } catch (error) {
          useAlert(this.$t('CONVERSATION.CALLER_ID.API.ERROR_MESSAGE'));
        }
      } else {
        useAlert(this.$t('CONVERSATION.CALLER_ID.API.ERROR_MESSAGE'));
      }
    },
    callerId(phones) {
      return phones.callerIdNumbers.map((phone, index) => ({
        id: index,
        label: phone,
      }));
    },
  },
};
</script>

<template>
  <div>
    <woot-modal-header :header-title="$t('CONVERSATION.CALLER_ID.TITLE')">
      <p class="text-slate-600 dark:text-slate-200">
        {{ $t('CONVERSATION.CALLER_ID.SUBTITLE') }}
      </p>
    </woot-modal-header>
    <div class="p-5 sm:p-8 mx-2">
      <div class="multiselect-wrap--small">
        <multiselect
          v-model="values"
          track-by="id"
          label="label"
          :placeholder="$t('CONVERSATION.CALLER_ID.PLACEHOLDER')"
          :multiple="false"
          selected-label
          :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
          deselect-label=""
          :max-height="160"
          :options="appliedCallerIds"
          :allow-empty="false"
          :searchable="false"
        />
      </div>
      <div class="w-full">
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
          <woot-button class="button clear" @click.prevent="onCloseModal">
            {{ $t('CONVERSATION.CALLER_ID.CANCEL_BUTTON_LABEL') }}
          </woot-button>
          <woot-button
            :is-loading="uIFlags.isUpdating"
            @click="onSubmitCallerIds"
          >
            {{ $t('CONVERSATION.CALLER_ID.SUBMIT_BUTTON_LABEL') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep .multiselect__content-wrapper {
  position: relative !important;
}
</style>
