export const CAMPAIGN_TYPES = {
  ONGOING: 'ongoing',
  ONE_OFF: 'one_off',
};

export const CAMPAIGN_STATUS = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  ToBeStarted: 'ToBeStarted',
  Paused: 'Paused',
  Canceled: 'Canceled',
  Failed: 'Failed',
  Scheduled: 'Scheduled',
};

export const STATUS_MAP = new Map([
  [
    CAMPAIGN_STATUS.Completed,
    {
      icon: 'check-circle',
      iconColor: 'text-green-600',
      textColor: 'text-green-600',
      bgColor: 'bg-green-100',
      viewBox: '0 -960 960 960',
    },
  ],
  [
    CAMPAIGN_STATUS.InProgress,
    {
      icon: 'published-with-changes',
      iconColor: 'text-blue-600',
      textColor: 'text-blue-600',
      bgColor: 'bg-blue-100',
      viewBox: '0 -960 960 960',
    },
  ],
  [
    CAMPAIGN_STATUS.ToBeStarted,
    {
      icon: 'not-started',
      iconColor: 'text-gray-800',
      textColor: 'text-gray-800',
      bgColor: 'bg-gray-200',
      viewBox: '0 -960 960 960',
    },
  ],
  [
    CAMPAIGN_STATUS.Paused,
    {
      icon: 'motion-photos-paused',
      iconColor: 'text-yellow-600',
      textColor: 'text-yellow-600',
      bgColor: 'bg-yellow-100',
      viewBox: '0 -960 960 960',
    },
  ],
  [
    CAMPAIGN_STATUS.Canceled,
    {
      icon: 'cancel',
      iconColor: 'text-red-600',
      textColor: 'text-red-600',
      bgColor: 'bg-red-100',
      viewBox: '0 -960 960 960',
    },
  ],
  [
    CAMPAIGN_STATUS.Failed,
    {
      icon: 'sms-failed',
      iconColor: 'text-orange-600',
      textColor: 'text-orange-600',
      bgColor: 'bg-orange-100',
      viewBox: '0 -960 960 960',
    },
  ],
  [
    CAMPAIGN_STATUS.Scheduled,
    {
      icon: 'schedule',
      iconColor: 'text-indigo-600',
      textColor: 'text-indigo-600',
      bgColor: 'bg-indigo-100',
      viewBox: '0 -960 960 960',
    },
  ],
]);
