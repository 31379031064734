const allElementsString = arr => {
  return arr.every(elem => typeof elem === 'string');
};

const allElementsNumbers = arr => {
  return arr.every(elem => typeof elem === 'number');
};

const formatArray = params => {
  if (params.length <= 0) {
    params = [];
  } else if (allElementsString(params) || allElementsNumbers(params)) {
    params = [...params];
  } else {
    params = params.map(val => val.id);
  }
  return params;
};

const generatePayloadForObject = item => {
  if (item.action_params.id) {
    item.action_params = [item.action_params.id];
  } else {
    item.action_params = [item.action_params];
  }
  return item.action_params;
};

const generatePayloadAutomation = data => {
  const actions = JSON.parse(JSON.stringify(data.actions));
  return actions.map((item, index) => {
    if (Array.isArray(item.action_params)) {
      if (
        item.action_name !== 'send_template' &&
        item.action_name !== 'send_flow'
      ) {
        item.action_params = formatArray(item.action_params);
      } else {
        item.action_name = 'send_webhook_event';
        const targetObject = item.action_params.find(val => val.phoneFrom);
        targetObject.phoneFrom = data.phoneFrom;
      }
    } else if (
      typeof item.action_params === 'object' &&
      !Array.isArray(item.action_params)
    ) {
      if (item.action_name === 'send_template') {
        item.action_name = 'send_webhook_event';
        item.action_params = [
          `${import.meta.env.VITE_APP_API_WA}/cw-adapter/automations/wa-templates/`,
          {
            ...item.action_params,
            variables: {
              ...item.action_params.variables,
              templateMedia:
                data.actions[index].action_params.variables?.templateMedia ??
                undefined,
              patch: data.actions[index].action_params.variables?.patch,
            },
          },
          {
            phoneFrom: data.phoneFrom,
            type: 'send_template',
            adapterTemplateId: '',
          },
        ];
      } else if (item.action_name === 'send_flow') {
        item.action_name = 'send_webhook_event';
        item.action_params = [
          `${import.meta.env.VITE_APP_API_WA}/cw-adapter/automations/wa-flows/`,
          item.action_params,
          { phoneFrom: data.phoneFrom, type: 'send_flow', adapterFlowId: '' },
        ];
      } else {
        item.action_params = generatePayloadForObject(item);
      }
    } else if (!item.action_params) {
      item.action_params = [];
    } else {
      item.action_params = [item.action_params];
    }
    return item;
  });
};

export default generatePayloadAutomation;
