<script>
import PageHeader from '../../settings/SettingsSubPageHeader.vue';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useAlert } from 'dashboard/composables';
import TemplateParserWithFixedData from 'dashboard/components/widgets/conversation/WhatsappTemplates/TemplateParserWithFixedData.vue';
import { useVuelidate } from '@vuelidate/core';

export default {
  components: {
    PageHeader,
    TemplateParserWithFixedData,
  },
  emits: ['onClose', 'onBack', 'variables'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      variables: {},
      emptyFields: false,
    };
  },
  validations: {
    variables: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uIFlags: 'campaigns/getUIFlags',
      excel: 'campaigns/getDataFromExcel',
    }),
    templateName() {
      return this.excel.template?.templateSelected.name ?? '';
    },
    templateSelected() {
      return this.excel.template?.templateSelected ?? {};
    },
  },
  mounted() {
    // if (
    //   this.excel &&
    //   this.excel.variables &&
    //   Object.values(this.excel.variables).length > 0
    // ) {
    // }
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onBack() {
      this.$emit('onBack', {
        variables: this.variables,
        submit: false,
        key: 'contacts',
      });
    },
    nextComponent() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.emptyFields = true;
        useAlert(this.$t('TEMPLATES_SETTINGS.ADD.FORM.FORM_INVALID'));
        return;
      }

      this.emptyFields = false;
      this.$emit('variables', { variables: this.variables, submit: true });
    },
    setVariables(variables) {
      this.variables = variables;
    },
  },
};
</script>

<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-max min-h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <PageHeader
      :header-title="$t('CAMPAIGN.ADD.PREVIEW_CAMPAIGN.TITLE')"
      :header-content="
        $t('CAMPAIGN.ADD.PREVIEW_CAMPAIGN.DESC', {
          templateName,
        })
      "
    />
    <div class="flex flex-wrap mt-4">
      <form
        class="flex flex-col w-full items-start"
        @submit.prevent="nextComponent()"
      >
        <TemplateParserWithFixedData
          :template="templateSelected"
          :contacts="excel.contacts"
          is-accounts
          hide-buttons
          hide-scroll
          :show-empty-fields="emptyFields"
          @send-message="setVariables"
        />
        <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full mt-3">
          <woot-button
            variant="clear"
            type="button"
            class="mr-auto"
            @click.prevent="onClose"
          >
            {{ $t('CAMPAIGN.ADD.CANCEL_BUTTON_TEXT') }}
          </woot-button>
          <woot-button variant="smooth" type="button" @click.prevent="onBack">
            {{ $t('CAMPAIGN.ADD.BACK_BUTTON_TEXT') }}
          </woot-button>
          <woot-button type="submit" :is-loading="uIFlags.isCreating">
            {{ $t('CAMPAIGN.ADD.CREATE_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </form>
    </div>
  </div>
</template>
